import React, { useContext } from 'react'
import { animated, useTrail } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import { Button } from 'antd'

function Step0() {
  const { setStep } = useContext(ReportContext)
  const ComponentsList = [
    <div key={'step0_c_1'} className={styles.text}>
      亲爱的侠士
    </div>,
    <div key={'step0_c_2'} className={styles.text}>
      本报告仅为娱乐，请勿节奏
    </div>,
    <div key={'step0_c_3'} className={styles.text}>
      数据基于百度统计和当前浏览器历史数据生成，不会侵害您的隐私
    </div>,
    <div key={'step0_c_4'} className={styles.text}>
      本次收集数据有限，更有趣的数据下次一定
    </div>,
    <div key={'step0_c_5'} className={styles.entry}>
      <Button type='primary' onClick={() => setStep(1)}>
        点击开始
      </Button>
      <p className={styles.tip}>报告有背景音乐，请注意场景和音量</p>
    </div>,
  ]

  const trail = useTrail(ComponentsList.length, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { tension: 170, friction: 40 },
  })

  return (
    <div className={styles.content}>
      <div>
        {trail.map((props, index) => {
          return (
            <animated.div style={props} key={`step0_${index}`}>
              {ComponentsList?.[index]}
            </animated.div>
          )
        })}
      </div>
    </div>
  )
}

export default Step0
