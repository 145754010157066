// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lth_pKyBJ_QlUyZwAYEc {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.g5MHARs81iC9cM3Jv8Fz {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 12px;
}
.tbNHufhaisdPy_YR5AmB {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 12px;
}
.miJHOhNskKRkjGt_8JXz {
  min-width: 100px;
  margin-right: 8px;
}
.DpTrfnRa4pRKF7lDIiFI {
  width: 500px;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/组件/ErrorBoundary/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;AACF;AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAAF;AAGA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADF;AAIA;EACE,gBAAA;EACA,iBAAA;AAFF;AAKA;EACE,YAAA;EACA,gBAAA;AAHF","sourcesContent":[".main {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n}\n\n.title {\n  font-size: 24px;\n  line-height: 24px;\n  margin-bottom: 12px;\n}\n\n.tip {\n  font-size: 14px;\n  line-height: 14px;\n  margin-bottom: 12px;\n}\n\n.btn {\n  min-width: 100px;\n  margin-right: 8px;\n}\n\n.error {\n  width: 500px;\n  margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Lth_pKyBJ_QlUyZwAYEc`,
	"title": `g5MHARs81iC9cM3Jv8Fz`,
	"tip": `tbNHufhaisdPy_YR5AmB`,
	"btn": `miJHOhNskKRkjGt_8JXz`,
	"error": `DpTrfnRa4pRKF7lDIiFI`
};
export default ___CSS_LOADER_EXPORT___;
