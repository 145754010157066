import React from 'react'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import styles from './index.module.less'
import 江湖写照 from './江湖写照.mp3'

// const url =
// 'https://ws6.stream.qqmusic.qq.com/C400002yE5jL0RJOJw.m4a?guid=2609730139&vkey=762B5AEEBC01F4B6ED916110B8587C8B8E767A0F29F8F431E31EEA83215099C3A18105EFE456BB59C165C3E0348AB25439D842622214A891__v215192ff9&uin=372103645&fromtag=120032&src=C400001sXRSZ12ty7h.m4a'

function 背景音乐() {
  return (
    <div className={styles.wrap}>
      <ReactJkMusicPlayer
        defaultPosition={{ right: -20, top: 20 }}
        className={styles.music}
        locale={'zh_CN'}
        autoPlay
        defaultPlayMode='singleLoop'
        defaultVolume={0.5}
        audioLists={[{ musicSrc: 江湖写照, name: '江湖写照' }]}
      />
    </div>
  )
}

export default 背景音乐
