import { 属性类型 } from '@/@types/属性'
import { 小药小吃数据类型 } from '@/@types/小药小吃'
import { 小吃类型枚举 } from '@/@types/枚举'
import { 获取心法数据 } from '@/心法模块'

const 主属性 = 获取心法数据()?.主属性
const 主属性加成类型 =
  主属性 === '力道'
    ? 属性类型.力道
    : 主属性 === '身法'
    ? 属性类型.身法
    : 主属性 === '元气'
    ? 属性类型.元气
    : 属性类型.根骨

const 通用小药小吃数据: 小药小吃数据类型[] = [
  {
    小吃名称: '风语·中品破秽散（破防）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/4279.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 2350 }],
  },
  {
    小吃名称: '风语·上品破秽散（破防）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/4278.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 4699 }],
  },
  {
    小吃名称: '风语·中品玉璃散（会心）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/4269.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 2350 }],
  },
  {
    小吃名称: '风语·上品玉璃散（会心）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/4268.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 4699 }],
  },
  {
    小吃名称: '风语·中品凝神散（破招）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/4488.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 2350 }],
  },
  {
    小吃名称: '风语·上品凝神散（破招）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/4487.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 4699 }],
  },
  {
    小吃名称: '风语·中品活气散（加速）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/4477.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 2350 }],
  },
  {
    小吃名称: '风语·上品活气散（加速）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/4476.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 4699 }],
  },
  {
    小吃名称: '风语·水煮肉片（破防）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/8910.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 1828 }],
  },
  {
    小吃名称: '风语·红烧排骨（破防）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/9999.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 3655 }],
  },
  {
    小吃名称: '风语·鱼香肉丝（会心）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/550.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 1828 }],
  },
  {
    小吃名称: '风语·酸菜鱼（会心）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/8909.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 3655 }],
  },
  {
    小吃名称: '风语·毛血旺（破招）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/9997.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 1828 }],
  },
  {
    小吃名称: '风语·白肉血肠（破招）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 3655 }],
  },
  {
    小吃名称: '风语·栗子烧肉（加速）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/9994.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 1828 }],
  },
  {
    小吃名称: '风语·红烧扣肉（加速）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/7650.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 3655 }],
  },
  {
    小吃名称: '强身·秘境·辅（全属）',
    小吃部位: 小吃类型枚举.食品辅助,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/3400.png',
    增益集合: [{ 属性: 属性类型.全属性, 值: 86 }],
  },
  {
    小吃名称: '炼气·秘境·辅（全属）',
    小吃部位: 小吃类型枚举.药品辅助,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/3444.png',
    增益集合: [{ 属性: 属性类型.全属性, 值: 111 }],
  },
  {
    小吃名称: '女儿红（加速）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/10134.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 677 }],
  },
  {
    小吃名称: '女儿红·今朝醉（加速）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/10134.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 1354 }],
  },
  {
    小吃名称: '女儿红·六日醉（加速）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/10134.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 2031 }],
  },
  {
    小吃名称: '女儿红·旬又三（加速）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/10134.png',
    增益集合: [{ 属性: 属性类型.加速等级, 值: 2708 }],
  },
  {
    小吃名称: '炖豆腐（无双）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/9989.png',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 1694 }],
  },
  {
    小吃名称: '风语·炖豆腐（无双）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/9989.png',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 2541 }],
  },
  {
    小吃名称: '煎豆腐（破招）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/552.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 1694 }],
  },
  {
    小吃名称: '风语·煎豆腐（破招）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/552.png',
    增益集合: [{ 属性: 属性类型.破招值, 值: 2541 }],
  },
  {
    小吃名称: '清蒸鲈鱼（破防）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/8909.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 1694 }],
  },
  {
    小吃名称: '风语·清蒸鲈鱼（破防）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/8909.png',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 2541 }],
  },
  {
    小吃名称: '炸鱼干（会心）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '蓝',
    图标: 'https://icon.jx3box.com/icon/8915.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 1694 }],
  },
  {
    小吃名称: '风语·炸鱼干（会心）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/8915.png',
    增益集合: [{ 属性: 属性类型.全会心等级, 值: 2541 }],
  },
  {
    小吃名称: '风语·水晶芙蓉宴',
    小吃部位: 小吃类型枚举.团队宴席,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/7629.png',
    增益集合: [{ 属性: 主属性加成类型, 值: 536 }],
  },
  {
    小吃名称: '创意料理（-无双+会心）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全会心等级, 值: 4576 },
      { 属性: 属性类型.无双等级, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-无双+破招）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.破招值, 值: 4576 },
      { 属性: 属性类型.无双等级, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-无双+破防）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全破防等级, 值: 4576 },
      { 属性: 属性类型.无双等级, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-破招+会心）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全会心等级, 值: 4576 },
      { 属性: 属性类型.破招值, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-破招+破防）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全破防等级, 值: 4576 },
      { 属性: 属性类型.破招值, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-破招+无双）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.无双等级, 值: 4576 },
      { 属性: 属性类型.破招值, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-气血+会心）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全会心等级, 值: 4576 },
      { 属性: 属性类型.额外气血上限, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-气血+破防）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.全破防等级, 值: 4576 },
      { 属性: 属性类型.额外气血上限, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-气血+无双）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    图标: 'https://icon.jx3box.com/icon/549.png',
    增益集合: [
      { 属性: 属性类型.无双等级, 值: 4576 },
      { 属性: 属性类型.额外气血上限, 值: -2032 },
    ],
  },
]

export default 通用小药小吃数据
