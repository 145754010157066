import React, { useContext } from 'react'
import styles from './index.module.less'
import ReportContext from '../context'

function 控制() {
  const { step, setStep, nextStep } = useContext(ReportContext)

  return (
    <div className={styles.control}>
      <span className={styles.btn} onClick={() => setStep(Math.max(0, step - 1))}>
        前篇
      </span>
      <span className={styles.btn} onClick={() => nextStep()}>
        后篇
      </span>
    </div>
  )
}

export default 控制
