// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GtobglaPlkkAaCe_N9wJ {
  position: absolute;
  left: 10px;
  top: 10px;
}
.iogHg6NjYDZ_nN32l8uE {
  color: #fff;
  margin-right: 8px;
  line-height: 22px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
}
.iogHg6NjYDZ_nN32l8uE:hover {
  color: var(--ant-primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/控制/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AACF;AAEA;EACE,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAAF;AAEE;EACE,+BAAA;AAAJ","sourcesContent":[".control {\n  position: absolute;\n  left: 10px;\n  top: 10px;\n}\n\n.btn {\n  color: #fff;\n  margin-right: 8px;\n  line-height: 22px;\n  font-size: 14px;\n  cursor: pointer;\n  transition: .2s;\n\n  &:hover {\n    color: var(--ant-primary-color);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `GtobglaPlkkAaCe_N9wJ`,
	"btn": `iogHg6NjYDZ_nN32l8uE`
};
export default ___CSS_LOADER_EXPORT___;
