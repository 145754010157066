import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 大荒笛法增益 from './技能增益/大荒笛法'
import 百足增益 from './技能增益/百足'
import 蛇影增益 from './技能增益/蛇影'
import 蝎心增益 from './技能增益/蝎心'
import 灵蛊增益 from './技能增益/灵蛊'
import 连缘蛊增益 from './技能增益/连缘蛊'
import 篾片蛊增益 from './技能增益/篾片蛊'

import DOT增益 from './技能增益/通用增益/DOT增益'
import 灵蛇增益 from './技能增益/灵蛇'
import { 属性类型 } from '@/@types/属性'
import 大橙武技能增益 from './技能增益/通用增益/大橙武技能增益'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32818',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(545259),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-720371.712) },
      2: { 技能伤害系数: 获取破招实际系数(-638582.784) },
      3: { 技能伤害系数: 获取破招实际系数(-392167.424) },
      4: { 技能伤害系数: 获取破招实际系数(-310378.49600000004) },
      5: { 技能伤害系数: 获取破招实际系数(-62914.560000000056) },
    },
  },
  {
    技能ID: '2183',
    技能名称: '大荒笛法',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 大荒笛法增益,
  },
  {
    技能ID: '3067',
    技能名称: '赤蛇',
    基础伤害_基础值: 204,
    基础伤害_浮动值: 21,
    技能伤害系数: 530,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13472',
    技能名称: '百足',
    基础伤害_基础值: 212,
    基础伤害_浮动值: 23,
    技能伤害系数: 574.88275152,
    技能增益列表: [...百足增益, ...大橙武技能增益],
    // 只有技能吃大橙武，DOT不吃
  },
  {
    技能ID: '18590',
    技能名称: '蛊毒',
    基础伤害_基础值: 28,
    基础伤害_浮动值: 3,
    技能伤害系数: 16,
    技能增益列表: 灵蛊增益,
  },
  {
    技能ID: '25044',
    技能名称: '连缘蛊',
    统计名称: '连缘蛊',
    基础伤害_基础值: 40,
    基础伤害_浮动值: 4,
    技能伤害系数: 165.31020000000004,
    技能增益列表: 连缘蛊增益,
  },
  {
    技能ID: '30918',
    技能名称: '连缘蛊·额外',
    统计名称: '连缘蛊',
    基础伤害_基础值: 133,
    基础伤害_浮动值: 14,
    技能伤害系数: 918.39,
    技能增益列表: 连缘蛊增益,
  },
  {
    技能ID: '29573',
    技能名称: '篾片蛊',
    基础伤害_基础值: 365,
    基础伤害_浮动值: 23,
    技能伤害系数: 1628.0550000000003,
    技能增益列表: 篾片蛊增益,
  },
  {
    技能ID: '34389',
    技能名称: '黯影',
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能伤害系数: 100,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '6648',
    技能名称: '幻击·食髓',
    技能伤害系数: 900.0,
    基础伤害_基础值: 300,
    基础伤害_浮动值: 26,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 900.0, 基础伤害_基础值: 300, 基础伤害_浮动值: 26 },
      2: { 技能伤害系数: 1350.0, 基础伤害_基础值: 600, 基础伤害_浮动值: 52 },
    },
  },
  {
    技能ID: '37959',
    技能名称: '不鸣',
    基础伤害_基础值: 157,
    基础伤害_浮动值: 1,
    技能伤害系数: 150.0,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13473',
    技能名称: '蟾啸',
    基础伤害_基础值: 285,
    基础伤害_浮动值: 30,
    技能伤害系数: 245.04480000000007,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '21303',
    技能名称: '蛇影',
    基础伤害_基础值: 204,
    基础伤害_浮动值: 21,
    技能伤害系数: 474.77430000000004,
    技能增益列表: 蛇影增益,
  },
  {
    技能ID: '38456',
    技能名称: '残香',
    基础伤害_基础值: 220,
    基础伤害_浮动值: 20,
    技能伤害系数: 180,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '9331',
    技能名称: '蝎心',
    基础伤害_基础值: 170,
    基础伤害_浮动值: 16,
    技能伤害系数: 446.9987808000001,
    技能增益列表: [
      // dot和本体的秘籍相同，但是吃的增伤实现不同
      {
        增益名称: '4%伤害',
        增益所在位置: '秘籍',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
      },
      {
        增益名称: '3%伤害',
        增益所在位置: '秘籍',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 31 / 1024 }],
      },
      ...蝎心增益,
      // dot不吃残香
      {
        增益名称: '残香',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 410 / 1024 }],
      },
    ],
  },
  {
    技能ID: '6218', // 6218_13476
    技能名称: '蝎心(DOT)',
    统计名称: '蝎心(DOT)',
    秘籍依赖技能: '蝎心',
    基础伤害_基础值: 80,
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 644.70978,
    技能增益列表: [
      // dot和本体的秘籍相同，但是吃的增伤实现不同
      {
        增益名称: '4%伤害',
        增益所在位置: '秘籍',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.04 }],
      },
      {
        增益名称: '3%伤害',
        增益所在位置: '秘籍',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.03 }],
      },
      ...蝎心增益,
      ...DOT增益,
    ],
  },
  {
    技能ID: '25917', // 25917_34643
    技能名称: '蛇影(DOT)·短',
    统计名称: '蛇影(DOT)',
    秘籍依赖技能: '蛇影',
    基础伤害_基础值: 55,
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 434.148,
    技能增益列表: [...蛇影增益, ...DOT增益],
  },
  {
    技能ID: '2509', // 2509_6238
    技能名称: '百足(DOT)',
    统计名称: '百足(DOT)',
    秘籍依赖技能: '百足',
    基础伤害_基础值: 92,
    DOT生效间隔: 48,
    DOT跳数: 6,
    技能伤害系数: 617.4666590400001,
    技能增益列表: [...百足增益, ...DOT增益],
  },
  {
    技能ID: '12557', // 12557_18700
    技能名称: '百足(DOT)·短',
    统计名称: '百足(DOT)',
    秘籍依赖技能: '百足',
    基础伤害_基础值: 92,
    DOT生效间隔: 32,
    DOT跳数: 9,
    技能伤害系数: 926.1999885600002,
    技能增益列表: [...百足增益, ...DOT增益],
  },
  {
    技能ID: '2296', // 2296_6237
    技能名称: '蛇影(DOT)',
    统计名称: '蛇影(DOT)',
    秘籍依赖技能: '蛇影',
    基础伤害_基础值: 55,
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 361.79,
    技能增益列表: [...蛇影增益, ...DOT增益],
  },
  {
    技能ID: '2295', // 2295_6236
    技能名称: '蟾啸(DOT)',
    统计名称: '蟾啸(DOT)',
    秘籍依赖技能: '蟾啸',
    基础伤害_基础值: 50,
    DOT生效间隔: 32,
    DOT跳数: 7,
    技能伤害系数: 545.740734,
    技能增益列表: [...通用增益, ...DOT增益],
  },
  {
    技能ID: '18882', // 18882_26226
    技能名称: '赤蛇(DOT)',
    基础伤害_基础值: 20,
    DOT生效间隔: 48,
    DOT跳数: 10,
    技能伤害系数: 315.0,
    技能增益列表: [...通用增益, ...DOT增益],
  },
  {
    技能ID: '39036',
    技能名称: '赤蝎',
    基础伤害_浮动值: 21,
    技能伤害系数: 750,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '2477',
    技能名称: '幻击', // 蛇
    基础伤害_基础值: 194,
    基础伤害_浮动值: 20,
    伤害计算类型: '毒经宠物伤害',
    技能伤害系数: 115.0,
    技能增益列表: 灵蛇增益,
  },
  {
    技能ID: '36292',
    技能名称: '幻击·距离', // 蛇
    统计名称: '幻击',
    基础伤害_基础值: 194,
    基础伤害_浮动值: 20,
    伤害计算类型: '毒经宠物伤害',
    技能伤害系数: 115.0,
    技能增益列表: 灵蛇增益,
  },
  {
    技能ID: '2472',
    技能名称: '攻击', // 蛇
    统计名称: '攻击',
    基础伤害_基础值: 40,
    基础伤害_浮动值: 4,
    伤害计算类型: '毒经宠物伤害',
    技能伤害系数: 129.0,
    技能增益列表: 灵蛇增益,
  },
  {
    技能ID: '22997',
    技能名称: '攻击·距离', // 蛇
    统计名称: '攻击',
    基础伤害_基础值: 40,
    基础伤害_浮动值: 4,
    伤害计算类型: '毒经宠物伤害',
    技能伤害系数: 129.0,
    技能增益列表: 灵蛇增益,
  },
  {
    技能ID: '25019',
    技能名称: '荒息', // 蛇
    统计名称: '荒息',
    基础伤害_基础值: 40,
    基础伤害_浮动值: 4,
    伤害计算类型: '毒经宠物伤害',
    技能伤害系数: 250.0,
    技能增益列表: 灵蛇增益,
    技能等级数据: {
      1: { 基础伤害_基础值: 40, 基础伤害_浮动值: 4 },
      2: { 基础伤害_基础值: 66, 基础伤害_浮动值: 7 },
    },
  },
  {
    技能ID: '25773',
    技能名称: '蛇影·神兵',
    基础伤害_浮动值: 2,
    技能伤害系数: 60,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
