import React, { useState } from 'react'
import styles from './index.module.less'
import ReportContext from './context'
import Music from './背景音乐'
import Control from './控制'

import Step0_入口页 from './报告组件/Step0_入口页'
import Step1_总览 from './报告组件/Step1_总览'
import Step2_心法接入 from './报告组件/Step2_心法接入'
import Step3_流量统计 from './报告组件/Step3_流量统计'
import Step4_心法流量统计 from './报告组件/Step4_心法流量统计'
import Step5_访问时长统计 from './报告组件/Step5_访问时长统计'
import Step6_访问时间统计 from './报告组件/Step6_访问时间统计'
import Step7_使用情况统计 from './报告组件/Step7_使用情况统计'
import Step8_致谢名单 from './报告组件/Step8_致谢名单'
import Step9_致谢名单_1 from './报告组件/Step9_致谢名单_1'
import Step10_致谢名单_2 from './报告组件/Step10_致谢名单_2'
import Step11_致谢名单_3 from './报告组件/Step11_致谢名单_3'
import Step12_结尾 from './报告组件/Step12_结尾'

const ComponentMap = [
  {
    title: '入口页',
    component: <Step0_入口页 />,
  },
  {
    title: '总览',
    component: <Step1_总览 />,
  },
  {
    title: '心法接入时间',
    component: <Step2_心法接入 />,
  },
  {
    title: '总流量统计',
    component: <Step3_流量统计 />,
  },
  {
    title: '各心法流量统计',
    component: <Step4_心法流量统计 />,
  },
  {
    title: '各心法访问时长',
    component: <Step5_访问时长统计 />,
  },
  {
    title: '访问时间占比',
    component: <Step6_访问时间统计 />,
  },
  {
    title: '个人使用情况',
    component: <Step7_使用情况统计 />,
  },
  {
    title: '致谢',
    component: <Step8_致谢名单 />,
  },
  {
    title: '致谢名单·1',
    component: <Step9_致谢名单_1 />,
  },
  {
    title: '致谢名单·2',
    component: <Step10_致谢名单_2 />,
  },
  {
    title: '致谢名单·3',
    component: <Step11_致谢名单_3 />,
  },
  {
    title: '尾篇',
    component: <Step12_结尾 />,
  },
]

function 年度报告() {
  const [step, setStep] = useState(0)

  const nextStep = () => {
    let next = step + 1
    if (next > ComponentMap.length - 1) {
      next = 0
    }
    setStep(next)
  }

  return (
    <ReportContext.Provider value={{ step, setStep, nextStep }}>
      <div className={styles.content}>
        <div className={styles.main}>{ComponentMap[step]?.component}</div>
        {step !== 0 ? (
          <>
            <span className={styles.title}>{ComponentMap[step]?.title}</span>
            <Control />
            <Music />
          </>
        ) : null}
      </div>
    </ReportContext.Provider>
  )
}

export default 年度报告
