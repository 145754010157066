// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lW62JfeLSaWq5iJ5xrDo .music-player-controller {
  width: 50px;
  height: 50px;
}
.lW62JfeLSaWq5iJ5xrDo .music-player-controller-setting {
  font-size: 14px;
  text-shadow: 1px 1px #000000;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/背景音乐/index.module.less"],"names":[],"mappings":"AAAA;EASM,WAAA;EACA,YAAA;AAPN;AAHA;EAcM,eAAA;EACA,4BAAA;AARN","sourcesContent":[".music {\n  :global {\n    // .react-jinke-music-player {\n    //   position: absolute;\n    //   right: -10px;\n    //   top: -10px;\n    // }\n\n    .music-player-controller {\n      width: 50px;\n      height: 50px;\n    }\n\n    .music-player-controller-setting {\n      font-size: 14px;\n      text-shadow: 1px 1px rgba(0, 0, 0, 1);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"music": `lW62JfeLSaWq5iJ5xrDo`
};
export default ___CSS_LOADER_EXPORT___;
