import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import { 按数字生成数组 } from '@/工具函数/help'

// 外功会心
export const 无界通用会心10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70162
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用会心_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.1 * item }],
    } as 技能增益列表类型
  })
}

export const 无界通用会心15函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70162
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用会心_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.15 * item }],
    } as 技能增益列表类型
  })
}

export const 无界通用会效10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70162
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用会效_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏外功会心效果等级, 值: 100 * item }],
    } as 技能增益列表类型
  })
}

// 内功会心
export const 无界通用内功会心15函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70345
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功会心_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.15 * item }],
    } as 技能增益列表类型
  })
}

export const 无界通用内功会心20函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70345
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功会心_20·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.2 * item }],
    } as 技能增益列表类型
  })
}

// 70729
export const 无界通用内功会效15函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功会效_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏内功会心效果等级, 值: 150 * item }],
    } as 技能增益列表类型
  })
}

// 被会心概率
// 70973
export const 无界通用内功被会心15函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.15 * item }],
    } as 技能增益列表类型
  })
}
export const 无界通用内功被会心20函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_20·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.2 * item }],
    } as 技能增益列表类型
  })
}
export const 无界通用内功被会心40函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_40·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.4 * item }],
    } as 技能增益列表类型
  })
}
export const 无界通用内功被会心60函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_60·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.6 * item }],
    } as 技能增益列表类型
  })
}
export const 无界通用内功被会心80函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_80·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.8 * item }],
    } as 技能增益列表类型
  })
}
export const 无界通用内功被会心100函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用内功被会心_100·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 1 * item }],
    } as 技能增益列表类型
  })
}
