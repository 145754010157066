import React, { useContext, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import { RightCircleOutlined } from '@ant-design/icons'

const contentPosition = [0, -48, -72]

function Step3() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const contentProps = useSpring({
    transform: `translateY(${contentPosition[action]}px)`,
    delay: 1000,
    config: { duration: 500 },
  })

  const text1props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text2props = useSpring({
    opacity: action > 0 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text3props = useSpring({
    opacity: action > 1 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 2) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 2 ? styles.next : ''}`} onClick={toNextStep}>
      <animated.div style={contentProps}>
        <div className={styles.content}>
          {/* {action > 0 ? ( */}
          <animated.div style={text1props}>
            <h1 className={styles.subTitle}>
              <span>
                总浏览量
                <span className={styles.import}>1,002,319</span>次、
              </span>
              <span>
                访客uv
                <span className={styles.import}>572,854</span>次。
              </span>
            </h1>
          </animated.div>
          <animated.div style={text2props}>
            {/* ) : null} */}
            <h1 className={styles.subTitle} style={{ marginBottom: 48 }}>
              实际使用侠士
              <span className={styles.import}>158,351</span>人。
            </h1>
          </animated.div>
          <animated.div style={text3props}>
            {/* ) : null} */}
            <h1 className={styles.subTitle} style={{ margin: 0 }}>
              你是第壹佰万次吗？
              <RightCircleOutlined style={{ marginLeft: 12 }} />
            </h1>
          </animated.div>
        </div>
      </animated.div>
    </div>
  )
}

export default Step3
