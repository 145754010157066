import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 大漠刀法增益 from './技能增益/大漠刀法'
import 通用武伤增益 from './技能增益/通用增益/通用武伤'
import 烈日斩增益 from './技能增益/烈日斩'
import 悬象著明增益 from './技能增益/悬象著明'
import 生死劫增益 from './技能增益/生死劫'
import 净世破魔击增益 from './技能增益/净世破魔击'
import 驱夜断愁增益 from './技能增益/驱夜断愁'
import 银月斩增益 from './技能增益/银月斩'
import 诛邪镇魔增益 from './技能增益/诛邪镇魔'
import 靡业报劫增益 from './技能增益/靡业报劫'
import 净世破魔击月增益 from './技能增益/净世破魔击月'
import 净世破魔击日增益 from './技能增益/净世破魔击日'
import 银月斩DOT增益 from './技能增益/银月斩DOT'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32816',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-681574.4),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-681574.4) },
      2: { 技能伤害系数: 获取破招实际系数(-674014.16704) },
      3: { 技能伤害系数: 获取破招实际系数(-314572.80000000005) },
      4: { 技能伤害系数: 获取破招实际系数(-674014.16704) },
      5: { 技能伤害系数: 获取破招实际系数(-299452.3340799999) },
    },
  },
  {
    技能ID: '19055',
    技能名称: '通用武伤',
    伤害类型: '外功',
    技能伤害系数: 16,
    技能增益列表: 通用武伤增益,
    技能等级数据: {
      1: { 统计名称: '赤日轮', 武器伤害系数: 1 },
      2: { 统计名称: '烈日斩', 武器伤害系数: 1 },
      3: { 统计名称: '生死劫·日', 武器伤害系数: 1 },
      4: { 统计名称: '生死劫·月', 武器伤害系数: 1 },
      5: { 统计名称: '净世破魔击·日', 武器伤害系数: 2 },
      6: { 统计名称: '净世破魔击·月', 武器伤害系数: 1 },
      7: { 统计名称: '幽月轮', 武器伤害系数: 1 },
      8: { 统计名称: '银月斩', 武器伤害系数: 1 },
      9: { 统计名称: '驱夜断愁', 武器伤害系数: 2 },
      10: { 统计名称: '悬象著明·日', 武器伤害系数: 1 },
      11: { 统计名称: '悬象著明·月', 武器伤害系数: 1 },
    },
  },
  {
    技能ID: '3963',
    技能名称: '烈日斩',
    统计名称: '烈日斩',
    技能伤害系数: 440.1482085000002,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 16,
    技能增益列表: 烈日斩增益,
  },
  {
    技能ID: '13468',
    技能名称: '银月斩',
    统计名称: '银月斩',
    技能伤害系数: 260.9673066000001,
    基础伤害_基础值: 98,
    基础伤害_浮动值: 10,
    技能增益列表: 银月斩增益,
  },
  {
    技能ID: '18280',
    技能名称: '烈日斩·子',
    技能伤害系数: 179.14050000000006,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 16,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '18281',
    技能名称: '银月斩·子',
    技能伤害系数: 101.15600000000003,
    基础伤害_基础值: 98,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '4035',
    技能名称: '生死劫·日',
    统计名称: '生死劫·日',
    秘籍依赖技能: '生死劫',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 生死劫增益,
  },
  {
    技能ID: '4036',
    技能名称: '生死劫·月',
    秘籍依赖技能: '生死劫',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 生死劫增益,
  },
  {
    技能ID: '4326',
    技能名称: '大漠刀法',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 大漠刀法增益,
  },
  {
    技能ID: '4476',
    技能名称: '净世破魔击·月',
    统计名称: '净世破魔击·月',
    秘籍依赖技能: '净世破魔击',
    技能伤害系数: 212.94947940997503,
    基础伤害_基础值: 73,
    基础伤害_浮动值: 2,
    技能增益列表: 净世破魔击月增益,
  },
  {
    技能ID: '14701',
    技能名称: '净世破魔击·日',
    统计名称: '净世破魔击·日',
    秘籍依赖技能: '净世破魔击',
    技能伤害系数: 638.8484382299249,
    基础伤害_基础值: 98,
    基础伤害_浮动值: 10,
    技能增益列表: 净世破魔击日增益,
  },
  {
    技能ID: '4480',
    技能名称: '驱夜断愁',
    统计名称: '驱夜断愁',
    秘籍依赖技能: '驱夜断愁',
    技能伤害系数: 242.55000000000004,
    基础伤害_基础值: 95,
    基础伤害_浮动值: 9,
    技能增益列表: 驱夜断愁增益,
  },
  {
    技能ID: '4482',
    技能名称: '驱夜断愁·背后',
    统计名称: '驱夜断愁',
    秘籍依赖技能: '驱夜断愁',
    技能伤害系数: 331.08075,
    基础伤害_基础值: 123,
    基础伤害_浮动值: 12,
    技能增益列表: 驱夜断愁增益,
  },
  {
    技能ID: '25777',
    技能名称: '驱夜断愁·神兵',
    技能伤害系数: 50,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '26708',
    技能名称: '净体不畏·日',
    统计名称: '净体不畏·日',
    技能伤害系数: 118.8,
    基础伤害_基础值: 100,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 118.8 },
      2: { 技能伤害系数: 89.1 },
      3: { 技能伤害系数: 178.2 },
      4: { 技能伤害系数: 17.82 },
      5: { 技能伤害系数: 17.82 },
    },
  },
  {
    技能ID: '26709',
    技能名称: '净体不畏·月',
    统计名称: '净体不畏·月',
    技能伤害系数: 118.8,
    基础伤害_基础值: 100,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 118.8 },
      2: { 技能伤害系数: 89.1 },
      3: { 技能伤害系数: 178.2 },
      4: { 技能伤害系数: 17.82 },
      5: { 技能伤害系数: 17.82 },
    },
  },
  {
    技能ID: '34348',
    技能名称: '悬象著明·日',
    统计名称: '悬象著明·日',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 悬象著明增益,
  },
  {
    技能ID: '34349',
    技能名称: '悬象著明·月',
    统计名称: '悬象著明·月',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 悬象著明增益,
  },
  {
    技能ID: '34359',
    技能名称: '净世破魔击·月悬象',
    技能伤害系数: 85.38960622500002,
    基础伤害_基础值: 23,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34361',
    技能名称: '净世破魔击·日悬象',
    技能伤害系数: 256.168818675,
    基础伤害_基础值: 30,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34362',
    技能名称: '生死劫·月悬象',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34363',
    技能名称: '生死劫·日悬象',
    技能伤害系数: 67.2,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34985',
    技能名称: '降灵尊',
    技能伤害系数: 303.6528,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '35056',
    技能名称: '靡业报劫',
    技能伤害系数: 160,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '35057',
    技能名称: '靡业报劫·终',
    技能伤害系数: 320,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '35065',
    技能名称: '愿火长燃',
    技能伤害系数: 640,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37336',
    技能名称: '崇光斩恶',
    统计名称: '崇光斩恶',
    技能伤害系数: 763.2,
    基础伤害_基础值: 209,
    基础伤害_浮动值: 240,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 763.2 },
      2: { 技能伤害系数: 938.1 },
      3: { 技能伤害系数: 1290.02 },
      4: { 技能伤害系数: 2098.8 },
      5: { 技能伤害系数: 2579.775 },
      6: { 技能伤害系数: 3547.555 },
    },
  },
  {
    技能ID: '26916',
    技能名称: '诛邪镇魔',
    统计名称: '诛邪镇魔',
    秘籍依赖技能: '净世破魔击',
    技能伤害系数: 415.85847187499996,
    基础伤害_基础值: 209,
    基础伤害_浮动值: 240,
    技能增益列表: 净世破魔击增益,
    伤害计算次数: 2,
    技能等级数据: {
      1: { 技能增益列表: 净世破魔击增益 },
      2: { 技能增益列表: 净世破魔击增益 },
      3: { 技能增益列表: 净世破魔击增益 },
      4: { 技能增益列表: 诛邪镇魔增益 },
      5: { 技能增益列表: 诛邪镇魔增益 },
      6: { 技能增益列表: 诛邪镇魔增益 },
    },
  },
  {
    技能ID: '4202', // 4202_13359
    技能名称: '银月斩(DOT)',
    秘籍依赖技能: '银月斩',
    统计名称: '银月斩(DOT)',
    技能伤害系数: 127.45656000000004,
    DOT生效间隔: 32,
    DOT跳数: 9,
    基础伤害_基础值: 55,
    技能增益列表: 银月斩DOT增益,
  },
  {
    技能ID: '25725', // 25725_34373
    技能名称: '靡业报劫·日(DOT)',
    统计名称: '靡业报劫·日(DOT)',
    技能伤害系数: 1658.8000000000002,
    DOT生效间隔: 32,
    DOT跳数: 7,
    基础伤害_基础值: 30,
    技能增益列表: 靡业报劫增益,
  },
  {
    技能ID: '25726', // 25726_34374
    技能名称: '靡业报劫·月(DOT)',
    统计名称: '靡业报劫·月(DOT)',
    技能伤害系数: 1658.8000000000002,
    DOT生效间隔: 32,
    DOT跳数: 7,
    基础伤害_基础值: 30,
    技能增益列表: 靡业报劫增益,
  },
  {
    技能ID: '25725', // 25725_35038
    技能名称: '靡业报劫·日(DOT)·悬象',
    统计名称: '靡业报劫·日(DOT)',
    技能伤害系数: 1658.8000000000002,
    DOT生效间隔: 32,
    DOT跳数: 7,
    基础伤害_基础值: 30,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '25726', // 25726_35039
    技能名称: '靡业报劫·月(DOT)·悬象',
    统计名称: '靡业报劫·月(DOT)',
    技能伤害系数: 1658.8000000000002,
    DOT生效间隔: 32,
    DOT跳数: 7,
    基础伤害_基础值: 30,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
