import React, { useContext, useEffect, useState } from 'react'
import 致谢名单 from '../../通用组件/致谢名单'
import ReportContext from '../../context'
import styles from './index.module.less'

function Step9() {
  const [action, setAction] = useState<number>(0)
  const { nextStep } = useContext(ReportContext)

  useEffect(() => {
    setTimeout(() => {
      setAction(1)
    }, 1000)
  }, [])

  const toNextStep = () => {
    if (action > 0) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <致谢名单 step={0} />
    </div>
  )
}

export default Step9
