import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 42544,
    图标ID: 1205,
    uid: 42544,
    装备名称: '陶然意·拥狸链',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42543,
    图标ID: 1205,
    uid: 42543,
    装备名称: '陶然意·万仞链',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42540,
    图标ID: 1205,
    uid: 42540,
    装备名称: '陶然意·徇节链',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42539,
    图标ID: 1205,
    uid: 42539,
    装备名称: '陶然意·临溟链',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42538,
    图标ID: 1205,
    uid: 42538,
    装备名称: '陶然意·上造链',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42536,
    图标ID: 1205,
    uid: 42536,
    装备名称: '陶然意·征惯链',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42534,
    图标ID: 1205,
    uid: 42534,
    装备名称: '陶然意·春瓮链',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42533,
    图标ID: 1205,
    uid: 42533,
    装备名称: '陶然意·吴陵链',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42532,
    图标ID: 1205,
    uid: 42532,
    装备名称: '陶然意·穿心链',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42528,
    图标ID: 1205,
    uid: 42528,
    装备名称: '陶然意·蜕羽链',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42526,
    图标ID: 1205,
    uid: 42526,
    装备名称: '陶然意·登锋链',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41414,
    图标ID: 4248,
    uid: 41414,
    装备名称: '澹澄链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1338 },
      { 属性: 属性类型.破招值, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41413,
    图标ID: 4248,
    uid: 41413,
    装备名称: '逋客链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1338 },
      { 属性: 属性类型.破招值, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41352,
    图标ID: 1207,
    uid: 41352,
    装备名称: '临云项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41351,
    图标ID: 1207,
    uid: 41351,
    装备名称: '君信项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41334,
    图标ID: 1201,
    uid: 41334,
    装备名称: '羡雁项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41333,
    图标ID: 1201,
    uid: 41333,
    装备名称: '君深项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41316,
    图标ID: 1200,
    uid: 41316,
    装备名称: '留诗链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41315,
    图标ID: 1200,
    uid: 41315,
    装备名称: '清月项链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41298,
    图标ID: 1207,
    uid: 41298,
    装备名称: '漠名链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41297,
    图标ID: 1207,
    uid: 41297,
    装备名称: '漠若链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42832,
    图标ID: 2838,
    uid: 42832,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42831,
    图标ID: 2838,
    uid: 42831,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功会心等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42830,
    图标ID: 2838,
    uid: 42830,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2075 },
      { 属性: 属性类型.外功破防等级, 值: 7318 },
      { 属性: 属性类型.无双等级, 值: 3818 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41512,
    图标ID: 2838,
    uid: 41512,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41511,
    图标ID: 2838,
    uid: 41511,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功会心等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41510,
    图标ID: 2838,
    uid: 41510,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2075 },
      { 属性: 属性类型.外功破防等级, 值: 7318 },
      { 属性: 属性类型.无双等级, 值: 3818 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42820,
    图标ID: 2838,
    uid: 42820,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 2409 },
      { 属性: 属性类型.外功会心等级, 值: 4585 },
      { 属性: 属性类型.外功会心效果等级, 值: 2293 },
      { 属性: 属性类型.破招值, 值: 2293 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42819,
    图标ID: 2838,
    uid: 42819,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1661 },
      { 属性: 属性类型.破招值, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 4585 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42818,
    图标ID: 2838,
    uid: 42818,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功会心等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41500,
    图标ID: 2838,
    uid: 41500,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 2409 },
      { 属性: 属性类型.外功会心等级, 值: 4585 },
      { 属性: 属性类型.外功会心效果等级, 值: 2293 },
      { 属性: 属性类型.破招值, 值: 2293 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41499,
    图标ID: 2838,
    uid: 41499,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1661 },
      { 属性: 属性类型.破招值, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 4585 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41498,
    图标ID: 2838,
    uid: 41498,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功会心等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41402,
    图标ID: 4248,
    uid: 41402,
    装备名称: '澹澄链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.身法, 值: 546 },
      { 属性: 属性类型.外功基础攻击, 值: 1241 },
      { 属性: 属性类型.破招值, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41401,
    图标ID: 4248,
    uid: 41401,
    装备名称: '逋客链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.力道, 值: 546 },
      { 属性: 属性类型.外功基础攻击, 值: 1241 },
      { 属性: 属性类型.破招值, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 42808,
    图标ID: 2838,
    uid: 42808,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 2932 },
      { 属性: 属性类型.破招值, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42807,
    图标ID: 2838,
    uid: 42807,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功破防等级, 值: 3225 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42806,
    图标ID: 2838,
    uid: 42806,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2709 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41488,
    图标ID: 2838,
    uid: 41488,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 2932 },
      { 属性: 属性类型.破招值, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41487,
    图标ID: 2838,
    uid: 41487,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功破防等级, 值: 3225 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41486,
    图标ID: 2838,
    uid: 41486,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2709 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42463,
    图标ID: 1205,
    uid: 42463,
    装备名称: '陶然意·拥狸链',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42462,
    图标ID: 1205,
    uid: 42462,
    装备名称: '陶然意·万仞链',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42459,
    图标ID: 1205,
    uid: 42459,
    装备名称: '陶然意·徇节链',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42458,
    图标ID: 1205,
    uid: 42458,
    装备名称: '陶然意·临溟链',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42457,
    图标ID: 1205,
    uid: 42457,
    装备名称: '陶然意·上造链',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42455,
    图标ID: 1205,
    uid: 42455,
    装备名称: '陶然意·征惯链',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42453,
    图标ID: 1205,
    uid: 42453,
    装备名称: '陶然意·春瓮链',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42452,
    图标ID: 1205,
    uid: 42452,
    装备名称: '陶然意·吴陵链',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42451,
    图标ID: 1205,
    uid: 42451,
    装备名称: '陶然意·穿心链',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42447,
    图标ID: 1205,
    uid: 42447,
    装备名称: '陶然意·蜕羽链',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42445,
    图标ID: 1205,
    uid: 42445,
    装备名称: '陶然意·登锋链',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42796,
    图标ID: 2838,
    uid: 42796,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42795,
    图标ID: 2838,
    uid: 42795,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功会心等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42794,
    图标ID: 2838,
    uid: 42794,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 1831 },
      { 属性: 属性类型.外功破防等级, 值: 6457 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41476,
    图标ID: 2838,
    uid: 41476,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41475,
    图标ID: 2838,
    uid: 41475,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功会心等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41474,
    图标ID: 2838,
    uid: 41474,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 1831 },
      { 属性: 属性类型.外功破防等级, 值: 6457 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41390,
    图标ID: 4248,
    uid: 41390,
    装备名称: '澹澄链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41389,
    图标ID: 4248,
    uid: 41389,
    装备名称: '逋客链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41286,
    图标ID: 1206,
    uid: 41286,
    装备名称: '御水项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41285,
    图标ID: 1206,
    uid: 41285,
    装备名称: '振藻项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41268,
    图标ID: 1199,
    uid: 41268,
    装备名称: '扬明项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41267,
    图标ID: 1199,
    uid: 41267,
    装备名称: '重悬项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41250,
    图标ID: 4249,
    uid: 41250,
    装备名称: '静言链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41249,
    图标ID: 4249,
    uid: 41249,
    装备名称: '棣仪链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42784,
    图标ID: 2838,
    uid: 42784,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 2124 },
      { 属性: 属性类型.外功会心等级, 值: 4043 },
      { 属性: 属性类型.外功会心效果等级, 值: 2021 },
      { 属性: 属性类型.破招值, 值: 2021 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42783,
    图标ID: 2838,
    uid: 42783,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42782,
    图标ID: 2838,
    uid: 42782,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功会心等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41464,
    图标ID: 2838,
    uid: 41464,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 2124 },
      { 属性: 属性类型.外功会心等级, 值: 4043 },
      { 属性: 属性类型.外功会心效果等级, 值: 2021 },
      { 属性: 属性类型.破招值, 值: 2021 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41463,
    图标ID: 2838,
    uid: 41463,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41462,
    图标ID: 2838,
    uid: 41462,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功会心等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41238,
    图标ID: 1206,
    uid: 41238,
    装备名称: '壁明链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41237,
    图标ID: 1206,
    uid: 41237,
    装备名称: '壁邺链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42772,
    图标ID: 2838,
    uid: 42772,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 2583 },
      { 属性: 属性类型.破招值, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42771,
    图标ID: 2838,
    uid: 42771,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 1685 },
      { 属性: 属性类型.外功破防等级, 值: 2841 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42770,
    图标ID: 2838,
    uid: 42770,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41452,
    图标ID: 2838,
    uid: 41452,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 2583 },
      { 属性: 属性类型.破招值, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41451,
    图标ID: 2838,
    uid: 41451,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 1685 },
      { 属性: 属性类型.外功破防等级, 值: 2841 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41450,
    图标ID: 2838,
    uid: 41450,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41166,
    图标ID: 4249,
    uid: 41166,
    装备名称: '语晴链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41165,
    图标ID: 4249,
    uid: 41165,
    装备名称: '语穹链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41378,
    图标ID: 4248,
    uid: 41378,
    装备名称: '澹澄链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.身法, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41377,
    图标ID: 4248,
    uid: 41377,
    装备名称: '逋客链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.力道, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 42866,
    图标ID: 1199,
    uid: 42866,
    装备名称: '平皋链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42865,
    图标ID: 1199,
    uid: 42865,
    装备名称: '淮沛链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42848,
    图标ID: 1199,
    uid: 42848,
    装备名称: '羽缀链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42847,
    图标ID: 1199,
    uid: 42847,
    装备名称: '夏凌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42736,
    图标ID: 2833,
    uid: 42736,
    装备名称: '灵空·风行链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42735,
    图标ID: 2833,
    uid: 42735,
    装备名称: '灵空·撼地链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42623,
    图标ID: 1199,
    uid: 42623,
    装备名称: '润源链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42622,
    图标ID: 1199,
    uid: 42622,
    装备名称: '辞羁链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41220,
    图标ID: 1199,
    uid: 41220,
    装备名称: '润源链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41219,
    图标ID: 1199,
    uid: 41219,
    装备名称: '辞羁链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41202,
    图标ID: 1199,
    uid: 41202,
    装备名称: '平皋链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41201,
    图标ID: 1199,
    uid: 41201,
    装备名称: '淮沛链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41184,
    图标ID: 1199,
    uid: 41184,
    装备名称: '羽缀链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41183,
    图标ID: 1199,
    uid: 41183,
    装备名称: '夏凌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41094,
    图标ID: 4248,
    uid: 41094,
    装备名称: '镂云链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41093,
    图标ID: 4248,
    uid: 41093,
    装备名称: '炎翳链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41076,
    图标ID: 4248,
    uid: 41076,
    装备名称: '智渊链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41075,
    图标ID: 4248,
    uid: 41075,
    装备名称: '韶曦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42760,
    图标ID: 2838,
    uid: 42760,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42759,
    图标ID: 2838,
    uid: 42759,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功会心等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42758,
    图标ID: 2838,
    uid: 42758,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功破防等级, 值: 5682 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41440,
    图标ID: 2838,
    uid: 41440,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41439,
    图标ID: 2838,
    uid: 41439,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功会心等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41438,
    图标ID: 2838,
    uid: 41438,
    装备名称: '无修链·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功破防等级, 值: 5682 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41148,
    图标ID: 4249,
    uid: 41148,
    装备名称: '语晴链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.身法, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41147,
    图标ID: 4249,
    uid: 41147,
    装备名称: '语穹链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.力道, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41058,
    图标ID: 4250,
    uid: 41058,
    装备名称: '修篁链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.身法, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41057,
    图标ID: 4250,
    uid: 41057,
    装备名称: '绮陌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.力道, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41130,
    图标ID: 4249,
    uid: 41130,
    装备名称: '语晴链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.身法, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.外功破防等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41129,
    图标ID: 4249,
    uid: 41129,
    装备名称: '语穹链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.力道, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.外功破防等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41040,
    图标ID: 4249,
    uid: 41040,
    装备名称: '涵煦链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.身法, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功会心等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41039,
    图标ID: 4249,
    uid: 41039,
    装备名称: '惜霜链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.力道, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功会心等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41112,
    图标ID: 4249,
    uid: 41112,
    装备名称: '语晴链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.身法, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.外功破防等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41111,
    图标ID: 4249,
    uid: 41111,
    装备名称: '语穹链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.力道, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.外功破防等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41522,
    图标ID: 4250,
    uid: 41522,
    装备名称: '春齐链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41521,
    图标ID: 4250,
    uid: 41521,
    装备名称: '曲汜链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41022,
    图标ID: 4252,
    uid: 41022,
    装备名称: '嵘嶂链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41021,
    图标ID: 4252,
    uid: 41021,
    装备名称: '湛影链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 项链装备数据
