import React, { useEffect, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import styles from './index.module.less'

function Step11() {
  const [action, setAction] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      setAction(1)
    }, 1000)
  }, [])

  const toNextStep = () => {
    if (action > 0) {
      window?.close()
    }
  }

  const animationProps = useSpring({
    from: { opacity: 0 }, // 初始时位置在左侧，完全透明
    to: { opacity: 1 }, // 最后到达正常位置，完全不透明
    config: { duration: 2000 }, // 动画的持续时间，可以根据需要调整
    onRest: () => setAction(1),
  })

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <animated.div style={animationProps}>
        <div>
          <h1 className={styles.title}>感谢观看，幸甚有你</h1>
        </div>
      </animated.div>
    </div>
  )
}

export default Step11
