import type { 循环数据 } from '@/@types/循环'
import { 循环合并函数 } from '@/数据/数据工具/获取循环信息'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// import 测试 from './测试.json'
// import 测试单个 from './测试单个.json'
import 击破满血木桩 from './击破满血木桩.json'
import 击破斩杀木桩 from './击破斩杀木桩.json'
import 击破均摊计算 from './击破均摊计算.json'
import 龙破满血木桩 from './龙破满血木桩.json'
import 龙破斩杀木桩 from './龙破斩杀木桩.json'
import 龙破均摊计算 from './龙破均摊计算.json'
import 龙渊5段加速 from './龙渊5段加速.json'
import 击渊5段加速 from './击渊5段加速.json'
// import 橙武 from './橙武.json'

const 击破均摊计算数据 = {
  ...击破均摊计算,
  循环详情: 循环合并函数([
    {
      覆盖率: 0.2,
      详情: 击破满血木桩?.循环详情,
    },
    {
      覆盖率: 0.8,
      详情: 击破斩杀木桩?.循环详情,
    },
  ]),
}

const 龙破均摊计算数据 = {
  ...龙破均摊计算,
  循环详情: 循环合并函数([
    {
      覆盖率: 0.2,
      详情: 龙破满血木桩?.循环详情,
    },
    {
      覆盖率: 0.8,
      详情: 龙破斩杀木桩?.循环详情,
    },
  ]),
}

const 计算循环: 循环数据[] = [
  // 测试单个,
  // 测试,
  击渊5段加速,
  龙渊5段加速,
  龙破均摊计算数据,
  击破均摊计算数据,

  龙破满血木桩,
  龙破斩杀木桩,
  击破满血木桩,
  击破斩杀木桩,
] as 循环数据[]

export default 计算循环
