import React, { useContext, useMemo, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import { 心法 } from '@/心法模块/index'
import ComponentReveal from '../../通用组件/多组件渐显'
import { RightCircleOutlined } from '@ant-design/icons'

const contentPosition = [0, -48]

function Step7() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const contentProps = useSpring({
    transform: `translateY(${contentPosition[action]}px)`,
    delay: 1000,
    config: { duration: 500 },
  })

  const 心法列表 = useMemo(() => {
    const obj = getLocalStorageKeysWithKey('全部方案数据')
    const res = Object.keys(obj)
      ?.map((key) => {
        const name = key?.replace('_全部方案数据', '')
        const 心法数据 = 心法?.[name]
        return {
          心法: name,
          主题色: 心法数据?.系统配置?.主题色,
          图标: 心法数据?.系统配置?.心法图标,
        }
      })
      ?.filter((item) => item?.图标)
    return res
  }, [])

  const 自定义循环数量 = useMemo(() => {
    const obj = getLocalStorageKeysWithKey('自定义循环')
    let res = 0
    Object.values(obj)?.forEach((心法数据) => {
      try {
        const data = JSON.parse(心法数据 as any)
        const num = Object.keys?.(data)?.length
        res = res + num
      } catch {
        return
      }
    })
    return res
  }, [])

  const text1props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text2props = useSpring({
    opacity: action > 0 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text3props = useSpring({
    opacity: action > 1 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 1) {
      nextStep()
    }
  }

  const getFinalText = (num) => {
    if (!num) {
      return '马上体验起来吧！'
    } else if (num < 3) {
      return '你是很专注的用户呢，相信你对该心法一定有很深的理解。'
    } else if (num >= 3) {
      return '看来你擅长很多心法，一定是团队中的多面手！'
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 2 ? styles.next : ''}`} onClick={toNextStep}>
      <animated.div style={contentProps}>
        <div className={styles.content}>
          {/* {action > 0 ? ( */}
          <animated.div style={text1props}>
            <h1 className={styles.subTitle}>
              {心法列表?.length ? (
                <span>
                  去年您一共使用计算器访问了
                  <span className={styles.import}>{心法列表?.length}</span>个心法
                </span>
              ) : (
                <span>看起来您还没有深入使用过计算器呢.</span>
              )}
            </h1>
          </animated.div>
          {心法列表?.length ? (
            <div className={styles.iconList}>
              <ComponentReveal
                list={心法列表.map((心法) => {
                  return <img src={心法?.图标} key={`谁用过心法_${心法?.心法}`} />
                })}
              />
            </div>
          ) : null}
          <animated.div style={text2props}>
            <h1 className={styles.subTitle}>{getFinalText(心法列表?.length)}</h1>
            {!自定义循环数量 ? <RightCircleOutlined style={{ marginLeft: 12 }} /> : null}
          </animated.div>
          {自定义循环数量 ? (
            <animated.div style={text3props}>
              <h1 className={styles.subTitle}>
                共使用计算器录入了
                <span className={styles.import}>{自定义循环数量}</span>个自定义循环。
              </h1>
              <h1 className={styles.subTitle}>
                相信对计算器你已经了如指掌
                <RightCircleOutlined style={{ marginLeft: 12 }} />
              </h1>
            </animated.div>
          ) : null}
        </div>
      </animated.div>
    </div>
  )
}

export default Step7

function getLocalStorageKeysWithKey(target) {
  // 创建一个对象来存储匹配的数据
  const result = {}

  // 遍历localStorage的所有键
  for (let i = 0; i < localStorage.length; i++) {
    // 获取当前键
    const key: string = localStorage.key(i) || ''

    // 检查键是否包含字母"a"
    if (key?.includes(target)) {
      // 如果包含，就将对应的数据存储到result对象中
      result[key] = localStorage.getItem(key)
    }
  }
  return result
}
