import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 贪破函数 = () => {
  const 数组 = 按数字生成数组(10)
  // 24453
  // 可叠加多层，最多10层
  return 数组.map((item) => {
    return {
      增益名称: `贪破·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏破招, 值: -30 * item }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('内功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 307 / 1024 }],
  },
  // 套装双会特效
  {
    增益名称: '佛吼',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 41 },
    ],
  },
  {
    // 11979
    增益名称: '罗汉金身',
    增益所在位置: '奇穴',
    依赖奇穴: '金刚怒目',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 308 },
      { 属性: 属性类型.郭氏内功破防等级, 值: 308 },
    ],
  },
  {
    // 11979_快照
    增益名称: '罗汉金身_快照',
    增益所在位置: '奇穴',
    依赖奇穴: '金刚怒目',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 308 }],
  },
  {
    // 11979_常驻
    增益名称: '罗汉金身_常驻',
    增益所在位置: '奇穴',
    依赖奇穴: '金刚怒目',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功破防等级, 值: 308 }],
  },
  {
    // 2686
    增益名称: '擒龙诀',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 204 }],
  },
  {
    // 10023
    增益名称: '伏魔',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  {
    // 24285
    增益名称: '金刚日轮',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.06 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 62 },
    ],
  },
  {
    // 21859
    增益名称: '身意',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.05 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 52 },
    ],
  },
  {
    // 19635_1
    增益名称: '普渡·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 41 / 1024 }],
  },
  {
    // 19635_2
    增益名称: '普渡·2',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 82 / 1024 }],
  },
  {
    // 19635_3
    增益名称: '普渡·3',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 123 / 1024 }],
  },
  {
    // 12590_1
    增益名称: '三生·1',
    增益所在位置: '奇穴',
    依赖奇穴: '三生',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 102 }],
    // 增益集合: [{ 属性: 属性类型.郭氏无双, 值: 110 }],
  },
  {
    // 12590_2
    增益名称: '三生·2',
    增益所在位置: '奇穴',
    依赖奇穴: '三生',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 205 }],
  },
  {
    // 12590_3
    增益名称: '三生·3',
    增益所在位置: '奇穴',
    依赖奇穴: '三生',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 307 }],
  },
  {
    // 13910
    增益名称: '众嗔',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    // 29547
    增益名称: '华香',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 614 }],
  },
  ...贪破函数(),
  // {
  //   增益名称: '枭神_快照',
  //   增益所在位置: '技能',
  //   // 增益类型: '部分启用',
  //   增益类型: '部分启用',
  //   增益集合: [
  //     { 属性: 属性类型.内功会心百分比, 值: 0.1 },
  //     { 属性: 属性类型.郭氏内功会心效果等级, 值: 100 },
  //   ],
  // },
]

export default 通用增益
