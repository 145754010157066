import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 40397,
    图标ID: 802,
    uid: 40397,
    装备名称: '陶然意·拥狸囊',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40396,
    图标ID: 802,
    uid: 40396,
    装备名称: '陶然意·万仞囊',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.力道, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40393,
    图标ID: 802,
    uid: 40393,
    装备名称: '陶然意·徇节囊',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40392,
    图标ID: 802,
    uid: 40392,
    装备名称: '陶然意·临溟囊',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40391,
    图标ID: 802,
    uid: 40391,
    装备名称: '陶然意·上造囊',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.力道, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40389,
    图标ID: 802,
    uid: 40389,
    装备名称: '陶然意·征惯囊',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40387,
    图标ID: 802,
    uid: 40387,
    装备名称: '陶然意·春瓮囊',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.力道, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40386,
    图标ID: 802,
    uid: 40386,
    装备名称: '陶然意·吴陵囊',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40385,
    图标ID: 802,
    uid: 40385,
    装备名称: '陶然意·穿心囊',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.力道, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40381,
    图标ID: 802,
    uid: 40381,
    装备名称: '陶然意·蜕羽囊',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.身法, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40379,
    图标ID: 802,
    uid: 40379,
    装备名称: '陶然意·登锋囊',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.力道, 值: 656 },
      { 属性: 属性类型.外功基础攻击, 值: 1070 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39361,
    图标ID: 2899,
    uid: 39361,
    装备名称: '举清囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39360,
    图标ID: 2899,
    uid: 39360,
    装备名称: '初落囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39289,
    图标ID: 806,
    uid: 39289,
    装备名称: '羡雁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39288,
    图标ID: 806,
    uid: 39288,
    装备名称: '君深囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39249,
    图标ID: 805,
    uid: 39249,
    装备名称: '留诗箭囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39248,
    图标ID: 805,
    uid: 39248,
    装备名称: '清月箭囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39145,
    图标ID: 798,
    uid: 39145,
    装备名称: '漠名囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39144,
    图标ID: 798,
    uid: 39144,
    装备名称: '漠若囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40884,
    图标ID: 801,
    uid: 40884,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 3009 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功会心效果等级, 值: 2864 },
      { 属性: 属性类型.破招值, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40883,
    图标ID: 801,
    uid: 40883,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 3009 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功会心效果等级, 值: 2864 },
      { 属性: 属性类型.无双等级, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40882,
    图标ID: 801,
    uid: 40882,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 2490 },
      { 属性: 属性类型.外功破防等级, 值: 8782 },
      { 属性: 属性类型.无双等级, 值: 4582 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39437,
    图标ID: 801,
    uid: 39437,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 3009 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功会心效果等级, 值: 2864 },
      { 属性: 属性类型.破招值, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39436,
    图标ID: 801,
    uid: 39436,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 3009 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功会心效果等级, 值: 2864 },
      { 属性: 属性类型.无双等级, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39435,
    图标ID: 801,
    uid: 39435,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.外功基础攻击, 值: 2490 },
      { 属性: 属性类型.外功破防等级, 值: 8782 },
      { 属性: 属性类型.无双等级, 值: 4582 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40878,
    图标ID: 801,
    uid: 40878,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 2891 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.外功会心效果等级, 值: 2751 },
      { 属性: 属性类型.无双等级, 值: 2751 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40877,
    图标ID: 801,
    uid: 40877,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 2891 },
      { 属性: 属性类型.外功会心等级, 值: 3668 },
      { 属性: 属性类型.破招值, 值: 3668 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40876,
    图标ID: 801,
    uid: 40876,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 3389 },
      { 属性: 属性类型.无双等级, 值: 8070 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39431,
    图标ID: 801,
    uid: 39431,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 2891 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.外功会心效果等级, 值: 2751 },
      { 属性: 属性类型.无双等级, 值: 2751 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39430,
    图标ID: 801,
    uid: 39430,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 2891 },
      { 属性: 属性类型.外功会心等级, 值: 3668 },
      { 属性: 属性类型.破招值, 值: 3668 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39429,
    图标ID: 801,
    uid: 39429,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.外功基础攻击, 值: 3389 },
      { 属性: 属性类型.无双等级, 值: 8070 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40872,
    图标ID: 801,
    uid: 40872,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2773 },
      { 属性: 属性类型.外功会心等级, 值: 3519 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 40871,
    图标ID: 801,
    uid: 40871,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2773 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40870,
    图标ID: 801,
    uid: 40870,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2295 },
      { 属性: 属性类型.外功会心等级, 值: 8093 },
      { 属性: 属性类型.无双等级, 值: 4222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39425,
    图标ID: 801,
    uid: 39425,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2773 },
      { 属性: 属性类型.外功会心等级, 值: 3519 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39424,
    图标ID: 801,
    uid: 39424,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2773 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39423,
    图标ID: 801,
    uid: 39423,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.外功基础攻击, 值: 2295 },
      { 属性: 属性类型.外功会心等级, 值: 8093 },
      { 属性: 属性类型.无双等级, 值: 4222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 40342,
    图标ID: 802,
    uid: 40342,
    装备名称: '陶然意·拥狸囊',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40341,
    图标ID: 802,
    uid: 40341,
    装备名称: '陶然意·万仞囊',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40338,
    图标ID: 802,
    uid: 40338,
    装备名称: '陶然意·徇节囊',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40337,
    图标ID: 802,
    uid: 40337,
    装备名称: '陶然意·临溟囊',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40336,
    图标ID: 802,
    uid: 40336,
    装备名称: '陶然意·上造囊',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40334,
    图标ID: 802,
    uid: 40334,
    装备名称: '陶然意·征惯囊',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40332,
    图标ID: 802,
    uid: 40332,
    装备名称: '陶然意·春瓮囊',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40331,
    图标ID: 802,
    uid: 40331,
    装备名称: '陶然意·吴陵囊',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40330,
    图标ID: 802,
    uid: 40330,
    装备名称: '陶然意·穿心囊',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40326,
    图标ID: 802,
    uid: 40326,
    装备名称: '陶然意·蜕羽囊',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40324,
    图标ID: 802,
    uid: 40324,
    装备名称: '陶然意·登锋囊',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 920 },
      { 属性: 属性类型.外功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40866,
    图标ID: 801,
    uid: 40866,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功会心效果等级, 值: 2527 },
      { 属性: 属性类型.破招值, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40865,
    图标ID: 801,
    uid: 40865,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功会心效果等级, 值: 2527 },
      { 属性: 属性类型.无双等级, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40864,
    图标ID: 801,
    uid: 40864,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2197 },
      { 属性: 属性类型.外功破防等级, 值: 7748 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40832,
    图标ID: 821,
    uid: 40832,
    装备名称: '碎菱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40831,
    图标ID: 821,
    uid: 40831,
    装备名称: '泊澜囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40061,
    图标ID: 2900,
    uid: 40061,
    装备名称: '孤山寒月·逐刃囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40060,
    图标ID: 2900,
    uid: 40060,
    装备名称: '孤山寒月·归期囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39419,
    图标ID: 801,
    uid: 39419,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功会心效果等级, 值: 2527 },
      { 属性: 属性类型.破招值, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39418,
    图标ID: 801,
    uid: 39418,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功会心效果等级, 值: 2527 },
      { 属性: 属性类型.无双等级, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39417,
    图标ID: 801,
    uid: 39417,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.外功基础攻击, 值: 2197 },
      { 属性: 属性类型.外功破防等级, 值: 7748 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39105,
    图标ID: 804,
    uid: 39105,
    装备名称: '扬明囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39104,
    图标ID: 804,
    uid: 39104,
    装备名称: '重悬囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39065,
    图标ID: 802,
    uid: 39065,
    装备名称: '静言囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39064,
    图标ID: 802,
    uid: 39064,
    装备名称: '棣仪囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41051,
    图标ID: 798,
    uid: 41051,
    装备名称: '敬行囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7313 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 1355 },
      { 属性: 属性类型.外功破防等级, 值: 4986 },
      { 属性: 属性类型.无双等级, 值: 3989 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41050,
    图标ID: 798,
    uid: 41050,
    装备名称: '纵远囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7313 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 1355 },
      { 属性: 属性类型.外功破防等级, 值: 4986 },
      { 属性: 属性类型.无双等级, 值: 3989 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40860,
    图标ID: 801,
    uid: 40860,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2549 },
      { 属性: 属性类型.外功会心等级, 值: 4851 },
      { 属性: 属性类型.外功会心效果等级, 值: 2426 },
      { 属性: 属性类型.无双等级, 值: 2426 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40859,
    图标ID: 801,
    uid: 40859,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2549 },
      { 属性: 属性类型.外功会心等级, 值: 3234 },
      { 属性: 属性类型.破招值, 值: 3234 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40858,
    图标ID: 801,
    uid: 40858,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2988 },
      { 属性: 属性类型.无双等级, 值: 7115 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39413,
    图标ID: 801,
    uid: 39413,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2549 },
      { 属性: 属性类型.外功会心等级, 值: 4851 },
      { 属性: 属性类型.外功会心效果等级, 值: 2426 },
      { 属性: 属性类型.无双等级, 值: 2426 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39412,
    图标ID: 801,
    uid: 39412,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2549 },
      { 属性: 属性类型.外功会心等级, 值: 3234 },
      { 属性: 属性类型.破招值, 值: 3234 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39411,
    图标ID: 801,
    uid: 39411,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.外功基础攻击, 值: 2988 },
      { 属性: 属性类型.无双等级, 值: 7115 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38963,
    图标ID: 2903,
    uid: 38963,
    装备名称: '壁明囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 1282 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38962,
    图标ID: 2903,
    uid: 38962,
    装备名称: '壁邺囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 1282 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40854,
    图标ID: 801,
    uid: 40854,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2443 },
      { 属性: 属性类型.外功会心等级, 值: 3099 },
      { 属性: 属性类型.外功破防等级, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 40853,
    图标ID: 801,
    uid: 40853,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2443 },
      { 属性: 属性类型.外功破防等级, 值: 3099 },
      { 属性: 属性类型.破招值, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40852,
    图标ID: 801,
    uid: 40852,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2021 },
      { 属性: 属性类型.外功会心等级, 值: 7129 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39407,
    图标ID: 801,
    uid: 39407,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2443 },
      { 属性: 属性类型.外功会心等级, 值: 3099 },
      { 属性: 属性类型.外功破防等级, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39406,
    图标ID: 801,
    uid: 39406,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2443 },
      { 属性: 属性类型.外功破防等级, 值: 3099 },
      { 属性: 属性类型.破招值, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39405,
    图标ID: 801,
    uid: 39405,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.外功基础攻击, 值: 2021 },
      { 属性: 属性类型.外功会心等级, 值: 7129 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 38843,
    图标ID: 802,
    uid: 38843,
    装备名称: '语壁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.身法, 值: 556 },
      { 属性: 属性类型.外功基础攻击, 值: 1263 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38842,
    图标ID: 802,
    uid: 38842,
    装备名称: '语掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.力道, 值: 556 },
      { 属性: 属性类型.外功基础攻击, 值: 1263 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40933,
    图标ID: 801,
    uid: 40933,
    装备名称: '润源囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 40932,
    图标ID: 801,
    uid: 40932,
    装备名称: '辞羁囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40918,
    图标ID: 801,
    uid: 40918,
    装备名称: '平皋囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40917,
    图标ID: 801,
    uid: 40917,
    装备名称: '淮沛囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40579,
    图标ID: 801,
    uid: 40579,
    装备名称: '羽缀囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40578,
    图标ID: 801,
    uid: 40578,
    装备名称: '夏凌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38925,
    图标ID: 801,
    uid: 38925,
    装备名称: '润源囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38924,
    图标ID: 801,
    uid: 38924,
    装备名称: '辞羁囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38887,
    图标ID: 801,
    uid: 38887,
    装备名称: '平皋囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38886,
    图标ID: 801,
    uid: 38886,
    装备名称: '淮沛囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38849,
    图标ID: 801,
    uid: 38849,
    装备名称: '羽缀囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38848,
    图标ID: 801,
    uid: 38848,
    装备名称: '夏凌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38787,
    图标ID: 814,
    uid: 38787,
    装备名称: '镂云囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38786,
    图标ID: 814,
    uid: 38786,
    装备名称: '炎翳囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38749,
    图标ID: 814,
    uid: 38749,
    装备名称: '智渊囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38748,
    图标ID: 814,
    uid: 38748,
    装备名称: '韶曦囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40848,
    图标ID: 801,
    uid: 40848,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 2336 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功会心效果等级, 值: 2223 },
      { 属性: 属性类型.破招值, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40847,
    图标ID: 801,
    uid: 40847,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 2336 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功会心效果等级, 值: 2223 },
      { 属性: 属性类型.无双等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40846,
    图标ID: 801,
    uid: 40846,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 1934 },
      { 属性: 属性类型.外功破防等级, 值: 6819 },
      { 属性: 属性类型.无双等级, 值: 3558 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39401,
    图标ID: 801,
    uid: 39401,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 2336 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功会心效果等级, 值: 2223 },
      { 属性: 属性类型.破招值, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39400,
    图标ID: 801,
    uid: 39400,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 2336 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功会心效果等级, 值: 2223 },
      { 属性: 属性类型.无双等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39399,
    图标ID: 801,
    uid: 39399,
    装备名称: '无修囊·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.外功基础攻击, 值: 1934 },
      { 属性: 属性类型.外功破防等级, 值: 6819 },
      { 属性: 属性类型.无双等级, 值: 3558 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38837,
    图标ID: 802,
    uid: 38837,
    装备名称: '语壁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6390 },
      { 属性: 属性类型.身法, 值: 521 },
      { 属性: 属性类型.外功基础攻击, 值: 1184 },
      { 属性: 属性类型.外功会心等级, 值: 4357 },
      { 属性: 属性类型.无双等级, 值: 3486 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38836,
    图标ID: 802,
    uid: 38836,
    装备名称: '语掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6390 },
      { 属性: 属性类型.力道, 值: 521 },
      { 属性: 属性类型.外功基础攻击, 值: 1184 },
      { 属性: 属性类型.外功会心等级, 值: 4357 },
      { 属性: 属性类型.无双等级, 值: 3486 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38711,
    图标ID: 803,
    uid: 38711,
    装备名称: '修篁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6193 },
      { 属性: 属性类型.身法, 值: 505 },
      { 属性: 属性类型.外功基础攻击, 值: 1147 },
      { 属性: 属性类型.外功会心等级, 值: 4222 },
      { 属性: 属性类型.无双等级, 值: 3378 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38710,
    图标ID: 803,
    uid: 38710,
    装备名称: '绮陌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6193 },
      { 属性: 属性类型.力道, 值: 505 },
      { 属性: 属性类型.外功基础攻击, 值: 1147 },
      { 属性: 属性类型.外功会心等级, 值: 4222 },
      { 属性: 属性类型.无双等级, 值: 3378 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38831,
    图标ID: 802,
    uid: 38831,
    装备名称: '语壁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5962 },
      { 属性: 属性类型.身法, 值: 486 },
      { 属性: 属性类型.外功基础攻击, 值: 1105 },
      { 属性: 属性类型.外功会心等级, 值: 4065 },
      { 属性: 属性类型.无双等级, 值: 3252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38830,
    图标ID: 802,
    uid: 38830,
    装备名称: '语掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5962 },
      { 属性: 属性类型.力道, 值: 486 },
      { 属性: 属性类型.外功基础攻击, 值: 1105 },
      { 属性: 属性类型.外功会心等级, 值: 4065 },
      { 属性: 属性类型.无双等级, 值: 3252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38673,
    图标ID: 809,
    uid: 38673,
    装备名称: '涵煦囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5797 },
      { 属性: 属性类型.身法, 值: 473 },
      { 属性: 属性类型.外功基础攻击, 值: 1074 },
      { 属性: 属性类型.加速等级, 值: 3953 },
      { 属性: 属性类型.无双等级, 值: 3162 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38672,
    图标ID: 809,
    uid: 38672,
    装备名称: '惜霜囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5797 },
      { 属性: 属性类型.力道, 值: 473 },
      { 属性: 属性类型.外功基础攻击, 值: 1074 },
      { 属性: 属性类型.加速等级, 值: 3953 },
      { 属性: 属性类型.无双等级, 值: 3162 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38825,
    图标ID: 802,
    uid: 38825,
    装备名称: '语壁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5534 },
      { 属性: 属性类型.身法, 值: 451 },
      { 属性: 属性类型.外功基础攻击, 值: 1025 },
      { 属性: 属性类型.外功会心等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3019 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38824,
    图标ID: 802,
    uid: 38824,
    装备名称: '语掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5534 },
      { 属性: 属性类型.力道, 值: 451 },
      { 属性: 属性类型.外功基础攻击, 值: 1025 },
      { 属性: 属性类型.外功会心等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3019 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39473,
    图标ID: 803,
    uid: 39473,
    装备名称: '春齐囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.身法, 值: 443 },
      { 属性: 属性类型.外功基础攻击, 值: 1007 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39472,
    图标ID: 803,
    uid: 39472,
    装备名称: '曲汜囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.力道, 值: 443 },
      { 属性: 属性类型.外功基础攻击, 值: 1007 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38635,
    图标ID: 2900,
    uid: 38635,
    装备名称: '嵘嶂囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.身法, 值: 412 },
      { 属性: 属性类型.外功基础攻击, 值: 671 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.全能等级, 值: 1482 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38634,
    图标ID: 2900,
    uid: 38634,
    装备名称: '湛影囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.力道, 值: 412 },
      { 属性: 属性类型.外功基础攻击, 值: 671 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.全能等级, 值: 1482 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 暗器装备数据
