/**
 * 访问量图表
 */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as G2 from '@antv/g2'
import { 心法 } from '@/心法模块/index'
import styles from './index.module.less'
import { formatSeconds } from './utils'

function 技能统计图表(props, ref) {
  const { data } = props
  const [chartData, setChartData] = useState<any>()

  const limitRef: any = useRef<any>()

  const getDataSource = () => {
    const res = Object.keys(data)?.map((key) => {
      const trueName = key?.replace('·', '_')
      const color = 心法?.[trueName]?.系统配置?.主题色
      return {
        心法: key,
        时长: data[key],
        颜色: color,
      }
    })
    res.sort((a, b) => a.时长 - b.时长)
    return res
  }

  useImperativeHandle(ref, () => ({
    initChart: initChart,
  }))

  useEffect(() => {
    limitRef.current = false
    return () => {
      limitRef.current = false
    }
  }, [])

  const initChart = () => {
    if (limitRef.current) {
      return
    }
    limitRef.current = true

    const chart = chartData
      ? chartData
      : new G2.Chart({
          container: 'line-chart',
          autoFit: true,
          renderer: 'canvas',
          padding: [0, 80, 0, 80],
        })

    if (!chartData) {
      setChartData(chart)
    }

    const dataSource = getDataSource()

    const colorList = dataSource?.map((item) => item?.颜色)
    chart.clear()
    chart.axis('心法', {
      title: null,
      tickLine: null,
      line: null,
      label: {
        style: {
          fill: '#ffffff', // 文本的颜色
          fontSize: 12, // 文本大小
        },
        offset: 12,
      },
    })
    chart.axis('时长', false)
    chart.coordinate().transpose()
    chart.legend(false)
    chart
      .interval()
      .position('心法*时长')
      .color('心法', colorList)
      .label('时长', {
        offset: 16,
        style: {
          fill: '#ffffff',
          fontSize: 16,
        },
        content: (obj) => {
          return `${formatSeconds(obj.时长)}`
        },
      })

    chart.data(dataSource)
    chart.render()

    setTimeout(() => {
      limitRef.current = false
    }, 10)
  }

  return <div className={styles.lineChart} id='line-chart' />
}

export default forwardRef(技能统计图表)
