import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 对阵招式增益 from './通用增益/对阵招式增益'

const 引窍: 技能增益列表类型[] = [
  ...通用增益,
  ...对阵招式增益,
  {
    增益名称: '引窍悬剑',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.15 }],
  },
  {
    增益名称: '一阳指增伤',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 308 / 1024 }],
  },
]

export default 引窍
