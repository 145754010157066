import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 傍身招式增益 from './技能增益/通用增益/傍身招式增益'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '100458',
    技能名称: '快雪时晴·悟',
    技能伤害系数: 210,
    技能破招系数: 666 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...傍身招式增益],
  },
  {
    技能ID: '100043',
    技能名称: '玉石俱焚·悟',
    技能伤害系数: 813,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '100047',
    技能名称: '厥阴指·悟',
    技能伤害系数: 676,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '100041',
    技能名称: '兰摧玉折·悟',
    技能伤害系数: 379,
    技能破招系数: 1208 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '101939',
    技能名称: '南风吐月·悟',
    统计名称: '南风吐月·悟',
    技能伤害系数: 621,
    技能破招系数: 1987 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '101583',
    技能名称: '南风吐月·悟·额外',
    统计名称: '南风吐月·悟',
    技能伤害系数: 384,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '70041_101593', // 70041_101593
    技能名称: '兰摧·悟(DOT)',
    统计名称: '兰摧·悟(DOT)',
    技能伤害系数: 323,
    DOT生效间隔: 48,
    DOT跳数: 7,
    基础伤害_基础值: 12,
    技能增益列表: [...通用增益],
  },
  {
    技能ID: '70041_101593_100043', // 70041_101593_100043
    技能名称: '兰摧·悟(DOT)·玉石',
    统计名称: '兰摧·悟(DOT)',
    技能伤害系数: 231,
    DOT生效间隔: 48,
    DOT跳数: 7,
    基础伤害_基础值: 12,
    技能增益列表: [...通用增益],
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
