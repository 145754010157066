import { 属性类型 } from '@/@types/属性'
import { 阵眼数据类型 } from '../interface'

const 通用阵眼数据: 阵眼数据类型[] = [
  {
    阵眼名称: '无界阵',
    增益集合: [
      { 属性: 属性类型.郭氏额外无双, 值: 102 },
      { 属性: 属性类型.通用增伤, 值: 51 / 1024 },
    ],
    图标: 'https://icon.jx3box.com/icon/22210.png',
  },
  {
    阵眼名称: '万灵阵(配大)',
    增益集合: [
      { 属性: 属性类型.全会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.通用增伤, 值: 62 / 1024 },
      { 属性: 属性类型.郭氏全会心效果等级, 值: 150 * 0.75, 触发型增益: true }, // 第五层阵眼-会心效果15%，覆盖率100
    ],
    覆盖率: 75,
    图标: 'https://icon.jx3box.com/icon/20426.png',
  },
  {
    阵眼名称: '万灵阵(一键宏)',
    增益集合: [
      { 属性: 属性类型.全会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.通用增伤, 值: 62 / 1024 },
      { 属性: 属性类型.郭氏全会心效果等级, 值: 150 * 0.65, 触发型增益: true }, // 第五层阵眼-会心效果15%，覆盖率100
    ],
    覆盖率: 65,
    图标: 'https://icon.jx3box.com/icon/20426.png',
  },
  {
    阵眼名称: '万灵阵(从大)',
    增益集合: [
      { 属性: 属性类型.全会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.通用增伤, 值: 62 / 1024 },
      { 属性: 属性类型.郭氏全会心效果等级, 值: 150 * 0.83, 触发型增益: true }, // 第五层阵眼-会心效果15%，覆盖率100
    ],
    覆盖率: 83,
    图标: 'https://icon.jx3box.com/icon/20426.png',
  },
]

export default 通用阵眼数据
