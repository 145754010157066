import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import LineChart from './折线图'
import { RightCircleOutlined } from '@ant-design/icons'

// 秒
const countMap = {
  '23:00 - 23:59': 0.072936704,
  '22:00 - 22:59': 0.074462226,
  '21:00 - 21:59': 0.07393443,
  '20:00 - 20:59': 0.066573213,
  '19:00 - 19:59': 0.062161272,
  '18:00 - 18:59': 0.049468212,
  '17:00 - 17:59': 0.051481622,
  '16:00 - 16:59': 0.056936185,
  '15:00 - 15:59': 0.055701002,
  '14:00 - 14:59': 0.052677894,
  '13:00 - 13:59': 0.042069082,
  '12:00 - 12:59': 0.035991938,
  '11:00 - 11:59': 0.040591451,
  '10:00 - 10:59': 0.037418685,
  '09:00 - 09:59': 0.029567586,
  '08:00 - 08:59': 0.013721714,
  '07:00 - 07:59': 0.005397693,
  '06:00 - 06:59': 0.006058187,
  '05:00 - 05:59': 0.008066608,
  '04:00 - 04:59': 0.011970707,
  '03:00 - 03:59': 0.018758231,
  '02:00 - 02:59': 0.029718242,
  '01:00 - 01:59': 0.044188251,
  '00:00 - 00:59': 0.060148861,
}

function Step6() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const lineRef = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      lineRef?.current?.initChart()
    }, 200)
  }, [])

  const text1props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 0) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <div className={styles.content}>
        <div className={styles.chartWrap}>
          {/* {action > 0 ? ( */}
          <LineChart ref={lineRef} data={countMap} />
        </div>
        <animated.div style={text1props}>
          <h1 className={styles.title}>
            你常常在哪个时间访问计算器呢？小心摸鱼被老板发现哦！
            <RightCircleOutlined style={{ marginLeft: 12 }} />
          </h1>
        </animated.div>
      </div>
    </div>
  )
}

export default Step6
