import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 三星临增益 from './技能增益/三星临'
import 兵主逆增益 from './技能增益/兵主逆'
import 天斗旋增益 from './技能增益/天斗旋'
import 鬼星开穴增益 from './技能增益/鬼星开穴'
import 亘天增益 from './技能增益/亘天'
import 九字诀山卦通用增益 from './技能增益/通用增益/九字诀山卦通用增益'
import { 属性类型 } from '@/@types/属性'
import 通用外功增益 from './技能增益/通用增益/通用外功'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 非山卦三星临: Partial<技能基础数据模型> = {
  统计名称: '三星临',
  秘籍依赖技能: '三星临',
  技能伤害系数: 275.51700000000005,
  基础伤害_基础值: 88,
  基础伤害_浮动值: 56,
  技能增益列表: 三星临增益,
}

const 非山卦兵主逆: Partial<技能基础数据模型> = {
  统计名称: '兵主逆',
  秘籍依赖技能: '兵主逆',
  技能伤害系数: 294.0,
  基础伤害_基础值: 390,
  基础伤害_浮动值: 188,
  技能增益列表: 兵主逆增益,
  技能等级数据: {
    '1,15': {
      技能伤害系数: (i) => (40 + i * 6) * 1.1,
      基础伤害_基础值: [125, 130, 135, 140, 145, 150, 170, 190, 210, 230, 250, 270, 290, 310],
      基础伤害_浮动值: [10, 15, 17, 19, 21, 23, 25, 28, 52, 57, 65, 67, 71, 75],
    },
    '15,17': {
      技能伤害系数: (i) => (40 + i * 10) * 1.1,
      基础伤害_基础值: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 330, 350, 370],
      基础伤害_浮动值: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 153, 174],
    },
    '19': {
      技能伤害系数: 294.0,
      基础伤害_基础值: 370,
      基础伤害_浮动值: 174,
    },
  },
}

const 非山卦天斗旋: Partial<技能基础数据模型> = {
  统计名称: '天斗旋',
  秘籍依赖技能: '天斗旋',
  技能伤害系数: 942.3480000000003,
  基础伤害_基础值: 620,
  基础伤害_浮动值: 425,
  技能增益列表: 天斗旋增益,
}

const 鬼星开穴: Partial<技能基础数据模型> = {
  统计名称: '鬼星开穴',
  秘籍依赖技能: '鬼星开穴',
  技能伤害系数: 550.7235,
  基础伤害_基础值: 730,
  基础伤害_浮动值: 500,
  基础伤害_倍率: (1.05 * 2) / 2,
  技能增益列表: 鬼星开穴增益,
  技能等级数据: {
    '1,15': {
      技能伤害系数: (i) => (70 + i * 5) * 2 * 0.9 * 0.85 * 1.15,
      基础伤害_基础值: [285, 300, 315, 330, 350, 370, 390, 410, 430, 450, 470, 500, 530, 560],
      基础伤害_浮动值: [35, 45, 55, 70, 85, 100, 115, 135, 155, 175, 205, 235, 265, 295],
    },
    '15,19': {
      技能伤害系数: () => 675.0,
      基础伤害_基础值: { 15: 590, 16: 650, 17: 680, 18: 730 },
      基础伤害_浮动值: { 15: 325, 16: 400, 17: 450, 18: 500 },
    },
    '19,22': {
      技能伤害系数: (i) => 675 * ((i - 18) * 0.15 + 1),
      基础伤害_基础值: { 19: 730 * 1.9, 20: 730 * 1.9, 21: 730 * 1.9 },
      基础伤害_浮动值: { 19: 500 * 1.9, 20: 500 * 1.9, 21: 500 * 1.9 },
    },
  },
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '破', // 32886
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (1.4168 * 1.4 * 0.88 - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      2: {
        技能伤害系数: 获取破招实际系数(1048576 * (1.4168 * 0.3 * 2.3 * 0.4 - 1)),
      },
    },
  },
  {
    技能名称: '魂击', // 25512
    伤害类型: '外功',
    技能伤害系数: 21,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用外功增益,
      {
        增益名称: '普通攻击增伤',
        增益所在位置: '职业',
        增益类型: '全局启用',
        增益启用: true,
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
      },
    ],
  },
  {
    技能名称: '三星临', // 24558
    ...非山卦三星临,
  },
  {
    技能名称: '三星临·水', // 24675
    ...非山卦三星临,
  },
  {
    技能名称: '三星临·火', // 24677
    ...非山卦三星临,
  },
  {
    技能名称: '三星临·山', // 24676
    统计名称: '三星临',
    秘籍依赖技能: '三星临',
    技能伤害系数: 289.29285000000004,
    基础伤害_基础值: 92,
    基础伤害_浮动值: 58,
    技能增益列表: [...三星临增益, ...九字诀山卦通用增益],
  },
  {
    技能名称: '兵主逆', // 24811
    ...非山卦兵主逆,
  },
  {
    技能名称: '兵主逆·水', // 24812
    ...非山卦兵主逆,
  },
  {
    技能名称: '兵主逆·火', // 24814
    ...非山卦兵主逆,
  },
  {
    技能名称: '兵主逆·山', // 24813
    统计名称: '兵主逆',
    秘籍依赖技能: '兵主逆',
    技能伤害系数: 230 * 1.05 * 1.1,
    基础伤害_基础值: 390,
    技能增益列表: [...兵主逆增益, ...九字诀山卦通用增益],
    技能等级数据: {
      '1,19': {
        技能伤害系数: (i) => {
          return [
            53.13, 60.06, 66.99000000000001, 73.92000000000002, 80.85000000000001,
            87.78000000000002, 94.71000000000001, 101.64000000000001, 108.57000000000001,
            115.50000000000001, 122.43000000000002, 129.36, 136.29000000000002, 143.22,
            219.45000000000005, 231.00000000000003, 242.55000000000004, 308.7,
          ]?.[i - 1]
        },
        基础伤害_基础值: [
          10, 15, 17, 19, 22, 24, 26, 29, 54, 59, 68, 70, 74, 78, 82, 160, 182, 197,
        ],
      },
    },
  },
  {
    技能名称: '天斗旋', // 24821
    ...非山卦天斗旋,
  },
  {
    技能名称: '天斗旋·水', // 24822
    ...非山卦天斗旋,
  },
  {
    技能名称: '天斗旋·火', // 24824
    ...非山卦天斗旋,
  },
  {
    技能名称: '天斗旋·山', // 24823
    统计名称: '天斗旋',
    秘籍依赖技能: '天斗旋',
    技能伤害系数: 989.4654000000004,
    基础伤害_基础值: 651,
    基础伤害_浮动值: 446,
    技能增益列表: [...天斗旋增益, ...九字诀山卦通用增益],
  },
  {
    技能名称: '卦象·火离', // 24454
    技能伤害系数: 200,
    基础伤害_基础值: 75,
    基础伤害_浮动值: 43,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1': {
        技能伤害系数: 30,
        基础伤害_基础值: 50,
        基础伤害_浮动值: 28,
      },
      '2,8': {
        技能伤害系数: (i) => 120 + (i - 1) * 6,
        基础伤害_基础值: [0, 52, 55, 59, 60, 66, 68],
        基础伤害_浮动值: [0, 29, 31, 33, 35, 39, 40],
      },
    },
  },
  {
    技能名称: '鬼星开穴', // 24870
    ...鬼星开穴,
  },
  {
    技能名称: '鬼星开穴·龙回首', // 37311
    ...鬼星开穴,
  },
  {
    技能名称: '列宿游', // 33236
    统计名称: '列宿游',
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    基础伤害_倍率: (1.05 * 2) / 2,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,17': {
        技能伤害系数: (i) => (800 + 800 * i * 0.03) * 1.15 * 0.283,
      },
    },
  },
  {
    技能名称: '纵横三才', // 28815
    统计名称: '纵横三才',
    基础伤害_基础值: 30,
    基础伤害_浮动值: 10,
    技能伤害系数: 350.0,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,61': {
        技能伤害系数: (i) => {
          if (+i === 2) {
            return 648.0
          } else if (+i === 3) {
            return 1100.0
          } else {
            return 350.0
          }
        },
        基础伤害_基础值: [
          30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540,
          570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900, 930, 960, 990, 1020, 1050,
          1080, 1110, 1140, 1170, 1200, 1230, 1260, 1290, 1320, 1350, 1380, 1410, 1440, 1470, 1500,
          1530, 1560, 1590, 1620, 1650, 1680, 1710, 1740, 1770, 1800,
        ],
        基础伤害_浮动值: [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
          210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380,
          390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560,
          570, 580, 590, 600,
        ],
      },
    },
  },
  {
    技能名称: '天网', // 30847
    技能伤害系数: 100,
    基础伤害_基础值: 50,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '亘天', // 34683
    秘籍依赖技能: '天斗旋',
    技能伤害系数: 270 * 1.1,
    基础伤害_倍率: (1.05 * 2) / 2,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 亘天增益,
  },
  {
    技能名称: '灵器', // 25174
    技能伤害系数: 200 * 0.9 * 1.2,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '阵星入舆', // 37599
    技能伤害系数: 270 * 1.1,
    基础伤害_倍率: (1.05 * 2) / 2,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '三星临·神兵', // 37599
    技能伤害系数: 50,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '神兵·合灯', // 33588
    技能伤害系数: 700.9200000000001,
    基础伤害_基础值: 120,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  三星临增益,
  兵主逆增益,
  天斗旋增益,
  鬼星开穴增益,
  亘天增益,
}

export { 技能增益 }
