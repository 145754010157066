import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import { 按数字生成数组 } from '@/工具函数/help'

// 易伤
// 70188
export const 无界通用易伤10函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (103 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤15函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (154 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤20函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_20·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (205 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤30函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_30·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (308 * item) / 1024 }],
    } as 技能增益列表类型
  })
}
export const 无界通用易伤35函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_35·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (359 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤40函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_40·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (410 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤50函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_50·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (512 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤60函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_60·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (615 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤80函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_80·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (820 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用易伤100函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用易伤_100·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.易伤增伤, 值: (1024 * item) / 1024 }],
    } as 技能增益列表类型
  })
}
