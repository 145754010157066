import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰坠装备数据: 装备属性信息模型[] = [
  {
    id: 42566,
    图标ID: 4246,
    uid: 42566,
    装备名称: '陶然意·拥狸坠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42565,
    图标ID: 4246,
    uid: 42565,
    装备名称: '陶然意·万仞坠',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42562,
    图标ID: 4246,
    uid: 42562,
    装备名称: '陶然意·徇节坠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42561,
    图标ID: 4246,
    uid: 42561,
    装备名称: '陶然意·临溟坠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42560,
    图标ID: 4246,
    uid: 42560,
    装备名称: '陶然意·上造坠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42558,
    图标ID: 4246,
    uid: 42558,
    装备名称: '陶然意·征惯坠',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42556,
    图标ID: 4246,
    uid: 42556,
    装备名称: '陶然意·春瓮坠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42555,
    图标ID: 4246,
    uid: 42555,
    装备名称: '陶然意·吴陵坠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42554,
    图标ID: 4246,
    uid: 42554,
    装备名称: '陶然意·穿心坠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42550,
    图标ID: 4246,
    uid: 42550,
    装备名称: '陶然意·蜕羽坠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42548,
    图标ID: 4246,
    uid: 42548,
    装备名称: '陶然意·登锋坠',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41420,
    图标ID: 4254,
    uid: 41420,
    装备名称: '澹澄坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1338 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41419,
    图标ID: 4254,
    uid: 41419,
    装备名称: '逋客坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1338 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41364,
    图标ID: 22903,
    uid: 41364,
    装备名称: '连天际',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41363,
    图标ID: 22902,
    uid: 41363,
    装备名称: '雁南归',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41340,
    图标ID: 1216,
    uid: 41340,
    装备名称: '羡雁坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41339,
    图标ID: 1216,
    uid: 41339,
    装备名称: '君深坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41322,
    图标ID: 1214,
    uid: 41322,
    装备名称: '留诗坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41321,
    图标ID: 1214,
    uid: 41321,
    装备名称: '清月坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41304,
    图标ID: 2842,
    uid: 41304,
    装备名称: '漠名坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41303,
    图标ID: 2842,
    uid: 41303,
    装备名称: '漠若坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42838,
    图标ID: 1215,
    uid: 42838,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功会心等级, 值: 4773 },
      { 属性: 属性类型.外功会心效果等级, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 2386 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42837,
    图标ID: 1215,
    uid: 42837,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42836,
    图标ID: 1215,
    uid: 42836,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2940 },
      { 属性: 属性类型.无双等级, 值: 7000 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41518,
    图标ID: 1215,
    uid: 41518,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功会心等级, 值: 4773 },
      { 属性: 属性类型.外功会心效果等级, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 2386 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41517,
    图标ID: 1215,
    uid: 41517,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2507 },
      { 属性: 属性类型.外功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41516,
    图标ID: 1215,
    uid: 41516,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.外功基础攻击, 值: 2940 },
      { 属性: 属性类型.无双等级, 值: 7000 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42826,
    图标ID: 1215,
    uid: 42826,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 2409 },
      { 属性: 属性类型.外功会心等级, 值: 3057 },
      { 属性: 属性类型.外功破防等级, 值: 3057 },
      { 属性: 属性类型.无双等级, 值: 3057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42825,
    图标ID: 1215,
    uid: 42825,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功会心等级, 值: 3363 },
      { 属性: 属性类型.无双等级, 值: 6725 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42824,
    图标ID: 1215,
    uid: 42824,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功破防等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41506,
    图标ID: 1215,
    uid: 41506,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 2409 },
      { 属性: 属性类型.外功会心等级, 值: 3057 },
      { 属性: 属性类型.外功破防等级, 值: 3057 },
      { 属性: 属性类型.无双等级, 值: 3057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41505,
    图标ID: 1215,
    uid: 41505,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功会心等级, 值: 3363 },
      { 属性: 属性类型.无双等级, 值: 6725 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41504,
    图标ID: 1215,
    uid: 41504,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.外功基础攻击, 值: 1994 },
      { 属性: 属性类型.外功破防等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41408,
    图标ID: 4254,
    uid: 41408,
    装备名称: '澹澄坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.身法, 值: 546 },
      { 属性: 属性类型.外功基础攻击, 值: 1241 },
      { 属性: 属性类型.外功会心等级, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41407,
    图标ID: 4254,
    uid: 41407,
    装备名称: '逋客坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.力道, 值: 546 },
      { 属性: 属性类型.外功基础攻击, 值: 1241 },
      { 属性: 属性类型.外功会心等级, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42814,
    图标ID: 1215,
    uid: 42814,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 4398 },
      { 属性: 属性类型.外功会心效果等级, 值: 2199 },
      { 属性: 属性类型.无双等级, 值: 2199 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42813,
    图标ID: 1215,
    uid: 42813,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 2932 },
      { 属性: 属性类型.外功破防等级, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42812,
    图标ID: 1215,
    uid: 42812,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 6744 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41494,
    图标ID: 1215,
    uid: 41494,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 4398 },
      { 属性: 属性类型.外功会心效果等级, 值: 2199 },
      { 属性: 属性类型.无双等级, 值: 2199 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41493,
    图标ID: 1215,
    uid: 41493,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 2311 },
      { 属性: 属性类型.外功会心等级, 值: 2932 },
      { 属性: 属性类型.外功破防等级, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41492,
    图标ID: 1215,
    uid: 41492,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 6744 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42485,
    图标ID: 4246,
    uid: 42485,
    装备名称: '陶然意·拥狸坠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42484,
    图标ID: 4246,
    uid: 42484,
    装备名称: '陶然意·万仞坠',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42481,
    图标ID: 4246,
    uid: 42481,
    装备名称: '陶然意·徇节坠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42480,
    图标ID: 4246,
    uid: 42480,
    装备名称: '陶然意·临溟坠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42479,
    图标ID: 4246,
    uid: 42479,
    装备名称: '陶然意·上造坠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42477,
    图标ID: 4246,
    uid: 42477,
    装备名称: '陶然意·征惯坠',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42475,
    图标ID: 4246,
    uid: 42475,
    装备名称: '陶然意·春瓮坠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42474,
    图标ID: 4246,
    uid: 42474,
    装备名称: '陶然意·吴陵坠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42473,
    图标ID: 4246,
    uid: 42473,
    装备名称: '陶然意·穿心坠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42469,
    图标ID: 4246,
    uid: 42469,
    装备名称: '陶然意·蜕羽坠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42467,
    图标ID: 4246,
    uid: 42467,
    装备名称: '陶然意·登锋坠',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42802,
    图标ID: 1215,
    uid: 42802,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.外功会心效果等级, 值: 2106 },
      { 属性: 属性类型.无双等级, 值: 2106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42801,
    图标ID: 1215,
    uid: 42801,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42800,
    图标ID: 1215,
    uid: 42800,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2594 },
      { 属性: 属性类型.无双等级, 值: 6176 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41482,
    图标ID: 1215,
    uid: 41482,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.外功会心效果等级, 值: 2106 },
      { 属性: 属性类型.无双等级, 值: 2106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41481,
    图标ID: 1215,
    uid: 41481,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2212 },
      { 属性: 属性类型.外功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41480,
    图标ID: 1215,
    uid: 41480,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.外功基础攻击, 值: 2594 },
      { 属性: 属性类型.无双等级, 值: 6176 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41396,
    图标ID: 4254,
    uid: 41396,
    装备名称: '澹澄坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41395,
    图标ID: 4254,
    uid: 41395,
    装备名称: '逋客坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41274,
    图标ID: 1213,
    uid: 41274,
    装备名称: '扬明坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41273,
    图标ID: 1213,
    uid: 41273,
    装备名称: '重悬坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41256,
    图标ID: 1211,
    uid: 41256,
    装备名称: '静言坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41255,
    图标ID: 1211,
    uid: 41255,
    装备名称: '棣仪坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 42790,
    图标ID: 1215,
    uid: 42790,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 2124 },
      { 属性: 属性类型.外功会心等级, 值: 2695 },
      { 属性: 属性类型.外功破防等级, 值: 2695 },
      { 属性: 属性类型.无双等级, 值: 2695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42789,
    图标ID: 1215,
    uid: 42789,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功会心等级, 值: 2965 },
      { 属性: 属性类型.无双等级, 值: 5929 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42788,
    图标ID: 1215,
    uid: 42788,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功破防等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41470,
    图标ID: 1215,
    uid: 41470,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 2124 },
      { 属性: 属性类型.外功会心等级, 值: 2695 },
      { 属性: 属性类型.外功破防等级, 值: 2695 },
      { 属性: 属性类型.无双等级, 值: 2695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41469,
    图标ID: 1215,
    uid: 41469,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功会心等级, 值: 2965 },
      { 属性: 属性类型.无双等级, 值: 5929 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41468,
    图标ID: 1215,
    uid: 41468,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.外功基础攻击, 值: 1758 },
      { 属性: 属性类型.外功破防等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42778,
    图标ID: 1215,
    uid: 42778,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.外功会心效果等级, 值: 1937 },
      { 属性: 属性类型.无双等级, 值: 1937 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42777,
    图标ID: 1215,
    uid: 42777,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 2583 },
      { 属性: 属性类型.外功破防等级, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 42776,
    图标ID: 1215,
    uid: 42776,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 1685 },
      { 属性: 属性类型.外功会心等级, 值: 5940 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41458,
    图标ID: 1215,
    uid: 41458,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.外功会心效果等级, 值: 1937 },
      { 属性: 属性类型.无双等级, 值: 1937 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41457,
    图标ID: 1215,
    uid: 41457,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 2035 },
      { 属性: 属性类型.外功会心等级, 值: 2583 },
      { 属性: 属性类型.外功破防等级, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41456,
    图标ID: 1215,
    uid: 41456,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.外功基础攻击, 值: 1685 },
      { 属性: 属性类型.外功会心等级, 值: 5940 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41172,
    图标ID: 1211,
    uid: 41172,
    装备名称: '语珏坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41171,
    图标ID: 1211,
    uid: 41171,
    装备名称: '语钧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41384,
    图标ID: 4254,
    uid: 41384,
    装备名称: '澹澄坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.身法, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.外功会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41383,
    图标ID: 4254,
    uid: 41383,
    装备名称: '逋客坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.力道, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.外功会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42870,
    图标ID: 2840,
    uid: 42870,
    装备名称: '平皋坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42869,
    图标ID: 2840,
    uid: 42869,
    装备名称: '淮沛坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42854,
    图标ID: 2840,
    uid: 42854,
    装备名称: '羽缀坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 42853,
    图标ID: 2840,
    uid: 42853,
    装备名称: '夏凌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 42742,
    图标ID: 4246,
    uid: 42742,
    装备名称: '灵空·风行坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42741,
    图标ID: 4246,
    uid: 42741,
    装备名称: '灵空·撼地坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42629,
    图标ID: 2840,
    uid: 42629,
    装备名称: '润源坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42628,
    图标ID: 2840,
    uid: 42628,
    装备名称: '辞羁坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41226,
    图标ID: 2840,
    uid: 41226,
    装备名称: '润源坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41225,
    图标ID: 2840,
    uid: 41225,
    装备名称: '辞羁坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41208,
    图标ID: 2840,
    uid: 41208,
    装备名称: '平皋坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41207,
    图标ID: 2840,
    uid: 41207,
    装备名称: '淮沛坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41190,
    图标ID: 2840,
    uid: 41190,
    装备名称: '羽缀坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41189,
    图标ID: 2840,
    uid: 41189,
    装备名称: '夏凌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41100,
    图标ID: 4254,
    uid: 41100,
    装备名称: '镂云佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41099,
    图标ID: 4254,
    uid: 41099,
    装备名称: '炎翳佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41082,
    图标ID: 4254,
    uid: 41082,
    装备名称: '智渊佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41081,
    图标ID: 4254,
    uid: 41081,
    装备名称: '韶曦佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42766,
    图标ID: 1215,
    uid: 42766,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.外功会心效果等级, 值: 1853 },
      { 属性: 属性类型.无双等级, 值: 1853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42765,
    图标ID: 1215,
    uid: 42765,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42764,
    图标ID: 1215,
    uid: 42764,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 2283 },
      { 属性: 属性类型.无双等级, 值: 5435 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41446,
    图标ID: 1215,
    uid: 41446,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功会心等级, 值: 3706 },
      { 属性: 属性类型.外功会心效果等级, 值: 1853 },
      { 属性: 属性类型.无双等级, 值: 1853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41445,
    图标ID: 1215,
    uid: 41445,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 1947 },
      { 属性: 属性类型.外功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41444,
    图标ID: 1215,
    uid: 41444,
    装备名称: '无修坠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.外功基础攻击, 值: 2283 },
      { 属性: 属性类型.无双等级, 值: 5435 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41154,
    图标ID: 1211,
    uid: 41154,
    装备名称: '语珏坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.身法, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.破招值, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41153,
    图标ID: 1211,
    uid: 41153,
    装备名称: '语钧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.力道, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.破招值, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41064,
    图标ID: 4246,
    uid: 41064,
    装备名称: '修篁坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.身法, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.外功会心等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41063,
    图标ID: 4246,
    uid: 41063,
    装备名称: '绮陌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.力道, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.外功会心等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41136,
    图标ID: 1211,
    uid: 41136,
    装备名称: '语珏坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.身法, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.破招值, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41135,
    图标ID: 1211,
    uid: 41135,
    装备名称: '语钧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.力道, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.破招值, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41046,
    图标ID: 1211,
    uid: 41046,
    装备名称: '涵煦坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.身法, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.破招值, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41045,
    图标ID: 1211,
    uid: 41045,
    装备名称: '惜霜坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.力道, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.破招值, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41118,
    图标ID: 1211,
    uid: 41118,
    装备名称: '语珏坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.身法, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.破招值, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41117,
    图标ID: 1211,
    uid: 41117,
    装备名称: '语钧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.力道, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.破招值, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41528,
    图标ID: 4246,
    uid: 41528,
    装备名称: '春齐坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41527,
    图标ID: 4246,
    uid: 41527,
    装备名称: '曲汜坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41028,
    图标ID: 1222,
    uid: 41028,
    装备名称: '嵘嶂坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41027,
    图标ID: 1222,
    uid: 41027,
    装备名称: '湛影坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 腰坠装备数据
