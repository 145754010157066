import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import { 按数字生成数组 } from '@/工具函数/help'

// 增伤
export const 无界通用增伤2函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_2·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (21 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤4函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_4·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (41 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤5函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_5·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (52 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤6函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_6·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (62 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤8函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_8·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (82 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (103 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤15函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_15·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (154 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤20函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_20·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (205 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

export const 无界通用增伤30函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_30·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (308 * item) / 1024 }],
    } as 技能增益列表类型
  })
}
