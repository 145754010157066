import React from 'react'

interface ReportContextProps {
  step: number
  setStep: (e: number) => void
  nextStep: () => void
}

const ReportContext = React.createContext<ReportContextProps>({} as any)

export default ReportContext
