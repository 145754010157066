// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DJMs207fbDijNnIMMF0m {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DJMs207fbDijNnIMMF0m.yYIdQcZ5PJWPudXCmclP {
  cursor: pointer;
}
.CVDYa2NnCN7vLN3E15fW {
  transition: 0.2s;
}
.P432yx5gG0n70avI5Ofp {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 12px;
}
.F5RqgSMdb31jbN2sEgWd {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 400;
}
.nCWjKhKR5UDM3LibNnxr {
  font-size: 48px;
  font-weight: 500;
  margin: 0 12px;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step10_致谢名单_2/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAFF;AAKA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  transition: .2s;\n}\n\n.title {\n  font-size: 34px;\n  font-weight: bold;\n  margin-bottom: 12px;\n}\n\n.subTitle {\n  font-size: 22px;\n  margin-bottom: 24px;\n  font-weight: 400;\n}\n\n.import {\n  font-size: 48px;\n  font-weight: 500;\n  margin: 0 12px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DJMs207fbDijNnIMMF0m`,
	"next": `yYIdQcZ5PJWPudXCmclP`,
	"content": `CVDYa2NnCN7vLN3E15fW`,
	"title": `P432yx5gG0n70avI5Ofp`,
	"subTitle": `F5RqgSMdb31jbN2sEgWd`,
	"import": `nCWjKhKR5UDM3LibNnxr`
};
export default ___CSS_LOADER_EXPORT___;
