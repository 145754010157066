import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰带装备数据: 装备属性信息模型[] = [
  {
    id: 103786,
    图标ID: 22087,
    uid: 103786,
    装备名称: '陶然意·拥狸腰带',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103785,
    图标ID: 22087,
    uid: 103785,
    装备名称: '陶然意·万仞腰带',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103782,
    图标ID: 22087,
    uid: 103782,
    装备名称: '陶然意·徇节腰带',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103781,
    图标ID: 22087,
    uid: 103781,
    装备名称: '陶然意·临溟腰带',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103780,
    图标ID: 22087,
    uid: 103780,
    装备名称: '陶然意·上造腰带',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103778,
    图标ID: 22087,
    uid: 103778,
    装备名称: '陶然意·征惯腰带',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103776,
    图标ID: 22087,
    uid: 103776,
    装备名称: '陶然意·春瓮腰带',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103775,
    图标ID: 22087,
    uid: 103775,
    装备名称: '陶然意·吴陵腰带',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103774,
    图标ID: 22087,
    uid: 103774,
    装备名称: '陶然意·穿心腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103770,
    图标ID: 22087,
    uid: 103770,
    装备名称: '陶然意·蜕羽腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103768,
    图标ID: 22087,
    uid: 103768,
    装备名称: '陶然意·登锋腰带',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 101599,
    图标ID: 23153,
    uid: 101599,
    装备名称: '花永腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101598,
    图标ID: 23153,
    uid: 101598,
    装备名称: '花世腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104334,
    图标ID: 23147,
    uid: 104334,
    装备名称: '含封腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2459 },
      { 属性: 属性类型.破招值, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 6786 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101573,
    图标ID: 23147,
    uid: 101573,
    装备名称: '刻花腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2951 },
      { 属性: 属性类型.外功破防等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101572,
    图标ID: 23147,
    uid: 101572,
    装备名称: '垄青腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2951 },
      { 属性: 属性类型.外功会心等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101571,
    图标ID: 23147,
    uid: 101571,
    装备名称: '烟月腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 3566 },
      { 属性: 属性类型.外功破防等级, 值: 4524 },
      { 属性: 属性类型.破招值, 值: 4524 },
      { 属性: 属性类型.无双等级, 值: 4524 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101570,
    图标ID: 23147,
    uid: 101570,
    装备名称: '醉别腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 4180 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101533,
    图标ID: 23147,
    uid: 101533,
    装备名称: '羡雁腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101532,
    图标ID: 23147,
    uid: 101532,
    装备名称: '君深腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101497,
    图标ID: 23147,
    uid: 101497,
    装备名称: '留诗带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101496,
    图标ID: 23147,
    uid: 101496,
    装备名称: '清月带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101393,
    图标ID: 23262,
    uid: 101393,
    装备名称: '孤漠·阑影腰带',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101392,
    图标ID: 23190,
    uid: 101392,
    装备名称: '孤漠·苍雨护腰',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101388,
    图标ID: 23214,
    uid: 101388,
    装备名称: '孤漠·狂澜腰带',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101387,
    图标ID: 23226,
    uid: 101387,
    装备名称: '孤漠·舟帆腰带',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101386,
    图标ID: 23165,
    uid: 101386,
    装备名称: '孤漠·庭月腰带',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101382,
    图标ID: 23171,
    uid: 101382,
    装备名称: '孤漠·雁际腰带',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101379,
    图标ID: 23208,
    uid: 101379,
    装备名称: '孤漠·泛浪腰带',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101378,
    图标ID: 23177,
    uid: 101378,
    装备名称: '孤漠·寒鸣腰带',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101377,
    图标ID: 23244,
    uid: 101377,
    装备名称: '孤漠·照江腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101371,
    图标ID: 23183,
    uid: 101371,
    装备名称: '孤漠·立霄腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101368,
    图标ID: 23250,
    uid: 101368,
    装备名称: '孤漠·踏沙护腰',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101246,
    图标ID: 9687,
    uid: 101246,
    装备名称: '漠名腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101245,
    图标ID: 9687,
    uid: 101245,
    装备名称: '漠若腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103624,
    图标ID: 22087,
    uid: 103624,
    装备名称: '陶然意·拥狸腰带',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103623,
    图标ID: 22087,
    uid: 103623,
    装备名称: '陶然意·万仞腰带',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103620,
    图标ID: 22087,
    uid: 103620,
    装备名称: '陶然意·徇节腰带',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103619,
    图标ID: 22087,
    uid: 103619,
    装备名称: '陶然意·临溟腰带',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103618,
    图标ID: 22087,
    uid: 103618,
    装备名称: '陶然意·上造腰带',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103616,
    图标ID: 22087,
    uid: 103616,
    装备名称: '陶然意·征惯腰带',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103614,
    图标ID: 22087,
    uid: 103614,
    装备名称: '陶然意·春瓮腰带',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103613,
    图标ID: 22087,
    uid: 103613,
    装备名称: '陶然意·吴陵腰带',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103612,
    图标ID: 22087,
    uid: 103612,
    装备名称: '陶然意·穿心腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103608,
    图标ID: 22087,
    uid: 103608,
    装备名称: '陶然意·蜕羽腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103606,
    图标ID: 22087,
    uid: 103606,
    装备名称: '陶然意·登锋腰带',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103495,
    图标ID: 6552,
    uid: 103495,
    装备名称: '孤山寒月·逐刃腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103494,
    图标ID: 6552,
    uid: 103494,
    装备名称: '孤山寒月·归期腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101204,
    图标ID: 23147,
    uid: 101204,
    装备名称: '扬明腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101203,
    图标ID: 23147,
    uid: 101203,
    装备名称: '重悬腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101168,
    图标ID: 23147,
    uid: 101168,
    装备名称: '静言带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101167,
    图标ID: 23147,
    uid: 101167,
    装备名称: '棣仪带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101064,
    图标ID: 23262,
    uid: 101064,
    装备名称: '西塞·离巢护腰',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101063,
    图标ID: 23190,
    uid: 101063,
    装备名称: '西塞·秋星护腰',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101059,
    图标ID: 23214,
    uid: 101059,
    装备名称: '西塞·麟台护腰',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101058,
    图标ID: 23226,
    uid: 101058,
    装备名称: '西塞·川冥护腰',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101057,
    图标ID: 23165,
    uid: 101057,
    装备名称: '西塞·流电护腰',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101053,
    图标ID: 23171,
    uid: 101053,
    装备名称: '西塞·行歌腰带',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101050,
    图标ID: 23208,
    uid: 101050,
    装备名称: '西塞·平塘护腰',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101049,
    图标ID: 23177,
    uid: 101049,
    装备名称: '西塞·赤眉护腰',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101048,
    图标ID: 23244,
    uid: 101048,
    装备名称: '西塞·江鸥护腰',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101042,
    图标ID: 23183,
    uid: 101042,
    装备名称: '西塞·冷辉腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101039,
    图标ID: 23250,
    uid: 101039,
    装备名称: '西塞·壮志腰带',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100983,
    图标ID: 8891,
    uid: 100983,
    装备名称: '寻踪觅宝·方思腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100982,
    图标ID: 8891,
    uid: 100982,
    装备名称: '寻踪觅宝·汉广腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 104556,
    图标ID: 52,
    uid: 104556,
    装备名称: '梧风御厨腰带·刀功',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104555,
    图标ID: 52,
    uid: 104555,
    装备名称: '岚峰御厨腰带·刀功',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104551,
    图标ID: 52,
    uid: 104551,
    装备名称: '凌晓御厨腰带·刀功 ',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104550,
    图标ID: 52,
    uid: 104550,
    装备名称: '沧波御厨腰带·刀功 ',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104549,
    图标ID: 52,
    uid: 104549,
    装备名称: '傲寒御厨腰带·刀功 ',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104545,
    图标ID: 52,
    uid: 104545,
    装备名称: '塞雪御厨腰带·刀工',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104542,
    图标ID: 52,
    uid: 104542,
    装备名称: '醉梦逍遥腰带·刀功',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104541,
    图标ID: 52,
    uid: 104541,
    装备名称: '至尊御厨腰带·刀功',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104540,
    图标ID: 52,
    uid: 104540,
    装备名称: '蜀月御厨腰带·刀功',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104534,
    图标ID: 52,
    uid: 104534,
    装备名称: '灵虚御厨腰带·刀功',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104531,
    图标ID: 52,
    uid: 104531,
    装备名称: '御赐御厨腰带·刀功',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100953,
    图标ID: 7988,
    uid: 100953,
    装备名称: '寻踪觅宝·迭微腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100952,
    图标ID: 7988,
    uid: 100952,
    装备名称: '寻踪觅宝·予吉腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100791,
    图标ID: 5883,
    uid: 100791,
    装备名称: '语英腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.身法, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.外功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100790,
    图标ID: 5883,
    uid: 100790,
    装备名称: '语友腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.力道, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.外功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104508,
    图标ID: 11600,
    uid: 104508,
    装备名称: '润源腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104507,
    图标ID: 11600,
    uid: 104507,
    装备名称: '辞羁腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104462,
    图标ID: 11600,
    uid: 104462,
    装备名称: '羽缀腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104461,
    图标ID: 11600,
    uid: 104461,
    装备名称: '夏凌腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104120,
    图标ID: 11600,
    uid: 104120,
    装备名称: '平皋腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104119,
    图标ID: 11600,
    uid: 104119,
    装备名称: '淮沛腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100887,
    图标ID: 11600,
    uid: 100887,
    装备名称: '润源腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100886,
    图标ID: 11600,
    uid: 100886,
    装备名称: '辞羁腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100851,
    图标ID: 11600,
    uid: 100851,
    装备名称: '平皋腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100850,
    图标ID: 11600,
    uid: 100850,
    装备名称: '淮沛腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100815,
    图标ID: 11600,
    uid: 100815,
    装备名称: '羽缀腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100814,
    图标ID: 11600,
    uid: 100814,
    装备名称: '夏凌腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100683,
    图标ID: 11043,
    uid: 100683,
    装备名称: '镂云带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100682,
    图标ID: 11043,
    uid: 100682,
    装备名称: '炎翳带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100647,
    图标ID: 11043,
    uid: 100647,
    装备名称: '智渊带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100646,
    图标ID: 11043,
    uid: 100646,
    装备名称: '韶曦带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100767,
    图标ID: 5883,
    uid: 100767,
    装备名称: '语英腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.身法, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.外功破防等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100766,
    图标ID: 5883,
    uid: 100766,
    装备名称: '语友腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.力道, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.外功破防等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100611,
    图标ID: 5883,
    uid: 100611,
    装备名称: '修篁带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.身法, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.外功破防等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100610,
    图标ID: 5883,
    uid: 100610,
    装备名称: '绮陌带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.力道, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.外功破防等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100743,
    图标ID: 5883,
    uid: 100743,
    装备名称: '语英腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.身法, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.外功破防等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100742,
    图标ID: 5883,
    uid: 100742,
    装备名称: '语友腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.力道, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.外功破防等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100575,
    图标ID: 6826,
    uid: 100575,
    装备名称: '涵煦护腰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100574,
    图标ID: 6826,
    uid: 100574,
    装备名称: '惜霜护腰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100719,
    图标ID: 5883,
    uid: 100719,
    装备名称: '语英腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.身法, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.外功破防等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100718,
    图标ID: 5883,
    uid: 100718,
    装备名称: '语友腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.力道, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.外功破防等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101839,
    图标ID: 5883,
    uid: 101839,
    装备名称: '春齐带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101838,
    图标ID: 5883,
    uid: 101838,
    装备名称: '曲汜带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100539,
    图标ID: 17935,
    uid: 100539,
    装备名称: '嵘嶂腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100538,
    图标ID: 17935,
    uid: 100538,
    装备名称: '湛影腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
]

export default 腰带装备数据
