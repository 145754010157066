import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 雪絮金屏增益 from './通用增益/雪絮金屏增益'
import { 按数字生成数组 } from '@/工具函数/help'
import 门派套装增益 from './通用增益/门派套装增益'
import 小橙武技能增益 from './通用增益/小橙武技能增益'
import 大橙武技能增益 from './通用增益/大橙武技能增益'

// 11221
const 化蛟函数 = () => {
  const 数组 = 按数字生成数组(7)
  return 数组.map((item) => {
    return {
      增益名称: `化蛟·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '化蛟',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (256 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 醉斩白蛇增益: 技能增益列表类型[] = [
  ...通用增益,
  ...雪絮金屏增益,
  ...化蛟函数(),
  ...门派套装增益,
  ...小橙武技能增益,
  ...大橙武技能增益,
  {
    // 19499
    增益名称: '砺锋',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '3%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 31 / 1024 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.03 }],
  },
  {
    增益名称: '2%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.02 }],
  },
]

export default 醉斩白蛇增益
