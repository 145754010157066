import 山海心诀 from './山海心诀'
import 孤锋诀 from './孤锋诀'
import 凌海诀 from './凌海诀'
import 太玄经 from './太玄经'
import 无方 from './无方'
import 花间游 from './花间游'
import 毒经 from './毒经'
import 易筋经 from './易筋经'
import 分山劲 from './分山劲'
import 北傲诀 from './北傲诀'
import 焚影圣诀 from './焚影'
import 紫霞功 from './紫霞功'
import 傲血战意 from './傲血战意'
import 周天功 from './周天功'

import 山海心诀_悟 from './山海心诀_悟'
import 孤锋诀_悟 from './孤锋诀_悟'
import 无方_悟 from './无方_悟'
import 凌海诀_悟 from './凌海诀_悟'
import 太玄经_悟 from './太玄经_悟'
import 花间游_悟 from './花间游_悟'
import 周天功_悟 from './周天功_悟'

const 心法 = {
  山海心诀,
  孤锋诀,
  凌海诀,
  太玄经,
  无方,
  花间游,
  毒经,
  易筋经,
  分山劲,
  北傲诀,
  焚影圣诀,
  紫霞功,
  傲血战意,
  周天功,
  山海心诀_悟,
  孤锋诀_悟,
  凌海诀_悟,
  太玄经_悟,
  无方_悟,
  花间游_悟,
  周天功_悟,
}
export default 心法

export type 全部心法类型 = typeof 心法
