export function formatSeconds(seconds) {
  // 计算分钟数
  const minutes = Math.floor(seconds / 60)
  // 计算剩余的秒数
  const remainingSeconds = seconds % 60
  // 使用padStart方法确保分钟和秒数都是两位数
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')
  // 返回格式化的字符串
  return `${formattedMinutes}:${formattedSeconds}`
}
