// src/TextReveal.js
import React from 'react'
import { useSprings, animated } from '@react-spring/web'

interface TextRevealProps {
  list: React.ReactNode[]
  callback?: () => void
}

const ComponentReveal: React.FC<TextRevealProps> = (props) => {
  const { list, callback } = props
  // 为每个字符创建一个 spring 动画
  const springs = useSprings(
    list.length,
    list.map((_, i) => ({
      from: { opacity: 0, transform: 'translateY(20px)' },
      to: { opacity: 1, transform: 'translateY(0px)' },
      delay: i * 50, // 每个字母的动画延迟
      onRest: () => {
        if (i === list.length - 1) {
          callback?.()
        }
      },
    }))
  )

  return (
    <>
      {springs.map((props, i) => (
        <animated.span style={props as any} key={i}>
          {list[i]}
        </animated.span>
      ))}
    </>
  )
}

export default ComponentReveal
