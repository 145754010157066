import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 六合独尊增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    // 9966_1
    增益名称: '同尘·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 359 / 1024 }],
  },
  {
    // 9966_2
    增益名称: '同尘·2',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 717 / 1024 }],
  },
  {
    // 9966_3
    增益名称: '同尘·3',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 1076 / 1024 }],
  },
  {
    // 9966_4
    增益名称: '同尘·4',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 1434 / 1024 }],
  },
]

export default 六合独尊增益
