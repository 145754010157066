import React, { useContext, useEffect, useState } from 'react'
import ReportContext from '../../context'
import { useSpring, animated } from '@react-spring/web'
import styles from './index.module.less'

function Step11() {
  const [action, setAction] = useState<number>(0)
  const { nextStep } = useContext(ReportContext)

  useEffect(() => {
    setTimeout(() => {
      setAction(1)
    }, 1000)
  }, [])

  const toNextStep = () => {
    if (action > 0) {
      nextStep()
    }
  }

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-30px)' }, // 初始时位置在左侧，完全透明
    to: { opacity: 1, transform: 'translateY(0px)' }, // 最后到达正常位置，完全不透明
    config: { duration: 1000 }, // 动画的持续时间，可以根据需要调整
    onRest: () => setAction(1),
  })

  const list = ['考拉', '蓝团', '可乐']

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <animated.div style={animationProps}>
        <div>
          <h1 className={styles.title}>特别鸣谢</h1>
          <div className={styles.list}>
            {list.map((item) => {
              return (
                <div className={styles.item} key={item}>
                  <img
                    className={styles.img}
                    src={require(`../../静态资源/致谢名单/${item}.jpg`)}
                  />
                  <span>{item}</span>
                </div>
              )
            })}
          </div>
        </div>
      </animated.div>
    </div>
  )
}

export default Step11
