import React, { useContext, useState } from 'react'
import { 心法 } from '@/心法模块/index'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import { RightCircleOutlined } from '@ant-design/icons'

function Step2() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const toNextStep = () => {
    if (action > 1) {
      nextStep()
    }
  }

  const list = [
    {
      time: '5月16日',
      心法列表: ['孤锋诀', '山海心诀', '凌海诀'],
    },
    {
      time: '5月24日',
      心法列表: ['太玄经'],
    },
    {
      time: '5月28日',
      心法列表: ['无方'],
    },
    {
      time: '6月18日',
      心法列表: ['花间游'],
    },
    {
      time: '6月27日',
      心法列表: ['孤锋诀·悟', '山海心诀·悟'],
    },
    {
      time: '7月12日',
      心法列表: ['无方·悟'],
    },
    {
      time: '8月27日',
      心法列表: ['周天功'],
    },
    {
      time: '9月27日',
      心法列表: ['毒经', '易筋经'],
    },
    {
      time: '10月24日',
      心法列表: ['北傲诀'],
    },
    {
      time: '11月03日',
      心法列表: ['分山劲'],
    },
    {
      time: '11月12日',
      心法列表: ['太玄经·悟', '凌海诀·悟'],
    },
    {
      time: '12月02日',
      心法列表: ['花间游·悟'],
    },
    {
      time: '01月01日',
      心法列表: ['周天功·悟'],
    },
  ]

  const next = () => {
    setAction(action + 1)
  }

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateX(-100px)' }, // 初始时位置在左侧，完全透明
    to: { opacity: 1, transform: 'translateX(0px)' }, // 最后到达正常位置，完全不透明
    config: { duration: 1000 }, // 动画的持续时间，可以根据需要调整
    onRest: () => next(),
  })

  const text2props = useSpring({
    opacity: action > 0 ? 1 : 0,
    delay: 1000,
    config: { duration: 500 },
    onRest: () => next(),
  })

  return (
    <div className={`${styles.wrap} ${action > 1 ? styles.next : ''}`} onClick={toNextStep}>
      <div style={{ marginTop: -100 }}>
        <animated.div style={animationProps} className={styles.fadeIn}>
          <div className={styles.timeLineWrap}>
            <div className={styles.timeLine} />
            <div className={styles.timeWrap}>
              {list.map((item, index) => {
                return (
                  <div
                    className={`${styles.timePoint} ${styles[`point${index + 1}`]}`}
                    key={`timelintPint_${index}`}
                  >
                    <div className={styles.point} />
                    <div className={styles.content}>
                      <h1 className={styles.xinfaTime}>{item?.time}</h1>
                      <div className={styles.xinfaList}>
                        {item?.心法列表?.map((item) => {
                          const trueName = item?.replace('·', '_')
                          const icon = 心法?.[trueName]?.系统配置?.心法图标
                          return (
                            <div className={styles.xinfa} key={`xinfa_${item}`}>
                              {icon ? <img className={styles.img} src={icon} /> : null}
                              <span className={styles.name}>{item}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </animated.div>
        <animated.div style={text2props}>
          <h1 className={styles.timeLineRes}>
            你猜对了吗？
            <RightCircleOutlined />
          </h1>
        </animated.div>
      </div>
    </div>
  )
}

export default Step2
