import React, { useContext, useEffect, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import styles from './index.module.less'
import TextReveal from '../../通用组件/文字渐显'
import ReportContext from '../../context'

function Step8() {
  const [action, setAction] = useState<number>(0)
  const { nextStep } = useContext(ReportContext)

  useEffect(() => {
    if (action < 1) {
      next()
    }
  }, [])

  useEffect(() => {
    if (action === 2) {
      setTimeout(() => {
        next()
      }, 5000)
    }
  }, [action])

  const text1props = useSpring({
    opacity: action > 0 ? 1 : 0,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text2props = useSpring({
    opacity: action > 1 ? 1 : 0,
    delay: 100,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 2) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 2 ? styles.next : ''}`} onClick={toNextStep}>
      <div className={styles.content}>
        {/* {action > 0 ? ( */}
        <animated.div style={text1props}>
          <h1 className={styles.subTitle}>
            <TextReveal text={'最后请允许我在此致谢所有帮助在线配装计算器成长的人'} />
          </h1>
        </animated.div>
        <animated.div style={text2props}>
          <h1 className={styles.subTitle}>
            <TextReveal text={'排名不分先后，名单较长，如有遗漏，敬请谅解'} />
          </h1>
        </animated.div>
      </div>
    </div>
  )
}

export default Step8
