// src/TextReveal.js
import React from 'react'
import { useSprings, animated } from '@react-spring/web'

interface TextRevealProps {
  text: string
  callback?: () => void
}

const TextReveal: React.FC<TextRevealProps> = (props) => {
  const { text, callback } = props
  // 将文字分成字符数组
  const chars = text.split('')

  // 为每个字符创建一个 spring 动画
  const springs = useSprings(
    chars.length,
    chars.map((_, i) => ({
      from: { opacity: 0, transform: 'translateY(20px)' },
      to: { opacity: 1, transform: 'translateY(0px)' },
      delay: i * 50, // 每个字母的动画延迟
      onRest: () => {
        if (i === chars.length - 1) {
          callback?.()
        }
      },
    }))
  )

  return (
    <div style={{ display: 'inline-block' }}>
      {springs.map((props, i) => (
        <animated.span style={props as any} key={i}>
          {chars[i]}
        </animated.span>
      ))}
    </div>
  )
}

export default TextReveal
