import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

import 无界通用增益 from '@/心法模块/统一数据/无界增益'
const {
  // 无界通用增伤5函数,
  // 无界通用增伤10函数,
  // 无界通用增伤15函数,
  // 无界通用增伤20函数,
  // 无界通用增伤30函数,
  // 无界通用内功被会心15函数,
  无界通用内功被会心20函数,
  无界通用内功被会心40函数,
  无界通用内功被会心60函数,
  无界通用内功被会心80函数,
  无界通用内功被会心100函数,
  // 无界通用无双10函数,
  无界通用易伤20函数,
  // 无界通用易伤30函数,
  // 无界通用易伤35函数,
  无界通用易伤40函数,
  无界通用易伤60函数,
  无界通用易伤80函数,
  无界通用易伤100函数,
  // 无界通用内功会效15函数,
} = 无界通用增益

const 通脉函数 = () => {
  const 数组 = 按数字生成数组(3)
  const 增伤数组 = [52, 103, 205]
  return 数组.map((item) => {
    return {
      增益名称: `通脉·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 增伤数组[item - 1] / 1024 }],
    } as 技能增益列表类型
  })
}

const 断脉秘籍函数 = () => {
  const 数组 = 按数字生成数组(3)
  const 增伤数组 = [52, 103, 154]
  return 数组.map((item) => {
    return {
      增益名称: `断脉秘籍·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 增伤数组[item - 1] / 1024 }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('内功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 471 / 1024 }],
  },
  {
    增益名称: '破招转非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.破招转非侠, 值: 8000 }],
  },
  {
    增益名称: '无视防御',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 614 }],
  },
  // 套装双会特效
  {
    // 29597
    增益名称: '风泽',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 41 },
    ],
  },
  {
    增益名称: '逆脉·悟',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    是否常驻面板: true,
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.15 }],
  },
  {
    增益名称: '截阳无双',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 103 }],
  },
  {
    增益名称: '截阳伤害',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 103 / 1024 }],
  },
  {
    增益名称: '读条增伤',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    增益名称: '大招增伤',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    增益名称: '引窍会效',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功会心效果等级, 值: 150 }],
  },


  // ...无界通用增伤5函数(),
  // ...无界通用增伤10函数(),
  // ...无界通用增伤15函数(),
  // ...无界通用增伤20函数(),
  // ...无界通用增伤30函数(),
  // ...无界通用内功被会心15函数(),
  ...无界通用内功被会心20函数(),
  ...无界通用内功被会心40函数(),
  ...无界通用内功被会心60函数(),
  ...无界通用内功被会心80函数(),
  ...无界通用内功被会心100函数(),
  // ...无界通用无双10函数(),
  ...无界通用易伤20函数(),
  // ...无界通用易伤30函数(),
  // ...无界通用易伤35函数(),
  ...无界通用易伤40函数(),
  ...无界通用易伤60函数(),
  ...无界通用易伤80函数(),
  ...无界通用易伤100函数(),
  // ...无界通用内功会效15函数(),

  // 模拟器新增增益
  ...通脉函数(),
  ...断脉秘籍函数(),
]

export default 通用增益
