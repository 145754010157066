import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import { 按数字生成数组 } from '@/工具函数/help'
import 大橙武技能增益 from './通用增益/大橙武技能增益'
import 小橙武技能增益 from './通用增益/小橙武技能增益'

// 12550
const 跬步四象函数 = () => {
  const 数组 = 按数字生成数组(5)
  const 增伤数组 = [41, 82, 123, 164, 205]
  return 数组.map((item) => {
    return {
      增益名称: `跬步四象·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '跬步',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 增伤数组[item - 1] / 1024 }],
    } as 技能增益列表类型
  })
}

const 四象轮回增益: 技能增益列表类型[] = [
  ...通用增益,
  ...大橙武技能增益,
  ...小橙武技能增益,
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.03 }],
  },
  {
    增益名称: '2%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.02 }],
  },
  {
    // 5827
    增益名称: '白虹',
    依赖奇穴: '白虹',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
    ],
  },
  {
    // 雾锁
    增益名称: '雾锁',
    依赖奇穴: '雾锁',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  ...跬步四象函数(),
]

export default 四象轮回增益
