import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

// 7671
const 牧云函数 = () => {
  const 数组 = 按数字生成数组(4)
  return 数组.map((item) => {
    return {
      增益名称: `牧云·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '牧云',
      增益类型: '部分启用',
      增益集合: [
        { 属性: 属性类型.外功会心百分比, 值: 0.03 * item },
        { 属性: 属性类型.郭氏外功会心效果等级, 值: 102 * item },
      ],
    } as 技能增益列表类型
  })
}

// 21638
const 龙驭函数 = () => {
  const 数组 = 按数字生成数组(3)
  return 数组.map((item) => {
    return {
      增益名称: `龙驭·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '龙驭',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (72 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('外功'),
  {
    增益名称: '撼如雷·天策',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.郭氏外功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏最终气血上限, 值: 51 },
    ],
  },
  {
    增益名称: '破风·天策',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功基础防御, 值: -3500 }],
  },
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 471 / 1024 }],
  },
  // 套装双会特效
  {
    // 1428
    增益名称: '军啸',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 41 },
    ],
  },
  {
    增益名称: '驰骋·1', // 6121_1
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 154 }],
  },
  {
    增益名称: '驰骋·2', // 6121_2
    增益所在位置: '奇穴',
    依赖奇穴: '驰骋',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 307 }],
  },
  {
    增益名称: '激雷', // 6363_3
    增益所在位置: '奇穴',
    依赖奇穴: '激雷',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.郭氏外功基础攻击, 值: 205 },
      { 属性: 属性类型.郭氏外功破防等级, 值: 205 },
      { 属性: 属性类型.外功会心百分比, 值: 0.3 },
    ],
  },
  {
    // 增益名称: '雷', // 6363_2
    增益名称: '夜征_快照', // 6363_2
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.3 }],
  },
  {
    增益名称: '夜征_常驻', // 6363_4
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 717 }],
  },
  {
    增益名称: '夜征', // 6363_4
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.3 },
      { 属性: 属性类型.郭氏全无视防御, 值: 717 },
    ],
  },
  {
    增益名称: '百折', // 14981
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  {
    增益名称: '渊', // 2779
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 358 }],
  },
  ...牧云函数(),
  ...龙驭函数(),
]

export default 通用增益
