// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OFn0_b3R0TJtBC8MZs6p {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.OFn0_b3R0TJtBC8MZs6p.dnPNUHEee5roLlUTmwfQ {
  cursor: pointer;
}
.fGqeP5QcfO7PGj7Ieyrt {
  transition: 0.2s;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step9_致谢名单_1/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;AADF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  transition: .2s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `OFn0_b3R0TJtBC8MZs6p`,
	"next": `dnPNUHEee5roLlUTmwfQ`,
	"content": `fGqeP5QcfO7PGj7Ieyrt`
};
export default ___CSS_LOADER_EXPORT___;
