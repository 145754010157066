import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('内功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 430 / 1024 }],
  },
  {
    // 1908
    增益名称: '会神',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功会心效果等级, 值: 200 }],
  },
  {
    // 2757
    增益名称: '紫气东来',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.25 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 256 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 512 },
    ],
  },
  {
    // 375_14 14级破苍穹
    增益名称: '破苍穹',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 204 },
      { 属性: 属性类型.郭氏全无视防御, 值: 615 },
    ],
  },
  // 套装双会特效
  {
    // 1439
    增益名称: '气涌',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 41 },
    ],
  },
]

export default 通用增益
