import { 属性类型 } from '@/@types/属性'
import { 阵眼数据类型 } from '../interface'
import 通用阵眼数据 from '../通用'

const 内功阵眼数据: 阵眼数据类型[] = [
  {
    阵眼名称: '段氏阵(清激)',
    过滤心法: '周天功',
    增益集合: [
      { 属性: 属性类型.破招值, 值: 2666 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.全会心百分比, 值: 0.05 },
      { 属性: 属性类型.通用增伤, 值: (62 / 1024) * 0.949, 触发型增益: true },
    ],
    覆盖率: 94.9,
    说明: '由于4阵计算问题，周天功心法"模拟"循环请勿选择词阵',
    图标: 'https://icon.jx3box.com/icon/23441.png',
  },
  {
    阵眼名称: '段氏阵(封府)',
    过滤心法: '周天功',
    增益集合: [
      { 属性: 属性类型.破招值, 值: 2666 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.全会心百分比, 值: 0.05 },
      { 属性: 属性类型.通用增伤, 值: (62 / 1024) * 0.928, 触发型增益: true },
    ],
    覆盖率: 92.8,
    说明: '由于4阵计算问题，周天功心法"模拟"循环请勿选择词阵',
    图标: 'https://icon.jx3box.com/icon/23441.png',
  },
  {
    阵眼名称: '段氏阵(快照)',
    所属心法: '周天功',
    增益集合: [
      { 属性: 属性类型.破招值, 值: 2666 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.全会心百分比, 值: 0.05 },
    ],
    说明: '4阵按模拟器的快照计算，循环内无对应buff不会触发对应增伤',
    图标: 'https://icon.jx3box.com/icon/23441.png',
  },
  {
    阵眼名称: '冰心阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 50 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 51 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 50, 触发型增益: true },
    ],
    图标: 'https://icon.jx3box.com/icon/1800.png',
  },
  {
    阵眼名称: '花间阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏内功破防等级, 值: 300 },
    ],
    图标: 'https://icon.jx3box.com/icon/1806.png',
  },
  {
    阵眼名称: '莫问阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 102 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.内功会心百分比, 值: 0.05, 触发型增益: true },
    ],
    图标: 'https://icon.jx3box.com/icon/7047.png',
  },
  {
    阵眼名称: '莫问阵(阵眼)',
    所属心法: '莫问',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 102 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.内功会心百分比, 值: 0.05, 触发型增益: true },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 205 },
    ],
    图标: 'https://icon.jx3box.com/icon/7047.png',
  },
  {
    阵眼名称: '易筋阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.郭氏内功破防等级, 值: 102 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 21 * 5, 触发型增益: true }, // 阵眼施展“守缺式”，则小队成员内功基础攻击力提高2%，最多叠加5层
    ],
    图标: 'https://icon.jx3box.com/icon/1802.png',
  },
  {
    阵眼名称: '气纯阵',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 154 },
      { 属性: 属性类型.内功会心百分比, 值: 0.01 * 5, 触发型增益: true }, // 阵眼施展续气招式，则小队内功会心几率提高1%，最多叠加五层。
    ],
    图标: 'https://icon.jx3box.com/icon/1797.png',
  },
  {
    阵眼名称: '毒经阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
      { 属性: 属性类型.郭氏内功破防等级, 值: 102 * 0.5, 触发型增益: true }, // 阵眼施展“蝎心”，则小队成员内功破防提高10%，持续5秒。
    ],
    覆盖率: 50,
    图标: 'https://icon.jx3box.com/icon/2714.png',
  },
  {
    阵眼名称: '毒经阵(阵眼)',
    所属心法: '毒经',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51, 触发型增益: true }, // 小队成员内功招式会心则使阵眼毒性内功基础攻击力提高5%，持续5秒
      { 属性: 属性类型.郭氏内功破防等级, 值: 102 * 0.5, 触发型增益: true }, // 阵眼施展“蝎心”，则小队成员内功破防提高10%，持续5秒。
    ],
    覆盖率: 50,
    图标: 'https://icon.jx3box.com/icon/2714.png',
  },
  {
    阵眼名称: '天罗阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏全无视防御, 值: 52 },
      { 属性: 属性类型.郭氏全会心效果等级, 值: 150 },
      { 属性: 属性类型.全会心百分比, 值: 0.05, 触发型增益: true }, // 第五层阵眼-1覆盖率按:100%算
    ],
    图标: 'https://icon.jx3box.com/icon/3152.png',
  },
  {
    阵眼名称: '焚影阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.内功会心百分比, 值: (0.1 * (5 / 7) * 6) / 25, 触发型增益: true }, // 阵眼施展“驱夜断愁”则团队成员内功会心几率提高10%，持续5秒。
    ],
    覆盖率: (((5 / 7) * 6) / 25) * 100,
    图标: 'https://icon.jx3box.com/icon/3878.png',
    // 全团6个人随机吃，大概7秒钟打一个，打一次5Buff持续5秒
  },
  {
    阵眼名称: '焚影阵(阵眼)',
    所属心法: '焚影圣诀',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.内功会心百分比, 值: 0.03 },
      { 属性: 属性类型.内功会心百分比, 值: (0.1 * (5 / 7) * 6) / 25, 触发型增益: true }, // 阵眼施展“驱夜断愁”则团队成员内功会心几率提高10%，持续5秒。
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 200, 触发型增益: true }, // 造成会心伤害则使阵眼内功会心效果提高20%，持续5秒。
    ],
    覆盖率: (((5 / 7) * 6) / 25) * 100,
    图标: 'https://icon.jx3box.com/icon/3878.png',
  },
  {
    阵眼名称: '衍天阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
      // 本赛季 鬼列 不起卦
      // { 属性: 属性类型.通用增伤, 值: (154 / 2) * 0.2, 触发型增益: true }, // 覆盖率按 20%算 阵眼施展“起卦”则有50%几率使小队成员招式伤害和治疗效果提高15%，有50%几率使小队成员受到的伤害降低25%，持续4秒。
    ],
    图标: 'https://icon.jx3box.com/icon/14159.png',
  },
  {
    阵眼名称: '衍天阵(阵眼)',
    所属心法: '太玄经',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏额外无双, 值: 20 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51, 触发型增益: true }, // 取平均数按5%算 小队成员内功会心则使阵眼混元性内功基础攻击随机提高1~10%，持续5秒。
      // 本赛季 鬼列 不起卦
      // { 属性: 属性类型.通用增伤, 值: (154 / 2) * 0.2, 触发型增益: true }, // 覆盖率按 20%算 阵眼施展“起卦”则有50%几率使小队成员招式伤害和治疗效果提高15%，有50%几率使小队成员受到的伤害降低25%，持续4秒。
    ],
    图标: 'https://icon.jx3box.com/icon/14159.png',
  },
  {
    阵眼名称: '无方阵',
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 51 },
      { 属性: 属性类型.通用增伤, 值: 31 / 1024 },
      { 属性: 属性类型.全会心百分比, 值: 0.03 },
      // 五阵暂时不计算 阵眼产生“中和”效果，使得小队成员下一次伤害招式将附带一段毒性内功伤害，该伤害取决于阵眼的内功攻击、破防等级、会心几率和会心效果。
    ],
    图标: 'https://icon.jx3box.com/icon/15642.png',
  },
  ...通用阵眼数据,
]

export default 内功阵眼数据
