import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 亘天 from './亘天.json'
import 祝祷 from './祝祷.json'
import 度冥 from './度冥.json'
import 橙武 from './橙武.json'
import 往日荣光天斗旋橙武 from './往日荣光天斗旋橙武.json'
// import 测试循环 from './测试循环.json'
// import 橙武加速测试 from './橙武加速测试.json'

const 计算循环: 循环数据[] = [
  // 测试循环,
  // 亘天,
  祝祷,
  度冥,
  橙武,
  往日荣光天斗旋橙武,
] as 循环数据[]

export default 计算循环
