import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// import 测试 from './测试.json'
import 默认 from './默认.json'
import 归酣 from './归酣.json'
import 碎影 from './碎影.json'

const 计算循环: 循环数据[] = [默认, 归酣, 碎影] as 循环数据[]

export default 计算循环
