import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import { 按数字生成数组 } from '@/工具函数/help'

// 无双
export const 无界通用无双8函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70167
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用无双_8·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 82 * item }],
    } as 技能增益列表类型
  })
}

// 无双
export const 无界通用无双10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70167
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用无双_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 103 * item }],
    } as 技能增益列表类型
  })
}
