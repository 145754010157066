// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tz72PHUrOUjeNPXC5e8K {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tz72PHUrOUjeNPXC5e8K.cqITuRKhtkJJu4_b7ui3 {
  cursor: pointer;
}
.SMpwrvmArjDbQEKuq6_B {
  transition: 0.2s;
}
.il1o6pn7Fsv8Jl8ZotN8 {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
  margin-right: 32px;
}
.S5pJqRzZO1TJuFStm5hr {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 400;
}
.lJ8sypoIKuZbcq6Q36v2 {
  font-size: 48px;
  font-weight: 500;
  margin: 0 12px;
}
.hdj7IRD9Bx0F1jA6ZBAb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.usLD76H4cri_fJdqy_Eo {
  display: flex;
  align-items: center;
  margin: 16px;
  font-size: 24px;
}
.BW9OVplMP5sz9qD_nVee {
  width: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step11_致谢名单_3/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAFF;AAKA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AALF;AAQA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AANF;AASA;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;AAPF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  transition: .2s;\n}\n\n.title {\n  font-size: 34px;\n  font-weight: bold;\n  margin-bottom: 12px;\n  text-align: center;\n  margin-right: 32px;\n}\n\n.subTitle {\n  font-size: 22px;\n  margin-bottom: 24px;\n  font-weight: 400;\n}\n\n.import {\n  font-size: 48px;\n  font-weight: 500;\n  margin: 0 12px\n}\n\n.list {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  margin: 16px;\n  font-size: 24px;;\n}\n\n.img {\n  width: 48px;\n  border-radius: 50%;\n  margin-right: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `tz72PHUrOUjeNPXC5e8K`,
	"next": `cqITuRKhtkJJu4_b7ui3`,
	"content": `SMpwrvmArjDbQEKuq6_B`,
	"title": `il1o6pn7Fsv8Jl8ZotN8`,
	"subTitle": `S5pJqRzZO1TJuFStm5hr`,
	"import": `lJ8sypoIKuZbcq6Q36v2`,
	"list": `hdj7IRD9Bx0F1jA6ZBAb`,
	"item": `usLD76H4cri_fJdqy_Eo`,
	"img": `BW9OVplMP5sz9qD_nVee`
};
export default ___CSS_LOADER_EXPORT___;
