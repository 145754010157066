// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vBrqbT8ccVJ5odcl3NA6 {
  position: absolute;
  left: 40px;
  top: 363px;
  width: 520px;
}
.CunqJgR_60nmQDudeTi6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 6px 0 24px 220px;
}
.lsf5RUVBLOlu1ItGUzB8 {
  display: flex;
  align-items: center;
}
.HTy0KbeEkkEtLycrUqJl {
  margin: 0 4px;
}
.kmP7gnjaxnkSSh7GUJ2i {
  width: 32px;
  height: 32px;
  display: block;
  margin-bottom: 4px;
  border-radius: 4px;
}
.yoa7cu3YqFGE4MY1hJll {
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/奇穴区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,YAAA;AACF;AAEA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AAHF;AAMA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;AAJF","sourcesContent":[".qixueWrap {\n  position: absolute;\n  left: 40px;\n  top: 363px;\n  width: 520px;\n}\n\n.qixueTitle {\n  font-size: 16px;\n  color: #fff;\n  font-weight: 500;\n  padding: 6px 0 24px 220px;\n}\n\n.qixueContent {\n  display: flex;\n  align-items: center;\n}\n\n.item {\n  margin: 0 4px;\n}\n\n.img {\n  width: 32px;\n  height: 32px;\n  display: block;\n  margin-bottom: 4px;\n  border-radius: 4px;\n}\n\n.title {\n  text-align: center;\n  color: #fff;\n  font-size: 12px;\n  line-height: 12px;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qixueWrap": `vBrqbT8ccVJ5odcl3NA6`,
	"qixueTitle": `CunqJgR_60nmQDudeTi6`,
	"qixueContent": `lsf5RUVBLOlu1ItGUzB8`,
	"item": `HTy0KbeEkkEtLycrUqJl`,
	"img": `kmP7gnjaxnkSSh7GUJ2i`,
	"title": `yoa7cu3YqFGE4MY1hJll`
};
export default ___CSS_LOADER_EXPORT___;
