import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 钩吻断肠增益 from './技能增益/钩吻断肠增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: 102159,
    技能名称: '且待时休·悟',
    技能伤害系数: 571,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102157,
    技能名称: '川乌射罔·悟',
    技能伤害系数: 265,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102158,
    技能名称: '沾衣未妨·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 166,
    技能破招系数: 532 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102164,
    技能名称: '并蒂夺株·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 187,
    技能破招系数: 594 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 101417,
    技能名称: '无方中和',
    基础伤害_基础值: 1,
    技能伤害系数: 231,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 71171_101417,
    技能名称: '中毒(DOT)',
    统计名称: '中毒(DOT)',
    基础伤害_基础值: 1,
    技能伤害系数: 461,
    DOT生效间隔: 16,
    DOT跳数: 6,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 102163,
    技能名称: '青圃着尘·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 323,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 101357,
    技能名称: '钩吻断肠·悟·普通',
    统计名称: '钩吻断肠·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 252,
    技能增益列表: 钩吻断肠增益,
  },
  {
    技能ID: 101358,
    技能名称: '钩吻断肠·悟·追加',
    统计名称: '钩吻断肠·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 308,
    技能增益列表: 钩吻断肠增益,
  },
  {
    技能ID: 101425,
    技能名称: '苍棘缚地·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 634,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 101365,
    技能名称: '紫叶沉疴·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 690,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  // -------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
