import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import PieChart from './饼图'
import LineChart from './柱图'
import { RightCircleOutlined } from '@ant-design/icons'

const countMap = {
  周天功: 165784,
  山海心诀: 109513,
  花间游: 92932,
  孤锋诀: 80618,
  北傲诀: 74010,
  凌海诀: 62056,
  太玄经: 57894,
  分山劲: 56073,
  毒经: 45521,
  无方: 39676,
  易筋经: 21230,
  孤锋诀_悟: 20319,
  山海心诀_悟: 20291,
  无方_悟: 6164,
  太玄经_悟: 5508,
  周天功_悟: 1922,
}

function Step4() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const pieRef = useRef<any>()
  const lineRef = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      pieRef?.current?.initChart()
      lineRef?.current?.initChart()
    }, 200)
  }, [])

  const text1props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 0) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <div className={styles.content}>
        <div className={styles.chartWrap}>
          {/* {action > 0 ? ( */}
          <PieChart ref={pieRef} data={countMap} />
          <LineChart ref={lineRef} data={countMap} />
        </div>
        <animated.div style={text1props}>
          <h1 className={styles.title}>
            这就是新门派的热度吗
            <RightCircleOutlined style={{ marginLeft: 12 }} />
          </h1>
        </animated.div>
      </div>
    </div>
  )
}

export default Step4
