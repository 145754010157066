import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试 from './测试.json'

// import 可乐一阳 from './可乐/一阳.json'

// import 纯指_三段加速 from './风雪/纯指_三段加速.json'
// import 风雪清激 from './风雪/清激.json'

// import 封催11 from './福姬/封催11.json'
// import 封催10 from './福姬/封催10.json'
// import 福姬一阳2 from './福姬/一阳_2.json'
// import 风指_控能简易版 from './福姬/风指_控能简易版.json'

// import 十九劈 from './十九停/劈.json'
// import 十九停一阳2 from './十九停/一阳_2.json'
// import 清激 from './十九停/清激.json'
// import 买糖 from './买糖.json'
// import 风雪 from './风雪.json'
// import 包包 from './包包.json'
// import 福姬 from './福姬.json'
// import 纯指 from './十九停/纯指.json'
// import 风指 from './十九停/风指.json'

// import 卫分_大风指 from './蔓海赋尘/卫分_大风指.json'
// import 风指_破起手 from './蔓海赋尘/风指_破起手.json'
// import 风指_引起手 from './蔓海赋尘/风指_引起手.json'

// import 模拟器_一阳来复 from './模拟器_一阳来复.json'
// import 模拟器_十九停 from './模拟器_十九停.json'
// import 模拟器_七海 from './模拟器_七海.json'
// import 封纷206 from './封纷206.json'
import 封纷 from './封纷.json'
// import 封纷3488 from './封纷3488.json'
// import 封纷新手 from './封纷新手.json'
// import 清激10 from './清激10.json'
// import 清激11 from './清激11.json'
// import 清激20 from './清激20.json'
import 相息一键宏 from './相息一键宏.json'
import 胧雾观花 from './胧雾观花.json'
import 橙武模拟 from './橙武模拟.json'

const 计算循环: 循环数据[] = [
  相息一键宏,
  // 封纷新手,
  封纷,
  胧雾观花,
  // 封纷3488,
  橙武模拟,
  // 封纷新手,
  // 封纷206,
  // 封纷3488,
  // 封催13,
  // 清激10,
  // 清激11,
  // 清激20,
  // 橙武JCL,
  // 清激_3488,
  // 封催CW,
  // 测试,
  // ---可乐
  // 可乐一阳,
  // 大风指,
  // 纯指,
  // 风指,
  // 风指_珠海盯帧,
  // 风雪清激,
  // ---福姬
  // 福姬一阳,
  // 福姬一阳2,
  // 风指_一段加速_2,
  // 风指_稳定10破,
  // 风指_控能简易版,
  // ---风雪
  // 风指_一段加速,
  // 风指_37引,
  // 风指_38引,
  // ---十九停
  // 十九劈,
  // 十九停一阳2,
  // 测试,
  // 封催11,
  // 封催10,
  // 清激,
  // 买糖,
  // 包包,
  // 福姬,
  // 风雪,
  // ----蔓海赋尘
  // 卫分_大风指,
  // 风指_引起手,
  // 风指_破起手,
  // 纯指_三段加速,
  // 模拟器_一阳来复,
  // 模拟器_十九停,
  // 模拟器_七海,
] as 循环数据[]

// import { 分析函数 } from './分析函数'
// 分析函数(计算循环)

// import { 技能序列导出 } from './分析函数'
// 技能序列导出(true)

export default 计算循环
