import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 鞋子装备数据: 装备属性信息模型[] = [
  {
    id: 103809,
    图标ID: 22086,
    uid: 103809,
    装备名称: '陶然意·月容靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103806,
    图标ID: 22086,
    uid: 103806,
    装备名称: '陶然意·修茂靴',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103805,
    图标ID: 22086,
    uid: 103805,
    装备名称: '陶然意·理乾靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103801,
    图标ID: 22086,
    uid: 103801,
    装备名称: '陶然意·来仪靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103799,
    图标ID: 22086,
    uid: 103799,
    装备名称: '陶然意·凡圣靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103795,
    图标ID: 22086,
    uid: 103795,
    装备名称: '陶然意·连筠靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103794,
    图标ID: 22086,
    uid: 103794,
    装备名称: '陶然意·蛇言靴',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103793,
    图标ID: 22086,
    uid: 103793,
    装备名称: '陶然意·寒河靴',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103791,
    图标ID: 22086,
    uid: 103791,
    装备名称: '陶然意·清静靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103789,
    图标ID: 22086,
    uid: 103789,
    装备名称: '陶然意·簇锦靴',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103788,
    图标ID: 22086,
    uid: 103788,
    装备名称: '陶然意·祇树靴',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101603,
    图标ID: 23152,
    uid: 101603,
    装备名称: '花念靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.破招值, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101602,
    图标ID: 23152,
    uid: 101602,
    装备名称: '花深靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.破招值, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101537,
    图标ID: 23146,
    uid: 101537,
    装备名称: '青桑靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101536,
    图标ID: 23146,
    uid: 101536,
    装备名称: '季春靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101501,
    图标ID: 23146,
    uid: 101501,
    装备名称: '游山靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101500,
    图标ID: 23146,
    uid: 101500,
    装备名称: '思玉靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101425,
    图标ID: 23195,
    uid: 101425,
    装备名称: '孤漠·织春靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101421,
    图标ID: 23279,
    uid: 101421,
    装备名称: '孤漠·竹烟靴',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101420,
    图标ID: 23273,
    uid: 101420,
    装备名称: '孤漠·星思靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101415,
    图标ID: 23285,
    uid: 101415,
    装备名称: '孤漠·玉寒靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101411,
    图标ID: 23219,
    uid: 101411,
    装备名称: '孤漠·星野靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101407,
    图标ID: 23243,
    uid: 101407,
    装备名称: '孤漠·雾山靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101405,
    图标ID: 23267,
    uid: 101405,
    装备名称: '孤漠·芳丛靴',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101403,
    图标ID: 23231,
    uid: 101403,
    装备名称: '孤漠·染晴靴',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101401,
    图标ID: 23182,
    uid: 101401,
    装备名称: '孤漠·云岫靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101397,
    图标ID: 23255,
    uid: 101397,
    装备名称: '孤漠·梅曳靴',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101395,
    图标ID: 23237,
    uid: 101395,
    装备名称: '孤漠·闻闲履',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101250,
    图标ID: 9689,
    uid: 101250,
    装备名称: '漠晖靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101249,
    图标ID: 9689,
    uid: 101249,
    装备名称: '漠安靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104450,
    图标ID: 9245,
    uid: 104450,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 3903 },
      { 属性: 属性类型.全会心等级, 值: 4454 },
      { 属性: 属性类型.破招值, 值: 4454 },
      { 属性: 属性类型.无双等级, 值: 4454 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104449,
    图标ID: 9245,
    uid: 104449,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 3230 },
      { 属性: 属性类型.内功破防等级, 值: 4900 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104448,
    图标ID: 9245,
    uid: 104448,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 4576 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101826,
    图标ID: 9245,
    uid: 101826,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 3903 },
      { 属性: 属性类型.全会心等级, 值: 4454 },
      { 属性: 属性类型.破招值, 值: 4454 },
      { 属性: 属性类型.无双等级, 值: 4454 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101825,
    图标ID: 9245,
    uid: 101825,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 3230 },
      { 属性: 属性类型.内功破防等级, 值: 4900 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101824,
    图标ID: 9245,
    uid: 101824,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.内功基础攻击, 值: 4576 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104438,
    图标ID: 9245,
    uid: 104438,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3750 },
      { 属性: 属性类型.内功破防等级, 值: 4280 },
      { 属性: 属性类型.破招值, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104437,
    图标ID: 9245,
    uid: 104437,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3750 },
      { 属性: 属性类型.全会心等级, 值: 4280 },
      { 属性: 属性类型.内功破防等级, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104436,
    图标ID: 9245,
    uid: 104436,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3104 },
      { 属性: 属性类型.全会心等级, 值: 9843 },
      { 属性: 属性类型.无双等级, 值: 5136 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101802,
    图标ID: 9245,
    uid: 101802,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3750 },
      { 属性: 属性类型.内功破防等级, 值: 4280 },
      { 属性: 属性类型.破招值, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101801,
    图标ID: 9245,
    uid: 101801,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3750 },
      { 属性: 属性类型.全会心等级, 值: 4280 },
      { 属性: 属性类型.内功破防等级, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101800,
    图标ID: 9245,
    uid: 101800,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.内功基础攻击, 值: 3104 },
      { 属性: 属性类型.全会心等级, 值: 9843 },
      { 属性: 属性类型.无双等级, 值: 5136 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104426,
    图标ID: 9245,
    uid: 104426,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 3597 },
      { 属性: 属性类型.全会心等级, 值: 6158 },
      { 属性: 属性类型.全会心效果等级, 值: 3079 },
      { 属性: 属性类型.无双等级, 值: 3079 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104425,
    图标ID: 9245,
    uid: 104425,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 2481 },
      { 属性: 属性类型.破招值, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 6158 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104424,
    图标ID: 9245,
    uid: 104424,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 2977 },
      { 属性: 属性类型.内功破防等级, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 4926 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101778,
    图标ID: 9245,
    uid: 101778,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 3597 },
      { 属性: 属性类型.全会心等级, 值: 6158 },
      { 属性: 属性类型.全会心效果等级, 值: 3079 },
      { 属性: 属性类型.无双等级, 值: 3079 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101777,
    图标ID: 9245,
    uid: 101777,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 2481 },
      { 属性: 属性类型.破招值, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 6158 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101776,
    图标ID: 9245,
    uid: 101776,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.内功基础攻击, 值: 2977 },
      { 属性: 属性类型.内功破防等级, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 4926 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 103647,
    图标ID: 22086,
    uid: 103647,
    装备名称: '陶然意·月容靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103644,
    图标ID: 22086,
    uid: 103644,
    装备名称: '陶然意·修茂靴',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103643,
    图标ID: 22086,
    uid: 103643,
    装备名称: '陶然意·理乾靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103639,
    图标ID: 22086,
    uid: 103639,
    装备名称: '陶然意·来仪靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103637,
    图标ID: 22086,
    uid: 103637,
    装备名称: '陶然意·凡圣靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103633,
    图标ID: 22086,
    uid: 103633,
    装备名称: '陶然意·连筠靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103632,
    图标ID: 22086,
    uid: 103632,
    装备名称: '陶然意·蛇言靴',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103631,
    图标ID: 22086,
    uid: 103631,
    装备名称: '陶然意·寒河靴',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103629,
    图标ID: 22086,
    uid: 103629,
    装备名称: '陶然意·清静靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103627,
    图标ID: 22086,
    uid: 103627,
    装备名称: '陶然意·簇锦靴',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103626,
    图标ID: 22086,
    uid: 103626,
    装备名称: '陶然意·祇树靴',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104414,
    图标ID: 9245,
    uid: 104414,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 3444 },
      { 属性: 属性类型.全会心等级, 值: 3930 },
      { 属性: 属性类型.破招值, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3930 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104413,
    图标ID: 9245,
    uid: 104413,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 2850 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104412,
    图标ID: 9245,
    uid: 104412,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 4038 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103499,
    图标ID: 6551,
    uid: 103499,
    装备名称: '孤山寒月·忧生靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103498,
    图标ID: 6551,
    uid: 103498,
    装备名称: '孤山寒月·风吟靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101754,
    图标ID: 9245,
    uid: 101754,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 3444 },
      { 属性: 属性类型.全会心等级, 值: 3930 },
      { 属性: 属性类型.破招值, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3930 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101753,
    图标ID: 9245,
    uid: 101753,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 2850 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101752,
    图标ID: 9245,
    uid: 101752,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.内功基础攻击, 值: 4038 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101208,
    图标ID: 23146,
    uid: 101208,
    装备名称: '映宵靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101207,
    图标ID: 23146,
    uid: 101207,
    装备名称: '云眷靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101172,
    图标ID: 23146,
    uid: 101172,
    装备名称: '江汜靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101171,
    图标ID: 23146,
    uid: 101171,
    装备名称: '合苏靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101096,
    图标ID: 23195,
    uid: 101096,
    装备名称: '西塞·沉醉靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101092,
    图标ID: 23279,
    uid: 101092,
    装备名称: '西塞·银筱履',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101091,
    图标ID: 23273,
    uid: 101091,
    装备名称: '西塞·清浊靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101086,
    图标ID: 23285,
    uid: 101086,
    装备名称: '西塞·青弦靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101082,
    图标ID: 23219,
    uid: 101082,
    装备名称: '西塞·大荒靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101078,
    图标ID: 23243,
    uid: 101078,
    装备名称: '西塞·寒蛩靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101076,
    图标ID: 23267,
    uid: 101076,
    装备名称: '西塞·朝愁履',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101074,
    图标ID: 23231,
    uid: 101074,
    装备名称: '西塞·烟蘸履',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101072,
    图标ID: 23182,
    uid: 101072,
    装备名称: '西塞·雪欹履',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101068,
    图标ID: 23255,
    uid: 101068,
    装备名称: '西塞·合章履',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101066,
    图标ID: 23237,
    uid: 101066,
    装备名称: '西塞·彼岸履',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100987,
    图标ID: 8893,
    uid: 100987,
    装备名称: '寻踪觅宝·中林靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100986,
    图标ID: 8893,
    uid: 100986,
    装备名称: '寻踪觅宝·谷风靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104402,
    图标ID: 9245,
    uid: 104402,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 3306 },
      { 属性: 属性类型.内功破防等级, 值: 3773 },
      { 属性: 属性类型.破招值, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104401,
    图标ID: 9245,
    uid: 104401,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 3306 },
      { 属性: 属性类型.全会心等级, 值: 3773 },
      { 属性: 属性类型.内功破防等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104400,
    图标ID: 9245,
    uid: 104400,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 2736 },
      { 属性: 属性类型.全会心等级, 值: 8678 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101730,
    图标ID: 9245,
    uid: 101730,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 3306 },
      { 属性: 属性类型.内功破防等级, 值: 3773 },
      { 属性: 属性类型.破招值, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101729,
    图标ID: 9245,
    uid: 101729,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 3306 },
      { 属性: 属性类型.全会心等级, 值: 3773 },
      { 属性: 属性类型.内功破防等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101728,
    图标ID: 9245,
    uid: 101728,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.内功基础攻击, 值: 2736 },
      { 属性: 属性类型.全会心等级, 值: 8678 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100957,
    图标ID: 7991,
    uid: 100957,
    装备名称: '寻踪觅宝·月诸靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100956,
    图标ID: 7991,
    uid: 100956,
    装备名称: '寻踪觅宝·陟南靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100921,
    图标ID: 11603,
    uid: 100921,
    装备名称: '壁镜靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100920,
    图标ID: 11603,
    uid: 100920,
    装备名称: '壁观靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104390,
    图标ID: 9245,
    uid: 104390,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 3169 },
      { 属性: 属性类型.全会心等级, 值: 5424 },
      { 属性: 属性类型.全会心效果等级, 值: 2712 },
      { 属性: 属性类型.无双等级, 值: 2712 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104389,
    图标ID: 9245,
    uid: 104389,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 2185 },
      { 属性: 属性类型.破招值, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 5424 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104388,
    图标ID: 9245,
    uid: 104388,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 2622 },
      { 属性: 属性类型.内功破防等级, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101706,
    图标ID: 9245,
    uid: 101706,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 3169 },
      { 属性: 属性类型.全会心等级, 值: 5424 },
      { 属性: 属性类型.全会心效果等级, 值: 2712 },
      { 属性: 属性类型.无双等级, 值: 2712 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101705,
    图标ID: 9245,
    uid: 101705,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 2185 },
      { 属性: 属性类型.破招值, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 5424 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101704,
    图标ID: 9245,
    uid: 101704,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.内功基础攻击, 值: 2622 },
      { 属性: 属性类型.内功破防等级, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100795,
    图标ID: 5886,
    uid: 100795,
    装备名称: '语旭鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.元气, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100794,
    图标ID: 5886,
    uid: 100794,
    装备名称: '语阳鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.根骨, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104512,
    图标ID: 11603,
    uid: 104512,
    装备名称: '昭文靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104511,
    图标ID: 11603,
    uid: 104511,
    装备名称: '丘墟靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104480,
    图标ID: 11603,
    uid: 104480,
    装备名称: '玉凉靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104479,
    图标ID: 11603,
    uid: 104479,
    装备名称: '樛枝靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104124,
    图标ID: 11603,
    uid: 104124,
    装备名称: '斛尘靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104123,
    图标ID: 11603,
    uid: 104123,
    装备名称: '熙春靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100891,
    图标ID: 11603,
    uid: 100891,
    装备名称: '昭文靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100890,
    图标ID: 11603,
    uid: 100890,
    装备名称: '丘墟靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100855,
    图标ID: 11603,
    uid: 100855,
    装备名称: '玉凉靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100854,
    图标ID: 11603,
    uid: 100854,
    装备名称: '樛枝靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100819,
    图标ID: 11603,
    uid: 100819,
    装备名称: '斛尘靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100818,
    图标ID: 11603,
    uid: 100818,
    装备名称: '熙春靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100687,
    图标ID: 11046,
    uid: 100687,
    装备名称: '弥弦靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100686,
    图标ID: 11046,
    uid: 100686,
    装备名称: '珀音靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100651,
    图标ID: 11046,
    uid: 100651,
    装备名称: '霖瑜履',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100650,
    图标ID: 11046,
    uid: 100650,
    装备名称: '瑾辞履',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104378,
    图标ID: 9245,
    uid: 104378,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 3031 },
      { 属性: 属性类型.全会心等级, 值: 3459 },
      { 属性: 属性类型.破招值, 值: 3459 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104377,
    图标ID: 9245,
    uid: 104377,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 2508 },
      { 属性: 属性类型.内功破防等级, 值: 3805 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104376,
    图标ID: 9245,
    uid: 104376,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 3553 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101682,
    图标ID: 9245,
    uid: 101682,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 3031 },
      { 属性: 属性类型.全会心等级, 值: 3459 },
      { 属性: 属性类型.破招值, 值: 3459 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101681,
    图标ID: 9245,
    uid: 101681,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 2508 },
      { 属性: 属性类型.内功破防等级, 值: 3805 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101680,
    图标ID: 9245,
    uid: 101680,
    装备名称: '无修鞋·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.内功基础攻击, 值: 3553 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100771,
    图标ID: 5886,
    uid: 100771,
    装备名称: '语旭鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.元气, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.破招值, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100770,
    图标ID: 5886,
    uid: 100770,
    装备名称: '语阳鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.根骨, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.破招值, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100615,
    图标ID: 5886,
    uid: 100615,
    装备名称: '惜晗鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.元气, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.破招值, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100614,
    图标ID: 5886,
    uid: 100614,
    装备名称: '濯缨鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.根骨, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.破招值, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100747,
    图标ID: 5886,
    uid: 100747,
    装备名称: '语旭鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.元气, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.破招值, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100746,
    图标ID: 5886,
    uid: 100746,
    装备名称: '语阳鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.根骨, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.破招值, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100579,
    图标ID: 6825,
    uid: 100579,
    装备名称: '绢素靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.内功破防等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100578,
    图标ID: 6825,
    uid: 100578,
    装备名称: '烨霖靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.根骨, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.内功破防等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100723,
    图标ID: 5886,
    uid: 100723,
    装备名称: '语旭鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.元气, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.破招值, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100722,
    图标ID: 5886,
    uid: 100722,
    装备名称: '语阳鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.根骨, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.破招值, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101843,
    图标ID: 5886,
    uid: 101843,
    装备名称: '轻桡鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.全会心等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101842,
    图标ID: 5886,
    uid: 101842,
    装备名称: '临流鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.内功会心等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100543,
    图标ID: 17934,
    uid: 100543,
    装备名称: '凝玥鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100542,
    图标ID: 17934,
    uid: 100542,
    装备名称: '静漪鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
]

export default 鞋子装备数据
