import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReportContext from '../../context'
import styles from './index.module.less'
import PieChart from './条形图'
import LineChart from './柱图'
import { RightCircleOutlined } from '@ant-design/icons'

// 秒
const countMap = {
  山海心诀: 657,
  孤锋诀: 340,
  周天功: 325,
  北傲诀: 323,
  毒经: 322,
  凌海诀: 314,
  花间游: 307,
  太玄经: 301,
  易筋经: 299,
  分山劲: 298,
  无方: 295,
}

function Step5() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const pieRef = useRef<any>()
  const lineRef = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      pieRef?.current?.initChart()
      lineRef?.current?.initChart()
    }, 200)
  }, [])

  const text1props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 0) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 0 ? styles.next : ''}`} onClick={toNextStep}>
      <div className={styles.content}>
        <div className={styles.chartWrap}>
          {/* {action > 0 ? ( */}
          <LineChart ref={lineRef} data={countMap} />
          <PieChart ref={pieRef} data={countMap} />
        </div>
        <animated.div style={text1props}>
          <h1 className={styles.title}>
            山海心诀心法的用户平均使用时间遥遥领先。他们在钻研什么新打法吗？
            <RightCircleOutlined style={{ marginLeft: 12 }} />
          </h1>
        </animated.div>
      </div>
    </div>
  )
}

export default Step5
