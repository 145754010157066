/**
 * 访问量图表
 */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as G2 from '@antv/g2'
import styles from './index.module.less'

function 技能统计图表(props, ref) {
  const { data } = props
  const [chartData, setChartData] = useState<any>()

  const limitRef: any = useRef<any>()

  const getDataSource = () => {
    const res = Object.keys(data)?.map((key) => {
      return {
        时间: key,
        占比: data[key],
      }
    })
    res.reverse()

    return res
  }

  useImperativeHandle(ref, () => ({
    initChart: initChart,
  }))

  useEffect(() => {
    limitRef.current = false
    return () => {
      limitRef.current = false
    }
  }, [])

  const initChart = () => {
    if (limitRef.current) {
      return
    }
    limitRef.current = true

    const chart = chartData
      ? chartData
      : new G2.Chart({
          container: 'line-view-chart',
          autoFit: true,
          renderer: 'canvas',
          padding: [20, 100, 20, 100],
        })

    if (!chartData) {
      setChartData(chart)
    }

    chart.clear()
    chart.tooltip({
      crosshairs: false,
    })
    chart.legend(false)

    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    })

    chart.axis('占比', {
      title: null,
      line: false,
      tickLine: false,
      subTickLine: false,
      label: {
        style: {
          fill: '#ffffff', // 文本的颜色
          fontSize: 20, // 文本大小
        },
      },
    })

    chart.axis('占比', {
      title: null,
      line: false,
      // 设置刻度线
      tickLine: false,
      grid: {
        line: {
          style: {
            stroke: 'rgba(255,255,255,0.5)', // 修改网格线的颜色
            lineWidth: 2, // 修改网格线的宽度
            lineDash: [4, 4], // 虚线的设置，参考CSS的lineDash
          },
        },
      },
      label: {
        style: {
          fill: '#ffffff', // 文本的颜色
          fontSize: 12, // 文本大小
        },
      },
    })

    chart
      .line()
      .position('时间*占比')
      .animate({
        enter: {
          animation: 'fade-in', // 动画名称
          easing: 'easeQuadIn', // 动画缓动效果
          delay: 100, // 动画延迟执行时间
          duration: 5000, // 动画执行时间
        },
      })
      .shape('smooth')

    const data = getDataSource()
    chart.data(data)
    chart.render()

    setTimeout(() => {
      limitRef.current = false
    }, 10)
  }

  return <div className={styles.viewChart} id='line-view-chart' />
}

export default forwardRef(技能统计图表)
