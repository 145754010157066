/**
 * 访问量图表
 */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as G2 from '@antv/g2'
import { 心法 } from '@/心法模块/index'
import styles from './index.module.less'
import { formatSeconds } from './utils'

function 技能统计图表(props, ref) {
  const { data } = props
  const [chartData, setChartData] = useState<any>()

  const limitRef: any = useRef<any>()

  const getDataSource = () => {
    const res = Object.keys(data)?.map((key) => {
      const trueName = key?.replace('·', '_')
      const color = 心法?.[trueName]?.系统配置?.主题色
      return {
        心法: key,
        时长: data[key],
        颜色: color,
      }
    })
    res.sort((a, b) => a.时长 - b.时长)
    return res
  }

  useImperativeHandle(ref, () => ({
    initChart: initChart,
  }))

  useEffect(() => {
    limitRef.current = false
    return () => {
      limitRef.current = false
    }
  }, [])

  const initChart = () => {
    if (limitRef.current) {
      return
    }
    limitRef.current = true

    const chart = chartData
      ? chartData
      : new G2.Chart({
          container: 'line-view-chart',
          autoFit: true,
          renderer: 'canvas',
          padding: [20, 20, 20, 20],
        })

    if (!chartData) {
      setChartData(chart)
    }

    const dataSource = getDataSource()

    const colorList = dataSource?.map((item) => item?.颜色)
    chart.clear()
    chart.coordinate('theta', {
      innerRadius: 0.35,
      radius: 0.75,
    })
    chart.tooltip({
      showMarkers: false,
    })
    chart.legend(false)
    const { subColor } = chart.getTheme()
    chart
      .interval({
        background: { style: { fill: subColor, fillOpacity: 1 } },
      })
      .adjust('stack')
      .position('心法*时长')
      .color('心法', colorList)
      .style({
        lineCap: 'round',
      })
      .label('时长', {
        offset: 16,
        style: {
          fill: '#ffffff',
          fontSize: 16,
        },
        content: (obj) => {
          return `${obj.心法}：${formatSeconds(obj.时长)}`
        },
      })

    chart.data(dataSource)
    chart.annotation().html({
      position: ['50%', '50%'],
      html: () => {
        return `<div style="transform:translate(-50%,-50%)">
                  <img alt="" width="80" height="80" style="border-radius: 50%" src="https://img1.baidu.com/it/u=1140823085,1031520433&fm=253&fmt=auto&app=120&f=JPEG?w=577&h=500">
                </div>`
      },
    })
    chart.render()

    setTimeout(() => {
      limitRef.current = false
    }, 10)
  }

  return <div className={styles.viewChart} id='line-view-chart' />
}

export default forwardRef(技能统计图表)
