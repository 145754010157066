import React from 'react'
import 入口图片 from '../静态资源/入口.png'
import styles from './index.module.less'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'

function 年度报告入口() {
  const { 简写 } = 获取当前数据()
  return (
    <div className={styles.enter} onClick={() => window?.open(`/report?xf=${简写}`)}>
      <img className={styles.img} src={入口图片} />
      <div className={styles.content}>
        <h1 className={styles.text}>年度报告</h1>
      </div>
    </div>
  )
}

export default 年度报告入口
