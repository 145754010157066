import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 40320,
    图标ID: 23410,
    uid: 40320,
    装备名称: '金乌回首',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 10523 },
      { 属性: 属性类型.外功破防等级, 值: 9608 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40319,
    图标ID: 23389,
    uid: 40319,
    装备名称: '烛微刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8089 },
      { 属性: 属性类型.外功会心等级, 值: 10523 },
      { 属性: 属性类型.外功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40315,
    图标ID: 23396,
    uid: 40315,
    装备名称: '长安',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 10523 },
      { 属性: 属性类型.外功破防等级, 值: 9608 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40314,
    图标ID: 23399,
    uid: 40314,
    装备名称: '七月嘉树',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 10980 },
      { 属性: 属性类型.外功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40313,
    图标ID: 23382,
    uid: 40313,
    装备名称: '碧血豪侠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 10065 },
      { 属性: 属性类型.外功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40309,
    图标ID: 23384,
    uid: 40309,
    装备名称: '天下宏愿',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8711 },
      { 属性: 属性类型.外功会心等级, 值: 9608 },
      { 属性: 属性类型.外功破防等级, 值: 8693 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40306,
    图标ID: 23395,
    uid: 40306,
    装备名称: '暴龙震江',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 10523 },
      { 属性: 属性类型.外功破防等级, 值: 11438 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40304,
    图标ID: 23385,
    uid: 40304,
    装备名称: '伏魔悲音',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 6160,
    武器伤害_最大值: 10267,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 9150 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40303,
    图标ID: 23404,
    uid: 40303,
    装备名称: '银羽雪辉',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7716 },
      { 属性: 属性类型.外功会心等级, 值: 10523 },
      { 属性: 属性类型.外功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40297,
    图标ID: 23387,
    uid: 40297,
    装备名称: '镇恶',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 6160,
    武器伤害_最大值: 10267,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 11438 },
      { 属性: 属性类型.外功破防等级, 值: 9608 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40294,
    图标ID: 23407,
    uid: 40294,
    装备名称: '掠炎',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 10980 },
      { 属性: 属性类型.外功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40288,
    图标ID: 23410,
    uid: 40288,
    装备名称: '金乌回首',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 9925 },
      { 属性: 属性类型.外功破防等级, 值: 9062 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40287,
    图标ID: 23389,
    uid: 40287,
    装备名称: '烛微刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7630 },
      { 属性: 属性类型.外功会心等级, 值: 9925 },
      { 属性: 属性类型.外功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40283,
    图标ID: 23396,
    uid: 40283,
    装备名称: '长安',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 9925 },
      { 属性: 属性类型.外功破防等级, 值: 9062 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40282,
    图标ID: 23399,
    uid: 40282,
    装备名称: '七月嘉树',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 10356 },
      { 属性: 属性类型.外功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40281,
    图标ID: 23382,
    uid: 40281,
    装备名称: '碧血豪侠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 9493 },
      { 属性: 属性类型.外功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40277,
    图标ID: 23384,
    uid: 40277,
    装备名称: '天下宏愿',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 8216 },
      { 属性: 属性类型.外功会心等级, 值: 9062 },
      { 属性: 属性类型.外功破防等级, 值: 8199 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40274,
    图标ID: 23395,
    uid: 40274,
    装备名称: '暴龙震江',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 9925 },
      { 属性: 属性类型.外功破防等级, 值: 10788 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40272,
    图标ID: 23385,
    uid: 40272,
    装备名称: '伏魔悲音',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 5810,
    武器伤害_最大值: 9683,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 8630 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40271,
    图标ID: 23404,
    uid: 40271,
    装备名称: '银羽雪辉',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7278 },
      { 属性: 属性类型.外功会心等级, 值: 9925 },
      { 属性: 属性类型.外功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40265,
    图标ID: 23387,
    uid: 40265,
    装备名称: '镇恶',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 5810,
    武器伤害_最大值: 9683,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 10788 },
      { 属性: 属性类型.外功破防等级, 值: 9062 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40262,
    图标ID: 23407,
    uid: 40262,
    装备名称: '掠炎',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 10356 },
      { 属性: 属性类型.外功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40256,
    图标ID: 23410,
    uid: 40256,
    装备名称: '金乌回首',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 9327 },
      { 属性: 属性类型.外功破防等级, 值: 8516 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40255,
    图标ID: 23389,
    uid: 40255,
    装备名称: '烛微刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7170 },
      { 属性: 属性类型.外功会心等级, 值: 9327 },
      { 属性: 属性类型.外功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40251,
    图标ID: 23396,
    uid: 40251,
    装备名称: '长安',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 9327 },
      { 属性: 属性类型.外功破防等级, 值: 8516 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40250,
    图标ID: 23399,
    uid: 40250,
    装备名称: '七月嘉树',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 9732 },
      { 属性: 属性类型.外功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40249,
    图标ID: 23382,
    uid: 40249,
    装备名称: '碧血豪侠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 8921 },
      { 属性: 属性类型.外功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40245,
    图标ID: 23384,
    uid: 40245,
    装备名称: '天下宏愿',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7721 },
      { 属性: 属性类型.外功会心等级, 值: 8516 },
      { 属性: 属性类型.外功破防等级, 值: 7705 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40242,
    图标ID: 23395,
    uid: 40242,
    装备名称: '暴龙震江',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 9327 },
      { 属性: 属性类型.外功破防等级, 值: 10138 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40240,
    图标ID: 23385,
    uid: 40240,
    装备名称: '伏魔悲音',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 5460,
    武器伤害_最大值: 9100,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 8110 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40239,
    图标ID: 23404,
    uid: 40239,
    装备名称: '银羽雪辉',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 6840 },
      { 属性: 属性类型.外功会心等级, 值: 9327 },
      { 属性: 属性类型.外功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40233,
    图标ID: 23387,
    uid: 40233,
    装备名称: '镇恶',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 5460,
    武器伤害_最大值: 9100,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 10138 },
      { 属性: 属性类型.外功破防等级, 值: 8516 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40230,
    图标ID: 23407,
    uid: 40230,
    装备名称: '掠炎',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 9732 },
      { 属性: 属性类型.外功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40224,
    图标ID: 23410,
    uid: 40224,
    装备名称: '金乌回首',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 8753 },
      { 属性: 属性类型.外功破防等级, 值: 7992 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40223,
    图标ID: 23389,
    uid: 40223,
    装备名称: '烛微刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6729 },
      { 属性: 属性类型.外功会心等级, 值: 8753 },
      { 属性: 属性类型.外功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40219,
    图标ID: 23396,
    uid: 40219,
    装备名称: '长安',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 8753 },
      { 属性: 属性类型.外功破防等级, 值: 7992 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40218,
    图标ID: 23399,
    uid: 40218,
    装备名称: '七月嘉树',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 9133 },
      { 属性: 属性类型.外功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40217,
    图标ID: 23382,
    uid: 40217,
    装备名称: '碧血豪侠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 8372 },
      { 属性: 属性类型.外功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40213,
    图标ID: 23384,
    uid: 40213,
    装备名称: '天下宏愿',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 7246 },
      { 属性: 属性类型.外功会心等级, 值: 7992 },
      { 属性: 属性类型.外功破防等级, 值: 7231 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40210,
    图标ID: 23395,
    uid: 40210,
    装备名称: '暴龙震江',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 8753 },
      { 属性: 属性类型.外功破防等级, 值: 9514 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40208,
    图标ID: 23385,
    uid: 40208,
    装备名称: '伏魔悲音',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5124,
    武器伤害_最大值: 8540,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 7611 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40207,
    图标ID: 23404,
    uid: 40207,
    装备名称: '银羽雪辉',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6419 },
      { 属性: 属性类型.外功会心等级, 值: 8753 },
      { 属性: 属性类型.外功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40201,
    图标ID: 23387,
    uid: 40201,
    装备名称: '镇恶',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5124,
    武器伤害_最大值: 8540,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 9514 },
      { 属性: 属性类型.外功破防等级, 值: 7992 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40198,
    图标ID: 23407,
    uid: 40198,
    装备名称: '掠炎',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 9133 },
      { 属性: 属性类型.外功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40192,
    图标ID: 23410,
    uid: 40192,
    装备名称: '金乌回首',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 8179 },
      { 属性: 属性类型.外功破防等级, 值: 7468 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40191,
    图标ID: 23389,
    uid: 40191,
    装备名称: '烛微刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6288 },
      { 属性: 属性类型.外功会心等级, 值: 8179 },
      { 属性: 属性类型.外功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40187,
    图标ID: 23396,
    uid: 40187,
    装备名称: '长安',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 8179 },
      { 属性: 属性类型.外功破防等级, 值: 7468 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40186,
    图标ID: 23399,
    uid: 40186,
    装备名称: '七月嘉树',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 8534 },
      { 属性: 属性类型.外功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40185,
    图标ID: 23382,
    uid: 40185,
    装备名称: '碧血豪侠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 7823 },
      { 属性: 属性类型.外功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40181,
    图标ID: 23384,
    uid: 40181,
    装备名称: '天下宏愿',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6771 },
      { 属性: 属性类型.外功会心等级, 值: 7468 },
      { 属性: 属性类型.外功破防等级, 值: 6756 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40178,
    图标ID: 23395,
    uid: 40178,
    装备名称: '暴龙震江',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 8179 },
      { 属性: 属性类型.外功破防等级, 值: 8890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40176,
    图标ID: 23385,
    uid: 40176,
    装备名称: '伏魔悲音',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 4788,
    武器伤害_最大值: 7980,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 7112 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40175,
    图标ID: 23404,
    uid: 40175,
    装备名称: '银羽雪辉',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 5998 },
      { 属性: 属性类型.外功会心等级, 值: 8179 },
      { 属性: 属性类型.外功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40169,
    图标ID: 23387,
    uid: 40169,
    装备名称: '镇恶',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 4788,
    武器伤害_最大值: 7980,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 8890 },
      { 属性: 属性类型.外功破防等级, 值: 7468 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40166,
    图标ID: 23407,
    uid: 40166,
    装备名称: '掠炎',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 8534 },
      { 属性: 属性类型.外功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38587,
    图标ID: 20244,
    uid: 38587,
    装备名称: '煞·庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38586,
    图标ID: 17801,
    uid: 38586,
    装备名称: '煞·绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4596 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38582,
    图标ID: 17803,
    uid: 38582,
    装备名称: '煞·山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38581,
    图标ID: 17806,
    uid: 38581,
    装备名称: '煞·山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 6239 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38580,
    图标ID: 17791,
    uid: 38580,
    装备名称: '煞·沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 5719 },
      { 属性: 属性类型.外功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38576,
    图标ID: 17793,
    uid: 38576,
    装备名称: '煞·孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4808 },
      { 属性: 属性类型.外功会心等级, 值: 5459 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38573,
    图标ID: 17802,
    uid: 38573,
    装备名称: '煞·拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 6499 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38571,
    图标ID: 17794,
    uid: 38571,
    装备名称: '煞·寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 6759 },
      { 属性: 属性类型.外功破防等级, 值: 5199 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38570,
    图标ID: 17813,
    uid: 38570,
    装备名称: '煞·摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4384 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 6499 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38564,
    图标ID: 17800,
    uid: 38564,
    装备名称: '煞·风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4455 },
      { 属性: 属性类型.外功会心等级, 值: 6499 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38561,
    图标ID: 17815,
    uid: 38561,
    装备名称: '煞·旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38077,
    图标ID: 20244,
    uid: 38077,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38076,
    图标ID: 17801,
    uid: 38076,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4596 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38072,
    图标ID: 17803,
    uid: 38072,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38071,
    图标ID: 17806,
    uid: 38071,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 6239 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38070,
    图标ID: 17791,
    uid: 38070,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 5719 },
      { 属性: 属性类型.外功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38066,
    图标ID: 17793,
    uid: 38066,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4808 },
      { 属性: 属性类型.外功会心等级, 值: 5459 },
      { 属性: 属性类型.外功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38063,
    图标ID: 17802,
    uid: 38063,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4667 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 6499 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38061,
    图标ID: 17794,
    uid: 38061,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 6759 },
      { 属性: 属性类型.外功破防等级, 值: 5199 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38060,
    图标ID: 17813,
    uid: 38060,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4384 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 6499 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38054,
    图标ID: 17800,
    uid: 38054,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.身法, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4455 },
      { 属性: 属性类型.外功会心等级, 值: 6499 },
      { 属性: 属性类型.外功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38051,
    图标ID: 17815,
    uid: 38051,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.力道, 值: 933 },
      { 属性: 属性类型.外功基础攻击, 值: 4526 },
      { 属性: 属性类型.外功会心等级, 值: 5979 },
      { 属性: 属性类型.外功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40420,
    图标ID: 20150,
    uid: 40420,
    装备名称: '陶然意·拥狸弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40419,
    图标ID: 20149,
    uid: 40419,
    装备名称: '陶然意·万仞刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40416,
    图标ID: 20146,
    uid: 40416,
    装备名称: '陶然意·徇节链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40415,
    图标ID: 20145,
    uid: 40415,
    装备名称: '陶然意·临溟伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40414,
    图标ID: 20144,
    uid: 40414,
    装备名称: '陶然意·上造刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40412,
    图标ID: 20142,
    uid: 40412,
    装备名称: '陶然意·征惯盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40410,
    图标ID: 20141,
    uid: 40410,
    装备名称: '陶然意·春瓮棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40408,
    图标ID: 20134,
    uid: 40408,
    装备名称: '陶然意·吴陵轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 5302,
    武器伤害_最大值: 8837,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40407,
    图标ID: 20139,
    uid: 40407,
    装备名称: '陶然意·穿心弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40403,
    图标ID: 20133,
    uid: 40403,
    装备名称: '陶然意·蜕羽剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 5302,
    武器伤害_最大值: 8837,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40401,
    图标ID: 20132,
    uid: 40401,
    装备名称: '陶然意·登锋枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1313 },
      { 属性: 属性类型.外功基础攻击, 值: 6000 },
      { 属性: 属性类型.外功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 39394,
    图标ID: 20231,
    uid: 39394,
    装备名称: '彩星',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39393,
    图标ID: 17808,
    uid: 39393,
    装备名称: '世人',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39389,
    图标ID: 18027,
    uid: 39389,
    装备名称: '侠骨',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39388,
    图标ID: 18029,
    uid: 39388,
    装备名称: '神仙府',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39387,
    图标ID: 18021,
    uid: 39387,
    装备名称: '瑞气',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39383,
    图标ID: 18022,
    uid: 39383,
    装备名称: '除恶',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6118,
    武器伤害_最大值: 10197,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39380,
    图标ID: 18026,
    uid: 39380,
    装备名称: '自酌',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39378,
    图标ID: 18023,
    uid: 39378,
    装备名称: '眸羽',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 5302,
    武器伤害_最大值: 8837,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39377,
    图标ID: 18032,
    uid: 39377,
    装备名称: '鬼魅',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39371,
    图标ID: 18025,
    uid: 39371,
    装备名称: '日月斛光',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 5302,
    武器伤害_最大值: 8837,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.身法, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39368,
    图标ID: 18033,
    uid: 39368,
    装备名称: '天地有时',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.力道, 值: 1414 },
      { 属性: 属性类型.外功基础攻击, 值: 7070 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39356,
    图标ID: 23101,
    uid: 39356,
    装备名称: '信陵恩',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39355,
    图标ID: 23037,
    uid: 39355,
    装备名称: '无宵忧',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39351,
    图标ID: 23061,
    uid: 39351,
    装备名称: '怨王侯',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39350,
    图标ID: 23071,
    uid: 39350,
    装备名称: '望海阔',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39349,
    图标ID: 23012,
    uid: 39349,
    装备名称: '问沈浮',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39345,
    图标ID: 23017,
    uid: 39345,
    装备名称: '欲悲歌',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39342,
    图标ID: 23056,
    uid: 39342,
    装备名称: '共离樽',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39340,
    图标ID: 23032,
    uid: 39340,
    装备名称: '洒宸襟',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39339,
    图标ID: 23086,
    uid: 39339,
    装备名称: '秦川月',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39333,
    图标ID: 23022,
    uid: 39333,
    装备名称: '迟来客',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39330,
    图标ID: 23091,
    uid: 39330,
    装备名称: '去关河',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39324,
    图标ID: 23032,
    uid: 39324,
    装备名称: '浮溪轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39322,
    图标ID: 23101,
    uid: 39322,
    装备名称: '幽篁弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39321,
    图标ID: 23037,
    uid: 39321,
    装备名称: '倾翰刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39317,
    图标ID: 23061,
    uid: 39317,
    装备名称: '枉隐链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39316,
    图标ID: 23071,
    uid: 39316,
    装备名称: '碧涧伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39315,
    图标ID: 23012,
    uid: 39315,
    装备名称: '凌寒刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39311,
    图标ID: 23017,
    uid: 39311,
    装备名称: '云屯盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39308,
    图标ID: 23056,
    uid: 39308,
    装备名称: '客游棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39306,
    图标ID: 23032,
    uid: 39306,
    装备名称: '浮溪轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39305,
    图标ID: 23086,
    uid: 39305,
    装备名称: '息舟弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39299,
    图标ID: 23022,
    uid: 39299,
    装备名称: '微命剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39296,
    图标ID: 23091,
    uid: 39296,
    装备名称: '暝投枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39284,
    图标ID: 23032,
    uid: 39284,
    装备名称: '兰茂轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39282,
    图标ID: 23101,
    uid: 39282,
    装备名称: '衣香弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39281,
    图标ID: 23037,
    uid: 39281,
    装备名称: '酌谣刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39277,
    图标ID: 23061,
    uid: 39277,
    装备名称: '凋翡链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39276,
    图标ID: 23071,
    uid: 39276,
    装备名称: '讵愁伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39275,
    图标ID: 23012,
    uid: 39275,
    装备名称: '琼珍刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39271,
    图标ID: 23017,
    uid: 39271,
    装备名称: '燃山盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39268,
    图标ID: 23056,
    uid: 39268,
    装备名称: '兰穆棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39266,
    图标ID: 23032,
    uid: 39266,
    装备名称: '兰茂轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39265,
    图标ID: 23086,
    uid: 39265,
    装备名称: '折险弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39259,
    图标ID: 23022,
    uid: 39259,
    装备名称: '楚泽剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39256,
    图标ID: 23091,
    uid: 39256,
    装备名称: '东川枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39178,
    图标ID: 20231,
    uid: 39178,
    装备名称: '檐下偷闲',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39177,
    图标ID: 17825,
    uid: 39177,
    装备名称: '轻侠逢',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39173,
    图标ID: 12367,
    uid: 39173,
    装备名称: '不望',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39172,
    图标ID: 11183,
    uid: 39172,
    装备名称: '乘羽涉海',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39171,
    图标ID: 9772,
    uid: 39171,
    装备名称: '震九衢',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39167,
    图标ID: 9768,
    uid: 39167,
    装备名称: '征鼓动楼',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39164,
    图标ID: 9761,
    uid: 39164,
    装备名称: '褐衣野帽',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39162,
    图标ID: 9767,
    uid: 39162,
    装备名称: '风拂袂',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39161,
    图标ID: 9763,
    uid: 39161,
    装备名称: '修影匿',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39155,
    图标ID: 9766,
    uid: 39155,
    装备名称: '尘世纷',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39152,
    图标ID: 9764,
    uid: 39152,
    装备名称: '乘纵',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41046,
    图标ID: 20238,
    uid: 41046,
    装备名称: '裂风破天弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41045,
    图标ID: 18729,
    uid: 41045,
    装备名称: '四绝分海刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.力道, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41041,
    图标ID: 14639,
    uid: 41041,
    装备名称: '九节摘心链',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41040,
    图标ID: 14638,
    uid: 41040,
    装备名称: '风雪不归伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41039,
    图标ID: 14637,
    uid: 41039,
    装备名称: '百炼环首刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.力道, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41035,
    图标ID: 14633,
    uid: 41035,
    装备名称: '双虎遮日·雁门如铁',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 5699,
    武器伤害_最大值: 9499,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41032,
    图标ID: 14636,
    uid: 41032,
    装备名称: '青玉夺龙棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 6459,
    武器伤害_最大值: 10765,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.力道, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41030,
    图标ID: 14625,
    uid: 41030,
    装备名称: '青衣子母剑·轻',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 4939,
    武器伤害_最大值: 8232,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41029,
    图标ID: 14632,
    uid: 41029,
    装备名称: '浪子穿心弩·杀心',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 3800,
    武器伤害_最大值: 6333,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.力道, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41023,
    图标ID: 14624,
    uid: 41023,
    装备名称: '悲风悯月剑·分野',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 4939,
    武器伤害_最大值: 8232,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.身法, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 41020,
    图标ID: 14627,
    uid: 41020,
    装备名称: '红缨亮银枪·肃清',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 24500,
    武器伤害_最小值: 6459,
    武器伤害_最大值: 10765,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16141 },
      { 属性: 属性类型.力道, 值: 1317 },
      { 属性: 属性类型.外功基础攻击, 值: 6586 },
      { 属性: 属性类型.破招值, 值: 11005 },
      { 属性: 属性类型.无双等级, 值: 10638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40365,
    图标ID: 20150,
    uid: 40365,
    装备名称: '陶然意·拥狸弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40364,
    图标ID: 20149,
    uid: 40364,
    装备名称: '陶然意·万仞刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.力道, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40361,
    图标ID: 20146,
    uid: 40361,
    装备名称: '陶然意·徇节链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40360,
    图标ID: 20145,
    uid: 40360,
    装备名称: '陶然意·临溟伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40359,
    图标ID: 20144,
    uid: 40359,
    装备名称: '陶然意·上造刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.力道, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40357,
    图标ID: 20142,
    uid: 40357,
    装备名称: '陶然意·征惯盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5257,
    武器伤害_最大值: 8762,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40355,
    图标ID: 20141,
    uid: 40355,
    装备名称: '陶然意·春瓮棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5958,
    武器伤害_最大值: 9930,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.力道, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40353,
    图标ID: 20134,
    uid: 40353,
    装备名称: '陶然意·吴陵轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 4556,
    武器伤害_最大值: 7594,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40352,
    图标ID: 20139,
    uid: 40352,
    装备名称: '陶然意·穿心弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.力道, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40348,
    图标ID: 20133,
    uid: 40348,
    装备名称: '陶然意·蜕羽剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 4556,
    武器伤害_最大值: 7594,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.身法, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40346,
    图标ID: 20132,
    uid: 40346,
    装备名称: '陶然意·登锋枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5958,
    武器伤害_最大值: 9930,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.力道, 值: 1128 },
      { 属性: 属性类型.外功基础攻击, 值: 5156 },
      { 属性: 属性类型.外功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40094,
    图标ID: 19665,
    uid: 40094,
    装备名称: '孤山寒月·飞雀',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40093,
    图标ID: 17823,
    uid: 40093,
    装备名称: '孤山寒月·狂沙',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40089,
    图标ID: 12245,
    uid: 40089,
    装备名称: '孤山寒月·血影',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40088,
    图标ID: 11185,
    uid: 40088,
    装备名称: '孤山寒月·琼华',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40087,
    图标ID: 9173,
    uid: 40087,
    装备名称: '孤山寒月·怒峰',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40083,
    图标ID: 9182,
    uid: 40083,
    装备名称: '孤山寒月·旗影',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40080,
    图标ID: 9172,
    uid: 40080,
    装备名称: '孤山寒月·金龙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40078,
    图标ID: 9175,
    uid: 40078,
    装备名称: '孤山寒月·清泉',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40077,
    图标ID: 9174,
    uid: 40077,
    装备名称: '孤山寒月·烈风',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40071,
    图标ID: 9169,
    uid: 40071,
    装备名称: '孤山寒月·云霄',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40068,
    图标ID: 9177,
    uid: 40068,
    装备名称: '孤山寒月·川风',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39140,
    图标ID: 23032,
    uid: 39140,
    装备名称: '金塘轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39138,
    图标ID: 23101,
    uid: 39138,
    装备名称: '银瑰弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39137,
    图标ID: 23037,
    uid: 39137,
    装备名称: '鸿璧刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39133,
    图标ID: 23061,
    uid: 39133,
    装备名称: '宿世链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39132,
    图标ID: 23071,
    uid: 39132,
    装备名称: '鲸度伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39131,
    图标ID: 23012,
    uid: 39131,
    装备名称: '彗云刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39127,
    图标ID: 23017,
    uid: 39127,
    装备名称: '北朔刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39124,
    图标ID: 23056,
    uid: 39124,
    装备名称: '厉霜棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39122,
    图标ID: 23032,
    uid: 39122,
    装备名称: '金塘轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39121,
    图标ID: 23086,
    uid: 39121,
    装备名称: '同照弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39115,
    图标ID: 23022,
    uid: 39115,
    装备名称: '烬鹤剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39112,
    图标ID: 23091,
    uid: 39112,
    装备名称: '尘烽枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39100,
    图标ID: 23032,
    uid: 39100,
    装备名称: '霁野轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39098,
    图标ID: 23101,
    uid: 39098,
    装备名称: '化风弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39097,
    图标ID: 23037,
    uid: 39097,
    装备名称: '连霄刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39093,
    图标ID: 23061,
    uid: 39093,
    装备名称: '伏螭链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39092,
    图标ID: 23071,
    uid: 39092,
    装备名称: '涧风伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39091,
    图标ID: 23012,
    uid: 39091,
    装备名称: '惊衡刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39087,
    图标ID: 23017,
    uid: 39087,
    装备名称: '凌冈刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39084,
    图标ID: 23056,
    uid: 39084,
    装备名称: '沉帆棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39082,
    图标ID: 23032,
    uid: 39082,
    装备名称: '霁野轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39081,
    图标ID: 23086,
    uid: 39081,
    装备名称: '临崖弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39075,
    图标ID: 23022,
    uid: 39075,
    装备名称: '鹭栖剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39072,
    图标ID: 23091,
    uid: 39072,
    装备名称: '澹流枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39060,
    图标ID: 20232,
    uid: 39060,
    装备名称: '寻踪觅宝·鸿渐弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39059,
    图标ID: 17824,
    uid: 39059,
    装备名称: '寻踪觅宝·鳞羽刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39055,
    图标ID: 12366,
    uid: 39055,
    装备名称: '寻踪觅宝·溯世链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39054,
    图标ID: 11184,
    uid: 39054,
    装备名称: '寻踪觅宝·斗浪伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39053,
    图标ID: 8720,
    uid: 39053,
    装备名称: '寻踪觅宝·独步刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39049,
    图标ID: 8764,
    uid: 39049,
    装备名称: '寻踪觅宝·立德刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39046,
    图标ID: 8753,
    uid: 39046,
    装备名称: '寻踪觅宝·矫流棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39044,
    图标ID: 8785,
    uid: 39044,
    装备名称: '寻踪觅宝·灵微剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39043,
    图标ID: 8746,
    uid: 39043,
    装备名称: '寻踪觅宝·逢行弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39037,
    图标ID: 8784,
    uid: 39037,
    装备名称: '寻踪觅宝·春易剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39034,
    图标ID: 8770,
    uid: 39034,
    装备名称: '寻踪觅宝·神宕枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40992,
    图标ID: 20237,
    uid: 40992,
    装备名称: '梧风棉棉钩·刀功',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40991,
    图标ID: 17821,
    uid: 40991,
    装备名称: '岚峰海坚鱼·刀功',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.力道, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40987,
    图标ID: 12599,
    uid: 40987,
    装备名称: '凌晓绿竹剑·刀功',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40986,
    图标ID: 11145,
    uid: 40986,
    装备名称: '沧波渔网架·刀功',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40985,
    图标ID: 8964,
    uid: 40985,
    装备名称: '傲寒绛腊厨装·刀功',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.力道, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40981,
    图标ID: 6736,
    uid: 40981,
    装备名称: '塞雪蒸鱼屉·刀功',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5164,
    武器伤害_最大值: 8607,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40978,
    图标ID: 4828,
    uid: 40978,
    装备名称: '醉梦逍遥棒·刀功',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5853,
    武器伤害_最大值: 9755,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.力道, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40976,
    图标ID: 2230,
    uid: 40976,
    装备名称: '至尊剔骨刀·刀功',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 4476,
    武器伤害_最大值: 7460,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40975,
    图标ID: 3322,
    uid: 40975,
    装备名称: '蜀月竹筒饭·刀功',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.力道, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40969,
    图标ID: 2230,
    uid: 40969,
    装备名称: '灵虚大菜刀·刀功',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 4476,
    武器伤害_最大值: 7460,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.身法, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40966,
    图标ID: 2213,
    uid: 40966,
    装备名称: '御赐大锅铲·刀功',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5853,
    武器伤害_最大值: 9755,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.力道, 值: 1193 },
      { 属性: 属性类型.外功基础攻击, 值: 5968 },
      { 属性: 属性类型.外功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39028,
    图标ID: 20232,
    uid: 39028,
    装备名称: '寻踪觅宝·惊鹊弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39027,
    图标ID: 17634,
    uid: 39027,
    装备名称: '寻踪觅宝·潮周刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39023,
    图标ID: 12369,
    uid: 39023,
    装备名称: '寻踪觅宝·负山链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39022,
    图标ID: 11185,
    uid: 39022,
    装备名称: '寻踪觅宝·异源伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39021,
    图标ID: 8719,
    uid: 39021,
    装备名称: '寻踪觅宝·肃思刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39017,
    图标ID: 6844,
    uid: 39017,
    装备名称: '寻踪觅宝·成渊刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39014,
    图标ID: 6837,
    uid: 39014,
    装备名称: '寻踪觅宝·泛游棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39012,
    图标ID: 6845,
    uid: 39012,
    装备名称: '寻踪觅宝·候岁剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39011,
    图标ID: 6839,
    uid: 39011,
    装备名称: '寻踪觅宝·声流弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39005,
    图标ID: 6843,
    uid: 39005,
    装备名称: '寻踪觅宝·折梅剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39002,
    图标ID: 6840,
    uid: 39002,
    装备名称: '寻踪觅宝·宣光枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38996,
    图标ID: 19665,
    uid: 38996,
    装备名称: '坞壁弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38995,
    图标ID: 17823,
    uid: 38995,
    装备名称: '峻壁刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38991,
    图标ID: 12366,
    uid: 38991,
    装备名称: '九壁链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38990,
    图标ID: 11760,
    uid: 38990,
    装备名称: '向壁伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38989,
    图标ID: 11759,
    uid: 38989,
    装备名称: '坚壁刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38985,
    图标ID: 11755,
    uid: 38985,
    装备名称: '离壁刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38982,
    图标ID: 11758,
    uid: 38982,
    装备名称: '壁牙棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38980,
    图标ID: 11747,
    uid: 38980,
    装备名称: '穿壁轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38979,
    图标ID: 11754,
    uid: 38979,
    装备名称: '列壁弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38973,
    图标ID: 11746,
    uid: 38973,
    装备名称: '壁灵剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38970,
    图标ID: 11749,
    uid: 38970,
    装备名称: '赤壁枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40959,
    图标ID: 20232,
    uid: 40959,
    装备名称: '参目弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40958,
    图标ID: 17823,
    uid: 40958,
    装备名称: '撩风刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40955,
    图标ID: 12366,
    uid: 40955,
    装备名称: '探幽链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40954,
    图标ID: 11760,
    uid: 40954,
    装备名称: '振溪伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40953,
    图标ID: 11759,
    uid: 40953,
    装备名称: '虺雷刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40950,
    图标ID: 11755,
    uid: 40950,
    装备名称: '洪业刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40947,
    图标ID: 11758,
    uid: 40947,
    装备名称: '唤渡棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40945,
    图标ID: 11747,
    uid: 40945,
    装备名称: '甘风剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40944,
    图标ID: 11754,
    uid: 40944,
    装备名称: '悬叶弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40940,
    图标ID: 11746,
    uid: 40940,
    装备名称: '泽水剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40937,
    图标ID: 11749,
    uid: 40937,
    装备名称: '雷渊枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40913,
    图标ID: 20232,
    uid: 40913,
    装备名称: '群云弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40912,
    图标ID: 17823,
    uid: 40912,
    装备名称: '离北刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40908,
    图标ID: 12366,
    uid: 40908,
    装备名称: '鸿飞链刀',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40907,
    图标ID: 11760,
    uid: 40907,
    装备名称: '凝漪伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40906,
    图标ID: 11759,
    uid: 40906,
    装备名称: '峙岳刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40903,
    图标ID: 11755,
    uid: 40903,
    装备名称: '扶山刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40901,
    图标ID: 11758,
    uid: 40901,
    装备名称: '四隅短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40899,
    图标ID: 11747,
    uid: 40899,
    装备名称: '延陵剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40898,
    图标ID: 11754,
    uid: 40898,
    装备名称: '蒙楚弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40892,
    图标ID: 11746,
    uid: 40892,
    装备名称: '织寒剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40890,
    图标ID: 11749,
    uid: 40890,
    装备名称: '烛曜枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40612,
    图标ID: 19665,
    uid: 40612,
    装备名称: '封狐弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40611,
    图标ID: 17823,
    uid: 40611,
    装备名称: '奔涛刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40607,
    图标ID: 12366,
    uid: 40607,
    装备名称: '玄珠链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40606,
    图标ID: 11760,
    uid: 40606,
    装备名称: '澥海伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40605,
    图标ID: 11759,
    uid: 40605,
    装备名称: '垂鸿刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40601,
    图标ID: 11755,
    uid: 40601,
    装备名称: '东合刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40598,
    图标ID: 11758,
    uid: 40598,
    装备名称: '平流短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40596,
    图标ID: 11747,
    uid: 40596,
    装备名称: '明祥剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40595,
    图标ID: 11754,
    uid: 40595,
    装备名称: '玄圃弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40589,
    图标ID: 11746,
    uid: 40589,
    装备名称: '雪濛剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40586,
    图标ID: 11749,
    uid: 40586,
    装备名称: '金狄枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38958,
    图标ID: 20232,
    uid: 38958,
    装备名称: '参目弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38957,
    图标ID: 17823,
    uid: 38957,
    装备名称: '撩风刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38953,
    图标ID: 12366,
    uid: 38953,
    装备名称: '探幽链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38952,
    图标ID: 11760,
    uid: 38952,
    装备名称: '振溪伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38951,
    图标ID: 11759,
    uid: 38951,
    装备名称: '虺雷刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38947,
    图标ID: 11755,
    uid: 38947,
    装备名称: '洪业刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38944,
    图标ID: 11758,
    uid: 38944,
    装备名称: '唤渡棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38942,
    图标ID: 11747,
    uid: 38942,
    装备名称: '甘风剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38941,
    图标ID: 11754,
    uid: 38941,
    装备名称: '悬叶弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38935,
    图标ID: 11746,
    uid: 38935,
    装备名称: '泽水剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38932,
    图标ID: 11749,
    uid: 38932,
    装备名称: '雷渊枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38920,
    图标ID: 19665,
    uid: 38920,
    装备名称: '封狐弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38919,
    图标ID: 17823,
    uid: 38919,
    装备名称: '奔涛刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38915,
    图标ID: 12366,
    uid: 38915,
    装备名称: '玄珠链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38914,
    图标ID: 11760,
    uid: 38914,
    装备名称: '澥海伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38913,
    图标ID: 11759,
    uid: 38913,
    装备名称: '垂鸿刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38909,
    图标ID: 11755,
    uid: 38909,
    装备名称: '东合刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38906,
    图标ID: 11758,
    uid: 38906,
    装备名称: '平流短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38904,
    图标ID: 11747,
    uid: 38904,
    装备名称: '明祥剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38903,
    图标ID: 11754,
    uid: 38903,
    装备名称: '玄圃弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38897,
    图标ID: 11746,
    uid: 38897,
    装备名称: '雪濛剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38894,
    图标ID: 11749,
    uid: 38894,
    装备名称: '金狄枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38882,
    图标ID: 20232,
    uid: 38882,
    装备名称: '群云弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38881,
    图标ID: 17823,
    uid: 38881,
    装备名称: '离北刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38877,
    图标ID: 12366,
    uid: 38877,
    装备名称: '鸿飞链刀',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38876,
    图标ID: 11760,
    uid: 38876,
    装备名称: '凝漪伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38875,
    图标ID: 11759,
    uid: 38875,
    装备名称: '峙岳刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38871,
    图标ID: 11755,
    uid: 38871,
    装备名称: '扶山刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38868,
    图标ID: 11758,
    uid: 38868,
    装备名称: '四隅短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38866,
    图标ID: 11747,
    uid: 38866,
    装备名称: '延陵剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38865,
    图标ID: 11754,
    uid: 38865,
    装备名称: '蒙楚弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38859,
    图标ID: 11746,
    uid: 38859,
    装备名称: '织寒剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38856,
    图标ID: 11749,
    uid: 38856,
    装备名称: '烛曜枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38820,
    图标ID: 19665,
    uid: 38820,
    装备名称: '雪眸弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38819,
    图标ID: 17824,
    uid: 38819,
    装备名称: '枕峰刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38815,
    图标ID: 12245,
    uid: 38815,
    装备名称: '月昼链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38814,
    图标ID: 11182,
    uid: 38814,
    装备名称: '残波伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38813,
    图标ID: 11088,
    uid: 38813,
    装备名称: '寻碧刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38809,
    图标ID: 11084,
    uid: 38809,
    装备名称: '明灭盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38806,
    图标ID: 11077,
    uid: 38806,
    装备名称: '烟重棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38804,
    图标ID: 11085,
    uid: 38804,
    装备名称: '初长轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38803,
    图标ID: 11079,
    uid: 38803,
    装备名称: '零枝弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38797,
    图标ID: 11083,
    uid: 38797,
    装备名称: '烟疏剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38794,
    图标ID: 11080,
    uid: 38794,
    装备名称: '寒犹枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38782,
    图标ID: 19665,
    uid: 38782,
    装备名称: '远青弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38781,
    图标ID: 17824,
    uid: 38781,
    装备名称: '影敛刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38777,
    图标ID: 12245,
    uid: 38777,
    装备名称: '诉苍链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38776,
    图标ID: 11182,
    uid: 38776,
    装备名称: '关玉伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38775,
    图标ID: 11088,
    uid: 38775,
    装备名称: '棹孤刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38771,
    图标ID: 11084,
    uid: 38771,
    装备名称: '影成盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38768,
    图标ID: 11077,
    uid: 38768,
    装备名称: '潮风棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38766,
    图标ID: 11085,
    uid: 38766,
    装备名称: '惜涯轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38765,
    图标ID: 11079,
    uid: 38765,
    装备名称: '断秋弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38759,
    图标ID: 11083,
    uid: 38759,
    装备名称: '思穷剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38756,
    图标ID: 11080,
    uid: 38756,
    装备名称: '雁逆枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 4677 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 39468,
    图标ID: 19665,
    uid: 39468,
    装备名称: '山海弓·丝路',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39467,
    图标ID: 17634,
    uid: 39467,
    装备名称: '孤锋刀·丝路',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.力道, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39463,
    图标ID: 12245,
    uid: 39463,
    装备名称: '无间链刃·丝路',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39462,
    图标ID: 11147,
    uid: 39462,
    装备名称: '逸仙伞·丝路',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39461,
    图标ID: 8822,
    uid: 39461,
    装备名称: '河朔刀·丝路',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.力道, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39457,
    图标ID: 7845,
    uid: 39457,
    装备名称: '斩马刀盾·外·丝路',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 4606,
    武器伤害_最大值: 7677,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39454,
    图标ID: 7862,
    uid: 39454,
    装备名称: '豪义短棒·丝路',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 5220,
    武器伤害_最大值: 8700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.力道, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39452,
    图标ID: 7829,
    uid: 39452,
    装备名称: '君子轻剑·丝路',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3992,
    武器伤害_最大值: 6653,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39451,
    图标ID: 7899,
    uid: 39451,
    装备名称: '千机弩·外·丝路',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.力道, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39445,
    图标ID: 7839,
    uid: 39445,
    装备名称: '太清剑·外·丝路',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3992,
    武器伤害_最大值: 6653,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.身法, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39442,
    图标ID: 7894,
    uid: 39442,
    装备名称: '红缨枪·外·丝路',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 5220,
    武器伤害_最大值: 8700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.力道, 值: 1064 },
      { 属性: 属性类型.外功基础攻击, 值: 5323 },
      { 属性: 属性类型.外功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38744,
    图标ID: 19665,
    uid: 38744,
    装备名称: '影逐弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38743,
    图标ID: 17823,
    uid: 38743,
    装备名称: '孤旅刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.力道, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38739,
    图标ID: 12245,
    uid: 38739,
    装备名称: '雷岳链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38738,
    图标ID: 11147,
    uid: 38738,
    装备名称: '山暮伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38737,
    图标ID: 8822,
    uid: 38737,
    装备名称: '刃秋刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.力道, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38733,
    图标ID: 7845,
    uid: 38733,
    装备名称: '锁夜盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4373,
    武器伤害_最大值: 7288,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38730,
    图标ID: 7862,
    uid: 38730,
    装备名称: '峰色棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4956,
    武器伤害_最大值: 8260,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.力道, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38728,
    图标ID: 7829,
    uid: 38728,
    装备名称: '问梅轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 3790,
    武器伤害_最大值: 6317,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38727,
    图标ID: 7899,
    uid: 38727,
    装备名称: '岚封弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.力道, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38721,
    图标ID: 7839,
    uid: 38721,
    装备名称: '玄岑剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 3790,
    武器伤害_最大值: 6317,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.身法, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38718,
    图标ID: 7894,
    uid: 38718,
    装备名称: '惜竹枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4956,
    武器伤害_最大值: 8260,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.力道, 值: 1010 },
      { 属性: 属性类型.外功基础攻击, 值: 5054 },
      { 属性: 属性类型.外功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38706,
    图标ID: 19665,
    uid: 38706,
    装备名称: '玄羽弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38705,
    图标ID: 17634,
    uid: 38705,
    装备名称: '紫珑刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.力道, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38701,
    图标ID: 12367,
    uid: 38701,
    装备名称: '茈芳链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38700,
    图标ID: 11147,
    uid: 38700,
    装备名称: '碧澜伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38699,
    图标ID: 8822,
    uid: 38699,
    装备名称: '孤鸿刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.力道, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38695,
    图标ID: 7845,
    uid: 38695,
    装备名称: '樵隐盾刀',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4094,
    武器伤害_最大值: 6823,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38692,
    图标ID: 7862,
    uid: 38692,
    装备名称: '绮思棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4640,
    武器伤害_最大值: 7733,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.力道, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38690,
    图标ID: 7829,
    uid: 38690,
    装备名称: '襄凝轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 3548,
    武器伤害_最大值: 5913,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38689,
    图标ID: 7899,
    uid: 38689,
    装备名称: '寂筠弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.力道, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38683,
    图标ID: 7839,
    uid: 38683,
    装备名称: '锦墨剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 3548,
    武器伤害_最大值: 5913,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.身法, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38680,
    图标ID: 7894,
    uid: 38680,
    装备名称: '晏然枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4640,
    武器伤害_最大值: 7733,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.力道, 值: 946 },
      { 属性: 属性类型.外功基础攻击, 值: 4731 },
      { 属性: 属性类型.外功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39506,
    图标ID: 19665,
    uid: 39506,
    装备名称: '杳深弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39505,
    图标ID: 17634,
    uid: 39505,
    装备名称: '秋晨刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39501,
    图标ID: 12245,
    uid: 39501,
    装备名称: '暮笳链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39500,
    图标ID: 11147,
    uid: 39500,
    装备名称: '飞潮伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39499,
    图标ID: 8822,
    uid: 39499,
    装备名称: '重英刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39495,
    图标ID: 7845,
    uid: 39495,
    装备名称: '负海刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39492,
    图标ID: 7862,
    uid: 39492,
    装备名称: '酣欢短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39490,
    图标ID: 7829,
    uid: 39490,
    装备名称: '飞客轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3326,
    武器伤害_最大值: 5544,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39489,
    图标ID: 7899,
    uid: 39489,
    装备名称: '游目弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39483,
    图标ID: 7839,
    uid: 39483,
    装备名称: '凄月剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3326,
    武器伤害_最大值: 5544,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39480,
    图标ID: 7894,
    uid: 39480,
    装备名称: '赤野枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 887 },
      { 属性: 属性类型.外功基础攻击, 值: 4435 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38668,
    图标ID: 19665,
    uid: 38668,
    装备名称: '珺琛弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38667,
    图标ID: 17634,
    uid: 38667,
    装备名称: '洵美刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38663,
    图标ID: 12245,
    uid: 38663,
    装备名称: '冥觞链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38662,
    图标ID: 11147,
    uid: 38662,
    装备名称: '漱烟伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38661,
    图标ID: 8824,
    uid: 38661,
    装备名称: '镂月刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38657,
    图标ID: 7849,
    uid: 38657,
    装备名称: '绡弘刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3838,
    武器伤害_最大值: 6397,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38654,
    图标ID: 7866,
    uid: 38654,
    装备名称: '蔻岚短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38652,
    图标ID: 7833,
    uid: 38652,
    装备名称: '沐岚剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3326,
    武器伤害_最大值: 5544,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38651,
    图标ID: 7903,
    uid: 38651,
    装备名称: '静淅弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38645,
    图标ID: 7844,
    uid: 38645,
    装备名称: '晗曦剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 3326,
    武器伤害_最大值: 5544,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.身法, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38642,
    图标ID: 7898,
    uid: 38642,
    装备名称: '璆鸣枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.力道, 值: 824 },
      { 属性: 属性类型.外功基础攻击, 值: 3764 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
]

export default 武器装备数据
