import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 鞋子装备数据: 装备属性信息模型[] = [
  {
    id: 103808,
    图标ID: 22086,
    uid: 103808,
    装备名称: '陶然意·拥狸靴',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103807,
    图标ID: 22086,
    uid: 103807,
    装备名称: '陶然意·万仞靴',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103804,
    图标ID: 22086,
    uid: 103804,
    装备名称: '陶然意·徇节靴',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103803,
    图标ID: 22086,
    uid: 103803,
    装备名称: '陶然意·临溟靴',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103802,
    图标ID: 22086,
    uid: 103802,
    装备名称: '陶然意·上造靴',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103800,
    图标ID: 22086,
    uid: 103800,
    装备名称: '陶然意·征惯靴',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103798,
    图标ID: 22086,
    uid: 103798,
    装备名称: '陶然意·春瓮靴',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103797,
    图标ID: 22086,
    uid: 103797,
    装备名称: '陶然意·吴陵靴',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103796,
    图标ID: 22086,
    uid: 103796,
    装备名称: '陶然意·穿心靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103792,
    图标ID: 22086,
    uid: 103792,
    装备名称: '陶然意·蜕羽靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103790,
    图标ID: 22086,
    uid: 103790,
    装备名称: '陶然意·登锋靴',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101605,
    图标ID: 23152,
    uid: 101605,
    装备名称: '花永靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.破招值, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101604,
    图标ID: 23152,
    uid: 101604,
    装备名称: '花世靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.破招值, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101539,
    图标ID: 23146,
    uid: 101539,
    装备名称: '羡雁靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101538,
    图标ID: 23146,
    uid: 101538,
    装备名称: '君深靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101503,
    图标ID: 23146,
    uid: 101503,
    装备名称: '留诗靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101502,
    图标ID: 23146,
    uid: 101502,
    装备名称: '清月靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101424,
    图标ID: 23261,
    uid: 101424,
    装备名称: '孤漠·阑影靴',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101423,
    图标ID: 23189,
    uid: 101423,
    装备名称: '孤漠·苍雨履',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101419,
    图标ID: 23213,
    uid: 101419,
    装备名称: '孤漠·狂澜靴',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101418,
    图标ID: 23225,
    uid: 101418,
    装备名称: '孤漠·舟帆靴',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101417,
    图标ID: 23164,
    uid: 101417,
    装备名称: '孤漠·庭月靴',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101413,
    图标ID: 23170,
    uid: 101413,
    装备名称: '孤漠·雁际靴',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101410,
    图标ID: 23207,
    uid: 101410,
    装备名称: '孤漠·泛浪靴',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101409,
    图标ID: 23176,
    uid: 101409,
    装备名称: '孤漠·寒鸣靴',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101408,
    图标ID: 23243,
    uid: 101408,
    装备名称: '孤漠·照江靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101402,
    图标ID: 23182,
    uid: 101402,
    装备名称: '孤漠·立霄靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101399,
    图标ID: 23249,
    uid: 101399,
    装备名称: '孤漠·踏沙靴',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101252,
    图标ID: 9689,
    uid: 101252,
    装备名称: '漠名靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101251,
    图标ID: 9689,
    uid: 101251,
    装备名称: '漠若靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104453,
    图标ID: 9245,
    uid: 104453,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 3510 },
      { 属性: 属性类型.外功会心等级, 值: 4454 },
      { 属性: 属性类型.破招值, 值: 4454 },
      { 属性: 属性类型.无双等级, 值: 4454 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104452,
    图标ID: 9245,
    uid: 104452,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 2905 },
      { 属性: 属性类型.外功破防等级, 值: 4900 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104451,
    图标ID: 9245,
    uid: 104451,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 4116 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101829,
    图标ID: 9245,
    uid: 101829,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 3510 },
      { 属性: 属性类型.外功会心等级, 值: 4454 },
      { 属性: 属性类型.破招值, 值: 4454 },
      { 属性: 属性类型.无双等级, 值: 4454 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101828,
    图标ID: 9245,
    uid: 101828,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 2905 },
      { 属性: 属性类型.外功破防等级, 值: 4900 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101827,
    图标ID: 9245,
    uid: 101827,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9800 },
      { 属性: 属性类型.外功基础攻击, 值: 4116 },
      { 属性: 属性类型.无双等级, 值: 9800 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104441,
    图标ID: 9245,
    uid: 104441,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 3373 },
      { 属性: 属性类型.外功破防等级, 值: 4280 },
      { 属性: 属性类型.破招值, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104440,
    图标ID: 9245,
    uid: 104440,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 3373 },
      { 属性: 属性类型.外功会心等级, 值: 4280 },
      { 属性: 属性类型.外功破防等级, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104439,
    图标ID: 9245,
    uid: 104439,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 2791 },
      { 属性: 属性类型.外功会心等级, 值: 9843 },
      { 属性: 属性类型.无双等级, 值: 5136 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101805,
    图标ID: 9245,
    uid: 101805,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 3373 },
      { 属性: 属性类型.外功破防等级, 值: 4280 },
      { 属性: 属性类型.破招值, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101804,
    图标ID: 9245,
    uid: 101804,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 3373 },
      { 属性: 属性类型.外功会心等级, 值: 4280 },
      { 属性: 属性类型.外功破防等级, 值: 4280 },
      { 属性: 属性类型.无双等级, 值: 4280 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101803,
    图标ID: 9245,
    uid: 101803,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简鞋子会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9415 },
      { 属性: 属性类型.外功基础攻击, 值: 2791 },
      { 属性: 属性类型.外功会心等级, 值: 9843 },
      { 属性: 属性类型.无双等级, 值: 5136 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104429,
    图标ID: 9245,
    uid: 104429,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.外功会心等级, 值: 6158 },
      { 属性: 属性类型.外功会心效果等级, 值: 3079 },
      { 属性: 属性类型.无双等级, 值: 3079 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104428,
    图标ID: 9245,
    uid: 104428,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 2231 },
      { 属性: 属性类型.破招值, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 6158 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104427,
    图标ID: 9245,
    uid: 104427,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 2677 },
      { 属性: 属性类型.外功破防等级, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 4926 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101781,
    图标ID: 9245,
    uid: 101781,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.外功会心等级, 值: 6158 },
      { 属性: 属性类型.外功会心效果等级, 值: 3079 },
      { 属性: 属性类型.无双等级, 值: 3079 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101780,
    图标ID: 9245,
    uid: 101780,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 2231 },
      { 属性: 属性类型.破招值, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 6158 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101779,
    图标ID: 9245,
    uid: 101779,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9031 },
      { 属性: 属性类型.外功基础攻击, 值: 2677 },
      { 属性: 属性类型.外功破防等级, 值: 9442 },
      { 属性: 属性类型.无双等级, 值: 4926 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 103646,
    图标ID: 22086,
    uid: 103646,
    装备名称: '陶然意·拥狸靴',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103645,
    图标ID: 22086,
    uid: 103645,
    装备名称: '陶然意·万仞靴',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103642,
    图标ID: 22086,
    uid: 103642,
    装备名称: '陶然意·徇节靴',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103641,
    图标ID: 22086,
    uid: 103641,
    装备名称: '陶然意·临溟靴',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103640,
    图标ID: 22086,
    uid: 103640,
    装备名称: '陶然意·上造靴',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103638,
    图标ID: 22086,
    uid: 103638,
    装备名称: '陶然意·征惯靴',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103636,
    图标ID: 22086,
    uid: 103636,
    装备名称: '陶然意·春瓮靴',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103635,
    图标ID: 22086,
    uid: 103635,
    装备名称: '陶然意·吴陵靴',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103634,
    图标ID: 22086,
    uid: 103634,
    装备名称: '陶然意·穿心靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103630,
    图标ID: 22086,
    uid: 103630,
    装备名称: '陶然意·蜕羽靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103628,
    图标ID: 22086,
    uid: 103628,
    装备名称: '陶然意·登锋靴',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104417,
    图标ID: 9245,
    uid: 104417,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 3097 },
      { 属性: 属性类型.外功会心等级, 值: 3930 },
      { 属性: 属性类型.破招值, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3930 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104416,
    图标ID: 9245,
    uid: 104416,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 2563 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104415,
    图标ID: 9245,
    uid: 104415,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103501,
    图标ID: 6551,
    uid: 103501,
    装备名称: '孤山寒月·逐刃靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103500,
    图标ID: 6551,
    uid: 103500,
    装备名称: '孤山寒月·归期靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101757,
    图标ID: 9245,
    uid: 101757,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 3097 },
      { 属性: 属性类型.外功会心等级, 值: 3930 },
      { 属性: 属性类型.破招值, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3930 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101756,
    图标ID: 9245,
    uid: 101756,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 2563 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101755,
    图标ID: 9245,
    uid: 101755,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.外功基础攻击, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 8647 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101210,
    图标ID: 23146,
    uid: 101210,
    装备名称: '扬明靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101209,
    图标ID: 23146,
    uid: 101209,
    装备名称: '重悬靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101174,
    图标ID: 23146,
    uid: 101174,
    装备名称: '静言靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101173,
    图标ID: 23146,
    uid: 101173,
    装备名称: '棣仪靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101095,
    图标ID: 23261,
    uid: 101095,
    装备名称: '西塞·离巢靴',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101094,
    图标ID: 23189,
    uid: 101094,
    装备名称: '西塞·秋星靴',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101090,
    图标ID: 23213,
    uid: 101090,
    装备名称: '西塞·麟台靴',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101089,
    图标ID: 23225,
    uid: 101089,
    装备名称: '西塞·川冥靴',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101088,
    图标ID: 23164,
    uid: 101088,
    装备名称: '西塞·流电靴',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101084,
    图标ID: 23170,
    uid: 101084,
    装备名称: '西塞·行歌靴',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101081,
    图标ID: 23207,
    uid: 101081,
    装备名称: '西塞·平塘靴',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101080,
    图标ID: 23176,
    uid: 101080,
    装备名称: '西塞·赤眉靴',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101079,
    图标ID: 23243,
    uid: 101079,
    装备名称: '西塞·江鸥靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101073,
    图标ID: 23182,
    uid: 101073,
    装备名称: '西塞·冷辉履',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101070,
    图标ID: 23249,
    uid: 101070,
    装备名称: '西塞·壮志履',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100989,
    图标ID: 8893,
    uid: 100989,
    装备名称: '寻踪觅宝·方思靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100988,
    图标ID: 8893,
    uid: 100988,
    装备名称: '寻踪觅宝·汉广靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104405,
    图标ID: 9245,
    uid: 104405,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2974 },
      { 属性: 属性类型.外功破防等级, 值: 3773 },
      { 属性: 属性类型.破招值, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104404,
    图标ID: 9245,
    uid: 104404,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2974 },
      { 属性: 属性类型.外功会心等级, 值: 3773 },
      { 属性: 属性类型.外功破防等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104403,
    图标ID: 9245,
    uid: 104403,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2461 },
      { 属性: 属性类型.外功会心等级, 值: 8678 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101733,
    图标ID: 9245,
    uid: 101733,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2974 },
      { 属性: 属性类型.外功破防等级, 值: 3773 },
      { 属性: 属性类型.破招值, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101732,
    图标ID: 9245,
    uid: 101732,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2974 },
      { 属性: 属性类型.外功会心等级, 值: 3773 },
      { 属性: 属性类型.外功破防等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101731,
    图标ID: 9245,
    uid: 101731,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.外功基础攻击, 值: 2461 },
      { 属性: 属性类型.外功会心等级, 值: 8678 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100959,
    图标ID: 7991,
    uid: 100959,
    装备名称: '寻踪觅宝·迭微靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100958,
    图标ID: 7991,
    uid: 100958,
    装备名称: '寻踪觅宝·予吉靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100923,
    图标ID: 11603,
    uid: 100923,
    装备名称: '壁明靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100922,
    图标ID: 11603,
    uid: 100922,
    装备名称: '壁邺靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104393,
    图标ID: 9245,
    uid: 104393,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 2850 },
      { 属性: 属性类型.外功会心等级, 值: 5424 },
      { 属性: 属性类型.外功会心效果等级, 值: 2712 },
      { 属性: 属性类型.无双等级, 值: 2712 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104392,
    图标ID: 9245,
    uid: 104392,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 1965 },
      { 属性: 属性类型.破招值, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 5424 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104391,
    图标ID: 9245,
    uid: 104391,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 2358 },
      { 属性: 属性类型.外功破防等级, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101709,
    图标ID: 9245,
    uid: 101709,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 2850 },
      { 属性: 属性类型.外功会心等级, 值: 5424 },
      { 属性: 属性类型.外功会心效果等级, 值: 2712 },
      { 属性: 属性类型.无双等级, 值: 2712 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101708,
    图标ID: 9245,
    uid: 101708,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 1965 },
      { 属性: 属性类型.破招值, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 5424 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101707,
    图标ID: 9245,
    uid: 101707,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.外功基础攻击, 值: 2358 },
      { 属性: 属性类型.外功破防等级, 值: 8317 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100797,
    图标ID: 5886,
    uid: 100797,
    装备名称: '语心鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.身法, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100796,
    图标ID: 5886,
    uid: 100796,
    装备名称: '语欣鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.力道, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104514,
    图标ID: 11603,
    uid: 104514,
    装备名称: '润源靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104513,
    图标ID: 11603,
    uid: 104513,
    装备名称: '辞羁靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104482,
    图标ID: 11603,
    uid: 104482,
    装备名称: '平皋靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104481,
    图标ID: 11603,
    uid: 104481,
    装备名称: '淮沛靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104126,
    图标ID: 11603,
    uid: 104126,
    装备名称: '羽缀靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104125,
    图标ID: 11603,
    uid: 104125,
    装备名称: '夏凌靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100893,
    图标ID: 11603,
    uid: 100893,
    装备名称: '润源靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100892,
    图标ID: 11603,
    uid: 100892,
    装备名称: '辞羁靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100857,
    图标ID: 11603,
    uid: 100857,
    装备名称: '平皋靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100856,
    图标ID: 11603,
    uid: 100856,
    装备名称: '淮沛靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100821,
    图标ID: 11603,
    uid: 100821,
    装备名称: '羽缀靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100820,
    图标ID: 11603,
    uid: 100820,
    装备名称: '夏凌靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100689,
    图标ID: 11046,
    uid: 100689,
    装备名称: '镂云靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100688,
    图标ID: 11046,
    uid: 100688,
    装备名称: '炎翳靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100653,
    图标ID: 11046,
    uid: 100653,
    装备名称: '智渊履',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100652,
    图标ID: 11046,
    uid: 100652,
    装备名称: '韶曦履',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104381,
    图标ID: 9245,
    uid: 104381,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 2726 },
      { 属性: 属性类型.外功会心等级, 值: 3459 },
      { 属性: 属性类型.破招值, 值: 3459 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104380,
    图标ID: 9245,
    uid: 104380,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 2256 },
      { 属性: 属性类型.外功破防等级, 值: 3805 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104379,
    图标ID: 9245,
    uid: 104379,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 3196 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101685,
    图标ID: 9245,
    uid: 101685,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 2726 },
      { 属性: 属性类型.外功会心等级, 值: 3459 },
      { 属性: 属性类型.破招值, 值: 3459 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101684,
    图标ID: 9245,
    uid: 101684,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 2256 },
      { 属性: 属性类型.外功破防等级, 值: 3805 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101683,
    图标ID: 9245,
    uid: 101683,
    装备名称: '无修鞋·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7609 },
      { 属性: 属性类型.外功基础攻击, 值: 3196 },
      { 属性: 属性类型.无双等级, 值: 7609 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100773,
    图标ID: 5886,
    uid: 100773,
    装备名称: '语心鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.身法, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.破招值, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100772,
    图标ID: 5886,
    uid: 100772,
    装备名称: '语欣鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.力道, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.破招值, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100617,
    图标ID: 5886,
    uid: 100617,
    装备名称: '修篁鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.身法, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.破招值, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100616,
    图标ID: 5886,
    uid: 100616,
    装备名称: '绮陌鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.力道, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.破招值, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100749,
    图标ID: 5886,
    uid: 100749,
    装备名称: '语心鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.身法, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.破招值, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100748,
    图标ID: 5886,
    uid: 100748,
    装备名称: '语欣鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.力道, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.破招值, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100581,
    图标ID: 6825,
    uid: 100581,
    装备名称: '涵煦靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100580,
    图标ID: 6825,
    uid: 100580,
    装备名称: '惜霜靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100725,
    图标ID: 5886,
    uid: 100725,
    装备名称: '语心鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.身法, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.破招值, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100724,
    图标ID: 5886,
    uid: 100724,
    装备名称: '语欣鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.力道, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.破招值, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101845,
    图标ID: 5886,
    uid: 101845,
    装备名称: '春齐鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功会心等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101844,
    图标ID: 5886,
    uid: 101844,
    装备名称: '曲汜鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功会心等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100545,
    图标ID: 17934,
    uid: 100545,
    装备名称: '嵘嶂鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100544,
    图标ID: 17934,
    uid: 100544,
    装备名称: '湛影鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
]

export default 鞋子装备数据
