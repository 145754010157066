// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GxOuazLEw8WZGnF1L5S1 {
  position: absolute;
  right: 32px;
  top: 2px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: right;
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/版本区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACF","sourcesContent":[".versionWrap {\n  position: absolute;\n  right: 32px;\n  top: 2px;\n  line-height: 24px;\n  font-size: 12px;\n  font-weight: 400;\n  color: #fff;\n  text-align: right;\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionWrap": `GxOuazLEw8WZGnF1L5S1`
};
export default ___CSS_LOADER_EXPORT___;
