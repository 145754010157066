import React, { useContext, useState } from 'react'
import { RightCircleOutlined } from '@ant-design/icons'
import { useSpring, animated } from '@react-spring/web'
import TextReveal from '../../通用组件/文字渐显'
import ReportContext from '../../context'
import styles from './index.module.less'

const contentPosition = [0, -48, -72]

function Step1() {
  const { nextStep } = useContext(ReportContext)
  const [action, setAction] = useState<number>(0)

  const contentProps = useSpring({
    transform: `translateY(${contentPosition[action]}px)`,
    delay: 1000,
    config: { duration: 500 },
  })

  const text1props = useSpring({
    opacity: action > 0 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const text2props = useSpring({
    opacity: action > 1 ? 1 : 0,
    delay: 1500,
    config: { duration: 700 },
    onRest: () => next(),
  })

  const next = () => {
    setAction(action + 1)
  }

  const toNextStep = () => {
    if (action > 1) {
      nextStep()
    }
  }

  return (
    <div className={`${styles.wrap} ${action > 1 ? styles.next : ''}`} onClick={toNextStep}>
      <animated.div style={contentProps}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <TextReveal
              text={'2024年5月16日，《在线配装计算器》正式上线！'}
              callback={() => next()}
            />
          </h1>
          {/* {action > 0 ? ( */}
          <animated.div style={text1props}>
            <h1 className={styles.subTitle}>
              截止2025年1月1日，
              <span>
                共接入旗舰端心法
                <span className={styles.import}>11</span>个、
              </span>
              <span>
                无界心法
                <span className={styles.import}>5</span>个。
              </span>
            </h1>
          </animated.div>
          <animated.div style={text2props}>
            {/* ) : null} */}
            <h1 className={styles.subTitle} style={{ margin: 0 }}>
              还记得你的心法是什么时候接入的吗？
              <RightCircleOutlined />
            </h1>
          </animated.div>
        </div>
      </animated.div>
    </div>
  )
}

export default Step1
