export default {
  "装备基础属性": {
    "基础攻击": 39172,
    "破防等级": 33323,
    "无双等级": 78386,
    "会心等级": 27316,
    "会心效果等级": 3759,
    "全能等级": 0,
    "武器伤害_最小值": 1750,
    "武器伤害_最大值": 2917,
    "破招值": 50051,
    "力道": 44,
    "元气": 44,
    "身法": 44,
    "根骨": 8443,
    "体质": 127927,
    "基础气血上限": 160878,
    "额外气血上限": 0,
    "加速等级": 2921,
    "装分": 471032
  },
  "装备镶嵌附魔属性": {
    "基础攻击": 7907,
    "破防等级": 6259,
    "无双等级": 7094,
    "会心等级": 3336,
    "会心效果等级": 834,
    "全能等级": 0,
    "武器伤害_最小值": 0,
    "武器伤害_最大值": 0,
    "破招值": 834,
    "力道": 44,
    "元气": 44,
    "身法": 44,
    "根骨": 256,
    "体质": 1593,
    "基础气血上限": 160878,
    "额外气血上限": 0,
    "加速等级": 0
  },
  "装备列表": [
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atSpiritBase",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atMagicOvercome",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 101432,
      "装备部位": "帽子"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicCriticalStrike",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atMagicCriticalDamagePowerBase",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 101463,
      "装备部位": "衣服",
      "附魔": "无双+624"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atAllTypeCriticalStrike",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atStrainBase",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 104333,
      "装备部位": "腰带",
      "附魔": "无双+624"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicOvercome",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atSurplusValueBase",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 101010,
      "装备部位": "护腕",
      "附魔": "无双+2089"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atAllTypeCriticalStrike",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atStrainBase",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 104337,
      "装备部位": "下装",
      "附魔": "无双+2089"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicAttackPowerBase",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atMagicOvercome",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 101072,
      "装备部位": "鞋子"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicAttackPowerBase",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 41411,
      "装备部位": "项链",
      "附魔": "体质+774"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicOvercome",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 41361,
      "装备部位": "腰坠",
      "附魔": "体质+774"
    },
    {
      "镶嵌孔数组": [],
      "当前精炼等级": 6,
      "id": 41325,
      "装备部位": "戒指",
      "附魔": "攻击+633"
    },
    {
      "镶嵌孔数组": [],
      "当前精炼等级": 6,
      "id": 41325,
      "装备部位": "戒指"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicCriticalStrike",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 6,
      "id": 39286,
      "装备部位": "暗器",
      "附魔": "破防+2089"
    },
    {
      "镶嵌孔数组": [
        {
          "镶嵌类型": "atMagicAttackPowerBase",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atSpiritBase",
          "镶嵌宝石等级": 8
        },
        {
          "镶嵌类型": "atMagicOvercome",
          "镶嵌宝石等级": 8
        }
      ],
      "当前精炼等级": 8,
      "id": 38053,
      "装备部位": "武器"
    }
  ],
  "五彩石": "彩·激流·月华·无双(陆)",
  "装备增益": {
    "大附魔_伤帽": 2,
    "大附魔_伤衣": 2,
    "大附魔_伤腰": 0,
    "大附魔_伤腕": 1,
    "大附魔_伤鞋": 1,
    "套装会心会效": 1,
    "套装技能": 1,
    "风特效腰坠": 2,
    "大橙武特效": 99
  }
}
