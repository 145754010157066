import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 下装装备数据: 装备属性信息模型[] = [
  {
    id: 103831,
    图标ID: 22085,
    uid: 103831,
    装备名称: '陶然意·月容裤',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103828,
    图标ID: 22085,
    uid: 103828,
    装备名称: '陶然意·修茂裤',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103827,
    图标ID: 22085,
    uid: 103827,
    装备名称: '陶然意·理乾裤',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103823,
    图标ID: 22085,
    uid: 103823,
    装备名称: '陶然意·来仪裤',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103821,
    图标ID: 22085,
    uid: 103821,
    装备名称: '陶然意·凡圣裤',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103817,
    图标ID: 22085,
    uid: 103817,
    装备名称: '陶然意·连筠裤',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103816,
    图标ID: 22085,
    uid: 103816,
    装备名称: '陶然意·蛇言裤',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103815,
    图标ID: 22085,
    uid: 103815,
    装备名称: '陶然意·寒河裤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103813,
    图标ID: 22085,
    uid: 103813,
    装备名称: '陶然意·清静裤',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103811,
    图标ID: 22085,
    uid: 103811,
    装备名称: '陶然意·簇锦裤',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103810,
    图标ID: 22085,
    uid: 103810,
    装备名称: '陶然意·祇树裤',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101609,
    图标ID: 23151,
    uid: 101609,
    装备名称: '花念裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1178 },
      { 属性: 属性类型.内功基础攻击, 值: 2975 },
      { 属性: 属性类型.全会心等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101608,
    图标ID: 23151,
    uid: 101608,
    装备名称: '花深裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1178 },
      { 属性: 属性类型.内功基础攻击, 值: 2975 },
      { 属性: 属性类型.内功会心等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104337,
    图标ID: 23145,
    uid: 104337,
    装备名称: '喻声裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.内功基础攻击, 值: 3906 },
      { 属性: 属性类型.破招值, 值: 14865 },
      { 属性: 属性类型.无双等级, 值: 9695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101651,
    图标ID: 23145,
    uid: 101651,
    装备名称: '客行江湖·散秋下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101650,
    图标ID: 23145,
    uid: 101650,
    装备名称: '客行江湖·馀散下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101585,
    图标ID: 23145,
    uid: 101585,
    装备名称: '然犀裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.内功基础攻击, 值: 4687 },
      { 属性: 属性类型.内功破防等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101584,
    图标ID: 23145,
    uid: 101584,
    装备名称: '尺书裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.内功基础攻击, 值: 6250 },
      { 属性: 属性类型.加速等级, 值: 17451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101583,
    图标ID: 23145,
    uid: 101583,
    装备名称: '鸣驺裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.内功基础攻击, 值: 5664 },
      { 属性: 属性类型.内功破防等级, 值: 6463 },
      { 属性: 属性类型.破招值, 值: 6463 },
      { 属性: 属性类型.无双等级, 值: 6463 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101582,
    图标ID: 23145,
    uid: 101582,
    装备名称: '宜春裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.内功基础攻击, 值: 4687 },
      { 属性: 属性类型.全会心等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101561,
    图标ID: 23145,
    uid: 101561,
    装备名称: '清槐裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101560,
    图标ID: 23145,
    uid: 101560,
    装备名称: '孟春裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101543,
    图标ID: 23145,
    uid: 101543,
    装备名称: '青桑裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.全会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101542,
    图标ID: 23145,
    uid: 101542,
    装备名称: '季春裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101507,
    图标ID: 23145,
    uid: 101507,
    装备名称: '游山下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101506,
    图标ID: 23145,
    uid: 101506,
    装备名称: '思玉下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101256,
    图标ID: 9688,
    uid: 101256,
    装备名称: '漠晖裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101255,
    图标ID: 9688,
    uid: 101255,
    装备名称: '漠安裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 103669,
    图标ID: 22085,
    uid: 103669,
    装备名称: '陶然意·月容裤',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103666,
    图标ID: 22085,
    uid: 103666,
    装备名称: '陶然意·修茂裤',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103665,
    图标ID: 22085,
    uid: 103665,
    装备名称: '陶然意·理乾裤',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103661,
    图标ID: 22085,
    uid: 103661,
    装备名称: '陶然意·来仪裤',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103659,
    图标ID: 22085,
    uid: 103659,
    装备名称: '陶然意·凡圣裤',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103655,
    图标ID: 22085,
    uid: 103655,
    装备名称: '陶然意·连筠裤',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103654,
    图标ID: 22085,
    uid: 103654,
    装备名称: '陶然意·蛇言裤',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103653,
    图标ID: 22085,
    uid: 103653,
    装备名称: '陶然意·寒河裤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103651,
    图标ID: 22085,
    uid: 103651,
    装备名称: '陶然意·清静裤',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103649,
    图标ID: 22085,
    uid: 103649,
    装备名称: '陶然意·簇锦裤',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103648,
    图标ID: 22085,
    uid: 103648,
    装备名称: '陶然意·祇树裤',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103505,
    图标ID: 6479,
    uid: 103505,
    装备名称: '孤山寒月·忧生裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 103504,
    图标ID: 6479,
    uid: 103504,
    装备名称: '孤山寒月·风吟裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101633,
    图标ID: 23145,
    uid: 101633,
    装备名称: '客行江湖·自吟裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101632,
    图标ID: 23145,
    uid: 101632,
    装备名称: '客行江湖·含虚裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101232,
    图标ID: 23145,
    uid: 101232,
    装备名称: '疏风裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101231,
    图标ID: 23145,
    uid: 101231,
    装备名称: '锦官裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101214,
    图标ID: 23145,
    uid: 101214,
    装备名称: '映宵裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.全会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101213,
    图标ID: 23145,
    uid: 101213,
    装备名称: '云眷裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101178,
    图标ID: 23145,
    uid: 101178,
    装备名称: '江汜下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101177,
    图标ID: 23145,
    uid: 101177,
    装备名称: '合苏下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100927,
    图标ID: 11602,
    uid: 100927,
    装备名称: '壁镜裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.元气, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.全会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100926,
    图标ID: 11602,
    uid: 100926,
    装备名称: '壁观裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.根骨, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.内功会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104518,
    图标ID: 11602,
    uid: 104518,
    装备名称: '昭文裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.全会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104517,
    图标ID: 11602,
    uid: 104517,
    装备名称: '丘墟裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104486,
    图标ID: 11602,
    uid: 104486,
    装备名称: '玉凉裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 104485,
    图标ID: 11602,
    uid: 104485,
    装备名称: '樛枝裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 104130,
    图标ID: 11602,
    uid: 104130,
    装备名称: '斛尘裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104129,
    图标ID: 11602,
    uid: 104129,
    装备名称: '熙春裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100897,
    图标ID: 11602,
    uid: 100897,
    装备名称: '昭文裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.全会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100896,
    图标ID: 11602,
    uid: 100896,
    装备名称: '丘墟裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100861,
    图标ID: 11602,
    uid: 100861,
    装备名称: '玉凉裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100860,
    图标ID: 11602,
    uid: 100860,
    装备名称: '樛枝裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100825,
    图标ID: 11602,
    uid: 100825,
    装备名称: '斛尘裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100824,
    图标ID: 11602,
    uid: 100824,
    装备名称: '熙春裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100693,
    图标ID: 11044,
    uid: 100693,
    装备名称: '弥弦裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100692,
    图标ID: 11044,
    uid: 100692,
    装备名称: '珀音裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100657,
    图标ID: 11044,
    uid: 100657,
    装备名称: '霖瑜裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100656,
    图标ID: 11044,
    uid: 100656,
    装备名称: '瑾辞裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100621,
    图标ID: 5885,
    uid: 100621,
    装备名称: '惜晗裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.元气, 值: 842 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.全会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100620,
    图标ID: 5885,
    uid: 100620,
    装备名称: '濯缨裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.根骨, 值: 842 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.内功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100585,
    图标ID: 6824,
    uid: 100585,
    装备名称: '绢素裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.元气, 值: 788 },
      { 属性: 属性类型.内功基础攻击, 值: 1991 },
      { 属性: 属性类型.内功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100584,
    图标ID: 6824,
    uid: 100584,
    装备名称: '烨霖裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.根骨, 值: 788 },
      { 属性: 属性类型.内功基础攻击, 值: 1991 },
      { 属性: 属性类型.内功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101849,
    图标ID: 5885,
    uid: 101849,
    装备名称: '轻桡裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.元气, 值: 739 },
      { 属性: 属性类型.内功基础攻击, 值: 1866 },
      { 属性: 属性类型.全会心等级, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101848,
    图标ID: 5885,
    uid: 101848,
    装备名称: '临流裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.根骨, 值: 739 },
      { 属性: 属性类型.内功基础攻击, 值: 1866 },
      { 属性: 属性类型.内功会心等级, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100549,
    图标ID: 17931,
    uid: 100549,
    装备名称: '凝玥裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.元气, 值: 686 },
      { 属性: 属性类型.内功基础攻击, 值: 1244 },
      { 属性: 属性类型.内功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100548,
    图标ID: 17931,
    uid: 100548,
    装备名称: '静漪裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.根骨, 值: 686 },
      { 属性: 属性类型.内功基础攻击, 值: 1244 },
      { 属性: 属性类型.内功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
]

export default 下装装备数据
