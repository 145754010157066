import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
// import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用外功增益: 技能增益列表类型[] = [
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 236 / 1024 }],
  },
  {
    // 28886
    增益名称: '诛邪非侠',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 61 / 1024 }],
  },
  {
    // 25721_1
    增益名称: '日月齐光·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 82 / 1024 }],
  },
  {
    // 25721_2
    增益名称: '日月齐光·2',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 154 / 1024 }],
  },
  {
    // 25721_3
    增益名称: '日月齐光·3',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  {
    // 12575
    增益名称: '用晦而明',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 563 }],
  },
]

export default 通用外功增益
