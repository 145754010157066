import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 护腕装备数据: 装备属性信息模型[] = [
  {
    id: 103765,
    图标ID: 22083,
    uid: 103765,
    装备名称: '陶然意·月容护手',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103762,
    图标ID: 22083,
    uid: 103762,
    装备名称: '陶然意·修茂护手',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103761,
    图标ID: 22083,
    uid: 103761,
    装备名称: '陶然意·理乾护手',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103757,
    图标ID: 22083,
    uid: 103757,
    装备名称: '陶然意·来仪护手',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.加速等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 103755,
    图标ID: 22083,
    uid: 103755,
    装备名称: '陶然意·凡圣护手',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103751,
    图标ID: 22083,
    uid: 103751,
    装备名称: '陶然意·连筠护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103750,
    图标ID: 22083,
    uid: 103750,
    装备名称: '陶然意·蛇言护手',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103749,
    图标ID: 22083,
    uid: 103749,
    装备名称: '陶然意·寒河护手',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103747,
    图标ID: 22083,
    uid: 103747,
    装备名称: '陶然意·清静护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103745,
    图标ID: 22083,
    uid: 103745,
    装备名称: '陶然意·簇锦护手',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103744,
    图标ID: 22083,
    uid: 103744,
    装备名称: '陶然意·祇树护手',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101591,
    图标ID: 23149,
    uid: 101591,
    装备名称: '花念袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101590,
    图标ID: 23149,
    uid: 101590,
    装备名称: '花深袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.内功破防等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 104335,
    图标ID: 23143,
    uid: 104335,
    装备名称: '钗语护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 2734 },
      { 属性: 属性类型.破招值, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 6786 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101645,
    图标ID: 23143,
    uid: 101645,
    装备名称: '客行江湖·散秋护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101644,
    图标ID: 23143,
    uid: 101644,
    装备名称: '客行江湖·馀散护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101577,
    图标ID: 23143,
    uid: 101577,
    装备名称: '迁莺护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3828 },
      { 属性: 属性类型.加速等级, 值: 7465 },
      { 属性: 属性类型.无双等级, 值: 6560 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101576,
    图标ID: 23143,
    uid: 101576,
    装备名称: '疏泉护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.全会心等级, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101575,
    图标ID: 23143,
    uid: 101575,
    装备名称: '峰翠护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.内功破防等级, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101574,
    图标ID: 23143,
    uid: 101574,
    装备名称: '翠屏护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3965 },
      { 属性: 属性类型.内功破防等级, 值: 4524 },
      { 属性: 属性类型.破招值, 值: 4524 },
      { 属性: 属性类型.无双等级, 值: 4524 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101525,
    图标ID: 23143,
    uid: 101525,
    装备名称: '青桑护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101524,
    图标ID: 23143,
    uid: 101524,
    装备名称: '季春护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101489,
    图标ID: 23143,
    uid: 101489,
    装备名称: '游山护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101488,
    图标ID: 23143,
    uid: 101488,
    装备名称: '思玉护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101363,
    图标ID: 23192,
    uid: 101363,
    装备名称: '孤漠·织春护手',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101359,
    图标ID: 23276,
    uid: 101359,
    装备名称: '孤漠·竹烟护手',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101358,
    图标ID: 23270,
    uid: 101358,
    装备名称: '孤漠·星思护手',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101353,
    图标ID: 23282,
    uid: 101353,
    装备名称: '孤漠·玉寒护手',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101349,
    图标ID: 23216,
    uid: 101349,
    装备名称: '孤漠·星野护手',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101345,
    图标ID: 23240,
    uid: 101345,
    装备名称: '孤漠·雾山护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101343,
    图标ID: 23264,
    uid: 101343,
    装备名称: '孤漠·芳丛袖',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101341,
    图标ID: 23228,
    uid: 101341,
    装备名称: '孤漠·染晴袖',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101339,
    图标ID: 23179,
    uid: 101339,
    装备名称: '孤漠·云岫护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101335,
    图标ID: 23252,
    uid: 101335,
    装备名称: '孤漠·梅曳护手',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101333,
    图标ID: 23234,
    uid: 101333,
    装备名称: '孤漠·闻闲护手',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101238,
    图标ID: 9686,
    uid: 101238,
    装备名称: '漠晖护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101237,
    图标ID: 9686,
    uid: 101237,
    装备名称: '漠安护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 103603,
    图标ID: 22083,
    uid: 103603,
    装备名称: '陶然意·月容护手',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103600,
    图标ID: 22083,
    uid: 103600,
    装备名称: '陶然意·修茂护手',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103599,
    图标ID: 22083,
    uid: 103599,
    装备名称: '陶然意·理乾护手',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103595,
    图标ID: 22083,
    uid: 103595,
    装备名称: '陶然意·来仪护手',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.加速等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 103593,
    图标ID: 22083,
    uid: 103593,
    装备名称: '陶然意·凡圣护手',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103589,
    图标ID: 22083,
    uid: 103589,
    装备名称: '陶然意·连筠护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103588,
    图标ID: 22083,
    uid: 103588,
    装备名称: '陶然意·蛇言护手',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103587,
    图标ID: 22083,
    uid: 103587,
    装备名称: '陶然意·寒河护手',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103585,
    图标ID: 22083,
    uid: 103585,
    装备名称: '陶然意·清静护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103583,
    图标ID: 22083,
    uid: 103583,
    装备名称: '陶然意·簇锦护手',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103582,
    图标ID: 22083,
    uid: 103582,
    装备名称: '陶然意·祇树护手',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 103487,
    图标ID: 6478,
    uid: 103487,
    装备名称: '孤山寒月·忧生护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 103486,
    图标ID: 6478,
    uid: 103486,
    装备名称: '孤山寒月·风吟护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101627,
    图标ID: 23143,
    uid: 101627,
    装备名称: '客行江湖·自吟护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101626,
    图标ID: 23143,
    uid: 101626,
    装备名称: '客行江湖·含虚护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101196,
    图标ID: 23143,
    uid: 101196,
    装备名称: '映宵护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101195,
    图标ID: 23143,
    uid: 101195,
    装备名称: '云眷护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101160,
    图标ID: 23143,
    uid: 101160,
    装备名称: '江汜护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101159,
    图标ID: 23143,
    uid: 101159,
    装备名称: '合苏护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101034,
    图标ID: 23192,
    uid: 101034,
    装备名称: '西塞·沉醉护手',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101030,
    图标ID: 23276,
    uid: 101030,
    装备名称: '西塞·银筱护手',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101029,
    图标ID: 23270,
    uid: 101029,
    装备名称: '西塞·清浊护手',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101024,
    图标ID: 23282,
    uid: 101024,
    装备名称: '西塞·青弦护手',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101020,
    图标ID: 23216,
    uid: 101020,
    装备名称: '西塞·大荒护手',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101016,
    图标ID: 23240,
    uid: 101016,
    装备名称: '西塞·寒蛩护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101014,
    图标ID: 23264,
    uid: 101014,
    装备名称: '西塞·朝愁护手',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101012,
    图标ID: 23228,
    uid: 101012,
    装备名称: '西塞·烟蘸袖',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101010,
    图标ID: 23179,
    uid: 101010,
    装备名称: '西塞·雪欹护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101006,
    图标ID: 23252,
    uid: 101006,
    装备名称: '西塞·合章护手',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101004,
    图标ID: 23234,
    uid: 101004,
    装备名称: '西塞·彼岸护手',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100975,
    图标ID: 8889,
    uid: 100975,
    装备名称: '寻踪觅宝·中林袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100974,
    图标ID: 8889,
    uid: 100974,
    装备名称: '寻踪觅宝·谷风袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100945,
    图标ID: 7987,
    uid: 100945,
    装备名称: '寻踪觅宝·月诸袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100944,
    图标ID: 7987,
    uid: 100944,
    装备名称: '寻踪觅宝·陟南袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100915,
    图标ID: 11599,
    uid: 100915,
    装备名称: '壁镜袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100914,
    图标ID: 11599,
    uid: 100914,
    装备名称: '壁观袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100783,
    图标ID: 5882,
    uid: 100783,
    装备名称: '语镇护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.元气, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.全会心等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100782,
    图标ID: 5882,
    uid: 100782,
    装备名称: '语洲护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.根骨, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.内功会心等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104501,
    图标ID: 11599,
    uid: 104501,
    装备名称: '昭文袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104500,
    图标ID: 11599,
    uid: 104500,
    装备名称: '丘墟袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104455,
    图标ID: 11599,
    uid: 104455,
    装备名称: '斛尘护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 104454,
    图标ID: 11599,
    uid: 104454,
    装备名称: '熙春护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 104112,
    图标ID: 11599,
    uid: 104112,
    装备名称: '玉凉袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 104111,
    图标ID: 11599,
    uid: 104111,
    装备名称: '樛枝袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100879,
    图标ID: 11599,
    uid: 100879,
    装备名称: '昭文袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100878,
    图标ID: 11599,
    uid: 100878,
    装备名称: '丘墟袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100843,
    图标ID: 11599,
    uid: 100843,
    装备名称: '玉凉袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100842,
    图标ID: 11599,
    uid: 100842,
    装备名称: '樛枝袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100807,
    图标ID: 11599,
    uid: 100807,
    装备名称: '斛尘护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100806,
    图标ID: 11599,
    uid: 100806,
    装备名称: '熙春护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100675,
    图标ID: 11045,
    uid: 100675,
    装备名称: '弥弦袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100674,
    图标ID: 11045,
    uid: 100674,
    装备名称: '珀音袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100639,
    图标ID: 11045,
    uid: 100639,
    装备名称: '霖瑜袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100638,
    图标ID: 11045,
    uid: 100638,
    装备名称: '瑾辞袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100759,
    图标ID: 5882,
    uid: 100759,
    装备名称: '语镇护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.元气, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.全会心等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100758,
    图标ID: 5882,
    uid: 100758,
    装备名称: '语洲护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.根骨, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.内功会心等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100603,
    图标ID: 5882,
    uid: 100603,
    装备名称: '惜晗护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.元气, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.加速等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100602,
    图标ID: 5882,
    uid: 100602,
    装备名称: '濯缨护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.根骨, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.加速等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100735,
    图标ID: 5882,
    uid: 100735,
    装备名称: '语镇护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.元气, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.全会心等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100734,
    图标ID: 5882,
    uid: 100734,
    装备名称: '语洲护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.根骨, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.内功会心等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100567,
    图标ID: 6827,
    uid: 100567,
    装备名称: '绢素袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.全会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100566,
    图标ID: 6827,
    uid: 100566,
    装备名称: '烨霖袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.根骨, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.内功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100711,
    图标ID: 5882,
    uid: 100711,
    装备名称: '语镇护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.元气, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.全会心等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100710,
    图标ID: 5882,
    uid: 100710,
    装备名称: '语洲护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.根骨, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.内功会心等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101831,
    图标ID: 5882,
    uid: 101831,
    装备名称: '轻桡袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101830,
    图标ID: 5882,
    uid: 101830,
    装备名称: '临流袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100531,
    图标ID: 17930,
    uid: 100531,
    装备名称: '凝玥护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100530,
    图标ID: 17930,
    uid: 100530,
    装备名称: '静漪护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
]

export default 护腕装备数据
