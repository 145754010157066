import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 傍身招式增益 from './技能增益/通用增益/傍身招式增益'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'
import 截阳增益 from './技能增益/截阳'
import 引窍增益 from './技能增益/引窍'
import 锁神增益 from './技能增益/锁神'
import 断脉增益 from './技能增益/断脉'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '102280',
    技能名称: '一阳指·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 384,
    技能破招系数: 1229 / 1024,
    技能增益列表: [...通用增益, ...傍身招式增益],
  },
  {
    技能ID: '102282',
    技能名称: '引窍·悟',
    技能伤害系数: 565,
    技能破招系数: 1802 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 引窍增益,
  },
  {
    技能ID: '102305',
    技能名称: '锁神·悟',
    统计名称: '锁神·悟',
    技能伤害系数: 219,
    技能破招系数: 696 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 锁神增益,
  },
  {
    技能ID: '102313',
    技能名称: '锁神·悟·绝篇',
    统计名称: '锁神·悟',
    技能伤害系数: 191,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102309',
    技能名称: '截阳·悟',
    技能伤害系数: 776,
    基础伤害_基础值: 1,
    技能增益列表: 截阳增益,
  },
  {
    技能ID: '102310',
    技能名称: '骤风令·悟',
    技能伤害系数: 672,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102311',
    技能名称: '断脉·悟',
    统计名称: '断脉·悟',
    技能伤害系数: 323,
    基础伤害_基础值: 1,
    技能增益列表: 断脉增益,
  },
  {
    技能ID: '102318',
    技能名称: '断脉·悟·二',
    统计名称: '断脉·悟',
    技能伤害系数: 384,
    技能破招系数: 1229 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102312',
    技能名称: '一阳化生·悟',
    技能伤害系数: 181,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102323',
    技能名称: '风扬·悟',
    技能伤害系数: 235,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
