import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 103853,
    图标ID: 22084,
    uid: 103853,
    装备名称: '陶然意·月容帽',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103850,
    图标ID: 22084,
    uid: 103850,
    装备名称: '陶然意·修茂帽',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103849,
    图标ID: 22084,
    uid: 103849,
    装备名称: '陶然意·理乾帽',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103845,
    图标ID: 22084,
    uid: 103845,
    装备名称: '陶然意·来仪帽',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.加速等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103843,
    图标ID: 22084,
    uid: 103843,
    装备名称: '陶然意·凡圣帽',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103839,
    图标ID: 22084,
    uid: 103839,
    装备名称: '陶然意·连筠帽',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103838,
    图标ID: 22084,
    uid: 103838,
    装备名称: '陶然意·蛇言帽',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.加速等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103837,
    图标ID: 22084,
    uid: 103837,
    装备名称: '陶然意·寒河帽',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103835,
    图标ID: 22084,
    uid: 103835,
    装备名称: '陶然意·清静帽',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103833,
    图标ID: 22084,
    uid: 103833,
    装备名称: '陶然意·簇锦帽',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103832,
    图标ID: 22084,
    uid: 103832,
    装备名称: '陶然意·祇树帽',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 984 },
      { 属性: 属性类型.内功基础攻击, 值: 1785 },
      { 属性: 属性类型.内功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101615,
    图标ID: 23150,
    uid: 101615,
    装备名称: '花念冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.元气, 值: 1060 },
      { 属性: 属性类型.内功基础攻击, 值: 2677 },
      { 属性: 属性类型.破招值, 值: 8860 },
      { 属性: 属性类型.无双等级, 值: 7088 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101614,
    图标ID: 23150,
    uid: 101614,
    装备名称: '花深冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.根骨, 值: 1060 },
      { 属性: 属性类型.内功基础攻击, 值: 2677 },
      { 属性: 属性类型.破招值, 值: 8860 },
      { 属性: 属性类型.无双等级, 值: 7088 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101657,
    图标ID: 23144,
    uid: 101657,
    装备名称: '客行江湖·散秋冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101656,
    图标ID: 23144,
    uid: 101656,
    装备名称: '客行江湖·馀散冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101549,
    图标ID: 23144,
    uid: 101549,
    装备名称: '青桑帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101548,
    图标ID: 23144,
    uid: 101548,
    装备名称: '季春帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101513,
    图标ID: 23144,
    uid: 101513,
    装备名称: '游山冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.全会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101512,
    图标ID: 23144,
    uid: 101512,
    装备名称: '思玉冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101456,
    图标ID: 23193,
    uid: 101456,
    装备名称: '孤漠·织春冠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101452,
    图标ID: 23277,
    uid: 101452,
    装备名称: '孤漠·竹烟冠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101451,
    图标ID: 23271,
    uid: 101451,
    装备名称: '孤漠·星思冠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101446,
    图标ID: 23283,
    uid: 101446,
    装备名称: '孤漠·玉寒冠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101442,
    图标ID: 23217,
    uid: 101442,
    装备名称: '孤漠·星野冠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101438,
    图标ID: 23241,
    uid: 101438,
    装备名称: '孤漠·雾山冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101436,
    图标ID: 23265,
    uid: 101436,
    装备名称: '孤漠·芳丛冠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101434,
    图标ID: 23229,
    uid: 101434,
    装备名称: '孤漠·染晴冠',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101432,
    图标ID: 23180,
    uid: 101432,
    装备名称: '孤漠·云岫冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101428,
    图标ID: 23253,
    uid: 101428,
    装备名称: '孤漠·梅曳冠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101426,
    图标ID: 23235,
    uid: 101426,
    装备名称: '孤漠·闻闲冠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101262,
    图标ID: 9684,
    uid: 101262,
    装备名称: '漠晖冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101261,
    图标ID: 9684,
    uid: 101261,
    装备名称: '漠安冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104444,
    图标ID: 9240,
    uid: 104444,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 5019 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.内功破防等级, 值: 5727 },
      { 属性: 属性类型.无双等级, 值: 5727 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104443,
    图标ID: 9240,
    uid: 104443,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 4153 },
      { 属性: 属性类型.全会心等级, 值: 6300 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104442,
    图标ID: 9240,
    uid: 104442,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 5884 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101808,
    图标ID: 9240,
    uid: 101808,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 5019 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.内功破防等级, 值: 5727 },
      { 属性: 属性类型.无双等级, 值: 5727 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101807,
    图标ID: 9240,
    uid: 101807,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 4153 },
      { 属性: 属性类型.全会心等级, 值: 6300 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101806,
    图标ID: 9240,
    uid: 101806,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.内功基础攻击, 值: 5884 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104432,
    图标ID: 9240,
    uid: 104432,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 4822 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 5502 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104431,
    图标ID: 9240,
    uid: 104431,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 4822 },
      { 属性: 属性类型.全会心等级, 值: 8254 },
      { 属性: 属性类型.全会心效果等级, 值: 4127 },
      { 属性: 属性类型.无双等级, 值: 4127 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104430,
    图标ID: 9240,
    uid: 104430,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 3990 },
      { 属性: 属性类型.内功破防等级, 值: 12656 },
      { 属性: 属性类型.无双等级, 值: 6603 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101784,
    图标ID: 9240,
    uid: 101784,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 4822 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 5502 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101783,
    图标ID: 9240,
    uid: 101783,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 4822 },
      { 属性: 属性类型.全会心等级, 值: 8254 },
      { 属性: 属性类型.全会心效果等级, 值: 4127 },
      { 属性: 属性类型.无双等级, 值: 4127 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101782,
    图标ID: 9240,
    uid: 101782,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.内功基础攻击, 值: 3990 },
      { 属性: 属性类型.内功破防等级, 值: 12656 },
      { 属性: 属性类型.无双等级, 值: 6603 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104420,
    图标ID: 9240,
    uid: 104420,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 4625 },
      { 属性: 属性类型.全会心等级, 值: 5278 },
      { 属性: 属性类型.内功破防等级, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104419,
    图标ID: 9240,
    uid: 104419,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 4625 },
      { 属性: 属性类型.全会心等级, 值: 5278 },
      { 属性: 属性类型.破招值, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104418,
    图标ID: 9240,
    uid: 104418,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 3828 },
      { 属性: 属性类型.全会心等级, 值: 12139 },
      { 属性: 属性类型.无双等级, 值: 6333 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101760,
    图标ID: 9240,
    uid: 101760,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 4625 },
      { 属性: 属性类型.全会心等级, 值: 5278 },
      { 属性: 属性类型.内功破防等级, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101759,
    图标ID: 9240,
    uid: 101759,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 4625 },
      { 属性: 属性类型.全会心等级, 值: 5278 },
      { 属性: 属性类型.破招值, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101758,
    图标ID: 9240,
    uid: 101758,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.内功基础攻击, 值: 3828 },
      { 属性: 属性类型.全会心等级, 值: 12139 },
      { 属性: 属性类型.无双等级, 值: 6333 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103691,
    图标ID: 22084,
    uid: 103691,
    装备名称: '陶然意·月容帽',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103688,
    图标ID: 22084,
    uid: 103688,
    装备名称: '陶然意·修茂帽',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.根骨, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103687,
    图标ID: 22084,
    uid: 103687,
    装备名称: '陶然意·理乾帽',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103683,
    图标ID: 22084,
    uid: 103683,
    装备名称: '陶然意·来仪帽',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.根骨, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.加速等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103681,
    图标ID: 22084,
    uid: 103681,
    装备名称: '陶然意·凡圣帽',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103677,
    图标ID: 22084,
    uid: 103677,
    装备名称: '陶然意·连筠帽',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103676,
    图标ID: 22084,
    uid: 103676,
    装备名称: '陶然意·蛇言帽',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.根骨, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.加速等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103675,
    图标ID: 22084,
    uid: 103675,
    装备名称: '陶然意·寒河帽',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.根骨, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103673,
    图标ID: 22084,
    uid: 103673,
    装备名称: '陶然意·清静帽',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.根骨, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103671,
    图标ID: 22084,
    uid: 103671,
    装备名称: '陶然意·簇锦帽',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103670,
    图标ID: 22084,
    uid: 103670,
    装备名称: '陶然意·祇树帽',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.元气, 值: 846 },
      { 属性: 属性类型.内功基础攻击, 值: 1534 },
      { 属性: 属性类型.内功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 104408,
    图标ID: 9240,
    uid: 104408,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 4428 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.内功破防等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 5053 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104407,
    图标ID: 9240,
    uid: 104407,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 3665 },
      { 属性: 属性类型.全会心等级, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104406,
    图标ID: 9240,
    uid: 104406,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 5192 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103511,
    图标ID: 6480,
    uid: 103511,
    装备名称: '孤山寒月·忧生冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103510,
    图标ID: 6480,
    uid: 103510,
    装备名称: '孤山寒月·风吟冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101736,
    图标ID: 9240,
    uid: 101736,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 4428 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.内功破防等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 5053 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101735,
    图标ID: 9240,
    uid: 101735,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 3665 },
      { 属性: 属性类型.全会心等级, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101734,
    图标ID: 9240,
    uid: 101734,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.内功基础攻击, 值: 5192 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101639,
    图标ID: 23144,
    uid: 101639,
    装备名称: '客行江湖·自吟冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101638,
    图标ID: 23144,
    uid: 101638,
    装备名称: '客行江湖·含虚冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101220,
    图标ID: 23144,
    uid: 101220,
    装备名称: '映宵帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101219,
    图标ID: 23144,
    uid: 101219,
    装备名称: '云眷帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101184,
    图标ID: 23144,
    uid: 101184,
    装备名称: '江汜冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101183,
    图标ID: 23144,
    uid: 101183,
    装备名称: '合苏冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101127,
    图标ID: 23193,
    uid: 101127,
    装备名称: '西塞·沉醉冠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101123,
    图标ID: 23277,
    uid: 101123,
    装备名称: '西塞·银筱冠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101122,
    图标ID: 23271,
    uid: 101122,
    装备名称: '西塞·清浊冠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101117,
    图标ID: 23283,
    uid: 101117,
    装备名称: '西塞·青弦冠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101113,
    图标ID: 23217,
    uid: 101113,
    装备名称: '西塞·大荒冠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101109,
    图标ID: 23241,
    uid: 101109,
    装备名称: '西塞·寒蛩冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101107,
    图标ID: 23265,
    uid: 101107,
    装备名称: '西塞·朝愁冠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101105,
    图标ID: 23229,
    uid: 101105,
    装备名称: '西塞·烟蘸钗',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101103,
    图标ID: 23180,
    uid: 101103,
    装备名称: '西塞·雪欹冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101099,
    图标ID: 23253,
    uid: 101099,
    装备名称: '西塞·合章冠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101097,
    图标ID: 23235,
    uid: 101097,
    装备名称: '西塞·彼岸冠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100993,
    图标ID: 8888,
    uid: 100993,
    装备名称: '寻踪觅宝·中林帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100992,
    图标ID: 8888,
    uid: 100992,
    装备名称: '寻踪觅宝·谷风帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104396,
    图标ID: 9240,
    uid: 104396,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 4251 },
      { 属性: 属性类型.全会心等级, 值: 4851 },
      { 属性: 属性类型.破招值, 值: 4851 },
      { 属性: 属性类型.无双等级, 值: 4851 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104395,
    图标ID: 9240,
    uid: 104395,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 4251 },
      { 属性: 属性类型.全会心等级, 值: 7277 },
      { 属性: 属性类型.全会心效果等级, 值: 3638 },
      { 属性: 属性类型.无双等级, 值: 3638 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104394,
    图标ID: 9240,
    uid: 104394,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 3518 },
      { 属性: 属性类型.内功破防等级, 值: 11158 },
      { 属性: 属性类型.无双等级, 值: 5821 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101712,
    图标ID: 9240,
    uid: 101712,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 4251 },
      { 属性: 属性类型.全会心等级, 值: 4851 },
      { 属性: 属性类型.破招值, 值: 4851 },
      { 属性: 属性类型.无双等级, 值: 4851 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101711,
    图标ID: 9240,
    uid: 101711,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 4251 },
      { 属性: 属性类型.全会心等级, 值: 7277 },
      { 属性: 属性类型.全会心效果等级, 值: 3638 },
      { 属性: 属性类型.无双等级, 值: 3638 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101710,
    图标ID: 9240,
    uid: 101710,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.内功基础攻击, 值: 3518 },
      { 属性: 属性类型.内功破防等级, 值: 11158 },
      { 属性: 属性类型.无双等级, 值: 5821 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100963,
    图标ID: 7986,
    uid: 100963,
    装备名称: '寻踪觅宝·月诸帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.元气, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.全会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100962,
    图标ID: 7986,
    uid: 100962,
    装备名称: '寻踪觅宝·陟南帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.根骨, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100933,
    图标ID: 11598,
    uid: 100933,
    装备名称: '壁镜帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.元气, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100932,
    图标ID: 11598,
    uid: 100932,
    装备名称: '壁观帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.根骨, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104384,
    图标ID: 9240,
    uid: 104384,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 4074 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.内功破防等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104383,
    图标ID: 9240,
    uid: 104383,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 4074 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.破招值, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104382,
    图标ID: 9240,
    uid: 104382,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 3372 },
      { 属性: 属性类型.全会心等级, 值: 10693 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101688,
    图标ID: 9240,
    uid: 101688,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 4074 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.内功破防等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101687,
    图标ID: 9240,
    uid: 101687,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 4074 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.破招值, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101686,
    图标ID: 9240,
    uid: 101686,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.内功基础攻击, 值: 3372 },
      { 属性: 属性类型.全会心等级, 值: 10693 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100801,
    图标ID: 5881,
    uid: 100801,
    装备名称: '语雾帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.元气, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2107 },
      { 属性: 属性类型.内功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100800,
    图标ID: 5881,
    uid: 100800,
    装备名称: '语溪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.根骨, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2107 },
      { 属性: 属性类型.内功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104492,
    图标ID: 11598,
    uid: 104492,
    装备名称: '玉凉冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104491,
    图标ID: 11598,
    uid: 104491,
    装备名称: '樛枝冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104464,
    图标ID: 11598,
    uid: 104464,
    装备名称: '斛尘冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.全会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104463,
    图标ID: 11598,
    uid: 104463,
    装备名称: '熙春冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.内功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104136,
    图标ID: 11598,
    uid: 104136,
    装备名称: '昭文冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104135,
    图标ID: 11598,
    uid: 104135,
    装备名称: '丘墟冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100903,
    图标ID: 11598,
    uid: 100903,
    装备名称: '昭文冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100902,
    图标ID: 11598,
    uid: 100902,
    装备名称: '丘墟冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100867,
    图标ID: 11598,
    uid: 100867,
    装备名称: '玉凉冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100866,
    图标ID: 11598,
    uid: 100866,
    装备名称: '樛枝冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100831,
    图标ID: 11598,
    uid: 100831,
    装备名称: '斛尘冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.全会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100830,
    图标ID: 11598,
    uid: 100830,
    装备名称: '熙春冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.内功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100699,
    图标ID: 11041,
    uid: 100699,
    装备名称: '弥弦帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100698,
    图标ID: 11041,
    uid: 100698,
    装备名称: '珀音帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100663,
    图标ID: 11041,
    uid: 100663,
    装备名称: '霖瑜帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.全会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 100662,
    图标ID: 11041,
    uid: 100662,
    装备名称: '瑾辞帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 104372,
    图标ID: 9240,
    uid: 104372,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 3897 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.内功破防等级, 值: 4447 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104371,
    图标ID: 9240,
    uid: 104371,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 3225 },
      { 属性: 属性类型.全会心等级, 值: 4892 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104370,
    图标ID: 9240,
    uid: 104370,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 4569 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101664,
    图标ID: 9240,
    uid: 101664,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 3897 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.内功破防等级, 值: 4447 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101663,
    图标ID: 9240,
    uid: 101663,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 3225 },
      { 属性: 属性类型.全会心等级, 值: 4892 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101662,
    图标ID: 9240,
    uid: 101662,
    装备名称: '无修冠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.内功基础攻击, 值: 4569 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100777,
    图标ID: 5881,
    uid: 100777,
    装备名称: '语雾帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9586 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1975 },
      { 属性: 属性类型.内功破防等级, 值: 6536 },
      { 属性: 属性类型.无双等级, 值: 5228 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100776,
    图标ID: 5881,
    uid: 100776,
    装备名称: '语溪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9586 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1975 },
      { 属性: 属性类型.内功破防等级, 值: 6536 },
      { 属性: 属性类型.无双等级, 值: 5228 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100627,
    图标ID: 5881,
    uid: 100627,
    装备名称: '惜晗帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9289 },
      { 属性: 属性类型.元气, 值: 758 },
      { 属性: 属性类型.内功基础攻击, 值: 1914 },
      { 属性: 属性类型.加速等级, 值: 6333 },
      { 属性: 属性类型.无双等级, 值: 5067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100626,
    图标ID: 5881,
    uid: 100626,
    装备名称: '濯缨帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9289 },
      { 属性: 属性类型.根骨, 值: 758 },
      { 属性: 属性类型.内功基础攻击, 值: 1914 },
      { 属性: 属性类型.加速等级, 值: 6333 },
      { 属性: 属性类型.无双等级, 值: 5067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100753,
    图标ID: 5881,
    uid: 100753,
    装备名称: '语雾帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8943 },
      { 属性: 属性类型.元气, 值: 730 },
      { 属性: 属性类型.内功基础攻击, 值: 1843 },
      { 属性: 属性类型.内功破防等级, 值: 6098 },
      { 属性: 属性类型.无双等级, 值: 4878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100752,
    图标ID: 5881,
    uid: 100752,
    装备名称: '语溪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8943 },
      { 属性: 属性类型.根骨, 值: 730 },
      { 属性: 属性类型.内功基础攻击, 值: 1843 },
      { 属性: 属性类型.内功破防等级, 值: 6098 },
      { 属性: 属性类型.无双等级, 值: 4878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100591,
    图标ID: 6822,
    uid: 100591,
    装备名称: '绢素巾',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8696 },
      { 属性: 属性类型.元气, 值: 709 },
      { 属性: 属性类型.内功基础攻击, 值: 1792 },
      { 属性: 属性类型.内功破防等级, 值: 5929 },
      { 属性: 属性类型.无双等级, 值: 4743 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100590,
    图标ID: 6822,
    uid: 100590,
    装备名称: '烨霖巾',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8696 },
      { 属性: 属性类型.根骨, 值: 709 },
      { 属性: 属性类型.内功基础攻击, 值: 1792 },
      { 属性: 属性类型.内功破防等级, 值: 5929 },
      { 属性: 属性类型.无双等级, 值: 4743 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100729,
    图标ID: 5881,
    uid: 100729,
    装备名称: '语雾帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.元气, 值: 677 },
      { 属性: 属性类型.内功基础攻击, 值: 1710 },
      { 属性: 属性类型.内功破防等级, 值: 5660 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100728,
    图标ID: 5881,
    uid: 100728,
    装备名称: '语溪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.根骨, 值: 677 },
      { 属性: 属性类型.内功基础攻击, 值: 1710 },
      { 属性: 属性类型.内功破防等级, 值: 5660 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101855,
    图标ID: 5881,
    uid: 101855,
    装备名称: '轻桡冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.元气, 值: 665 },
      { 属性: 属性类型.内功基础攻击, 值: 1680 },
      { 属性: 属性类型.破招值, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101854,
    图标ID: 5881,
    uid: 101854,
    装备名称: '临流冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.根骨, 值: 665 },
      { 属性: 属性类型.内功基础攻击, 值: 1680 },
      { 属性: 属性类型.破招值, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100555,
    图标ID: 17933,
    uid: 100555,
    装备名称: '凝玥帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.元气, 值: 618 },
      { 属性: 属性类型.内功基础攻击, 值: 1120 },
      { 属性: 属性类型.内功破防等级, 值: 5559 },
      { 属性: 属性类型.全能等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100554,
    图标ID: 17933,
    uid: 100554,
    装备名称: '静漪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.根骨, 值: 618 },
      { 属性: 属性类型.内功基础攻击, 值: 1120 },
      { 属性: 属性类型.内功破防等级, 值: 5559 },
      { 属性: 属性类型.全能等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 帽子装备数据
