// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cA6rHvd0jP5iDFAPGmqw {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--ant-primary-color);
}
.aPimfQnvZGnFCqIMRUSh {
  line-height: 20px;
  margin-bottom: 4px;
  color: #cd1818;
}
.blj2BTeeR6YsnvxsE3dD {
  padding: 0 4px;
  font-weight: 700;
  color: var(--ant-primary-color);
  text-decoration: underline;
}
.OMg7hbCn67AZqn1crZvL {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
}
.KvmoLZX5dRnRO_XsYUaX + .KvmoLZX5dRnRO_XsYUaX {
  margin-left: 16px;
}
.FF2WhZaBgPIhrsJ_snrV {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}
.UkmO2tUx9MNwh8Ez_dwW {
  font-size: 20px;
  line-height: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/系统说明/当前版本/计算记录对比/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,+BAAA;AACF;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AAAF;AAGA;EACE,cAAA;EACA,gBAAA;EACA,+BAAA;EACA,0BAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAJF;AAOA;EACE,eAAA;EACA,iBAAA;AALF","sourcesContent":[".title {\n  font-size: 14px;\n  line-height: 20px;\n  margin-bottom: 4px;\n  color: var(--ant-primary-color);\n}\n\n.text {\n  line-height: 20px;\n  margin-bottom: 4px;\n  color: #cd1818\n}\n\n.cycleTip {\n  padding: 0 4px;\n  font-weight: 700;\n  color: var(--ant-primary-color);\n  text-decoration: underline;\n}\n\n.content {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-top: 4px;\n}\n\n.contentItem + .contentItem {\n  margin-left: 16px;\n}\n\n.diffTitle {\n  font-size: 14px;\n  line-height: 20px;\n  margin-bottom: 4px;\n}\n\n.diffNum {\n  font-size: 20px;\n  line-height: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `cA6rHvd0jP5iDFAPGmqw`,
	"text": `aPimfQnvZGnFCqIMRUSh`,
	"cycleTip": `blj2BTeeR6YsnvxsE3dD`,
	"content": `OMg7hbCn67AZqn1crZvL`,
	"contentItem": `KvmoLZX5dRnRO_XsYUaX`,
	"diffTitle": `FF2WhZaBgPIhrsJ_snrV`,
	"diffNum": `UkmO2tUx9MNwh8Ez_dwW`
};
export default ___CSS_LOADER_EXPORT___;
