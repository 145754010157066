// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iBSAR8hedfBeaJSI0i2z {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iBSAR8hedfBeaJSI0i2z.ZlAo1Vu0X1nM9TKz5YtU {
  cursor: pointer;
}
.sfAMZm2S4Gy4Ogrn_clf {
  width: 100%;
}
.Jp0yvbOCTUAjgvEfbNVj {
  width: 100%;
  display: flex;
  align-items: center;
}
.sfAMZm2S4Gy4Ogrn_clf {
  transition: 0.2s;
}
.Z7Ii0axGXeDKXB61T36C {
  width: 100%;
  height: 400px;
}
.gB_ODTsGctbA2DX79lzA {
  font-size: 24px;
  font-weight: bold;
  margin: 48px auto 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step6_访问时间统计/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,WAAA;AADF;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,WAAA;EACA,aAAA;AAJF;AAOA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AALF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  width: 100%;\n}\n\n.chartWrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.content {\n  transition: .2s;\n}\n\n.viewChart {\n  width: 100%;\n  height: 400px;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin: 48px auto 0;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `iBSAR8hedfBeaJSI0i2z`,
	"next": `ZlAo1Vu0X1nM9TKz5YtU`,
	"content": `sfAMZm2S4Gy4Ogrn_clf`,
	"chartWrap": `Jp0yvbOCTUAjgvEfbNVj`,
	"viewChart": `Z7Ii0axGXeDKXB61T36C`,
	"title": `gB_ODTsGctbA2DX79lzA`
};
export default ___CSS_LOADER_EXPORT___;
