// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z85vnTsUiS6Y1AGXE4Vy {
  padding-left: 24px;
  position: relative;
  height: 630px;
}
.ox0Yin2Cpjnl1YS8tpHe {
  margin-bottom: 12px;
}
.g6NhsW7j1y1H74QyIWWc {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
}
.S9qnAshQqsnYxWVaw0J5 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 24px;
}
.Vxz0S9dvPXWa39FtXx7K {
  margin-top: 12px;
}
.sm3oxIGQLiPrKDmEPSBO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sm3oxIGQLiPrKDmEPSBO .ant-btm {
  width: 40%;
}
.RMnc5SmH9RwMPWPdKp8m {
  padding-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/导出配置/index.module.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;AAAF;AAGA;EACE,mBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAFF;AAKA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,kBAAA;AAHF;AAMA;EACE,gBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AALF;AAEA;EAMM,UAAA;AALN;AAUA;EACE,iBAAA;AARF","sourcesContent":["\n.setting {\n  padding-left: 24px;\n  position: relative;\n  height: 630px;\n}\n\n.item {\n  margin-bottom: 12px;\n}\n\n.title {\n  font-size: 14px;\n  line-height: 18px;\n  margin-bottom: 8px;\n}\n\n.footer {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding-left: 24px;\n}\n\n.btn {\n  margin-top: 12px;\n}\n\n.codeWrap {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  :global {\n    .ant-btm {\n      width: 40%;\n    }\n  }\n}\n\n.codeForm {\n  padding-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": `Z85vnTsUiS6Y1AGXE4Vy`,
	"item": `ox0Yin2Cpjnl1YS8tpHe`,
	"title": `g6NhsW7j1y1H74QyIWWc`,
	"footer": `S9qnAshQqsnYxWVaw0J5`,
	"btn": `Vxz0S9dvPXWa39FtXx7K`,
	"codeWrap": `sm3oxIGQLiPrKDmEPSBO`,
	"codeForm": `RMnc5SmH9RwMPWPdKp8m`
};
export default ___CSS_LOADER_EXPORT___;
