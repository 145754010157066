import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 戒指装备数据: 装备属性信息模型[] = [
  {
    id: 42883,
    图标ID: 18059,
    uid: 42883,
    装备名称: '共苍茫·遥',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7549 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 1305 },
      { 属性: 属性类型.外功会心等级, 值: 2573 },
      { 属性: 属性类型.外功破防等级, 值: 2573 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42441,
    图标ID: 18065,
    uid: 42441,
    装备名称: '共苍茫·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19800,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7549 },
      { 属性: 属性类型.身法, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 1305 },
      { 属性: 属性类型.外功会心等级, 值: 1887 },
      { 属性: 属性类型.外功破防等级, 值: 1887 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42440,
    图标ID: 18059,
    uid: 42440,
    装备名称: '共苍茫·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19800,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7549 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 1305 },
      { 属性: 属性类型.外功会心等级, 值: 1887 },
      { 属性: 属性类型.外功破防等级, 值: 1887 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42882,
    图标ID: 18058,
    uid: 42882,
    装备名称: '共苍茫·遥',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 17250,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6577 },
      { 属性: 属性类型.力道, 值: 537 },
      { 属性: 属性类型.外功基础攻击, 值: 1137 },
      { 属性: 属性类型.外功会心等级, 值: 2242 },
      { 属性: 属性类型.外功破防等级, 值: 2242 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42436,
    图标ID: 18064,
    uid: 42436,
    装备名称: '共苍茫·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17250,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6577 },
      { 属性: 属性类型.身法, 值: 537 },
      { 属性: 属性类型.外功基础攻击, 值: 1137 },
      { 属性: 属性类型.外功会心等级, 值: 1644 },
      { 属性: 属性类型.外功破防等级, 值: 1644 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42435,
    图标ID: 18058,
    uid: 42435,
    装备名称: '共苍茫·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17250,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6577 },
      { 属性: 属性类型.力道, 值: 537 },
      { 属性: 属性类型.外功基础攻击, 值: 1137 },
      { 属性: 属性类型.外功会心等级, 值: 1644 },
      { 属性: 属性类型.外功破防等级, 值: 1644 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42881,
    图标ID: 18057,
    uid: 42881,
    装备名称: '共苍茫·遥',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 15500,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5909 },
      { 属性: 属性类型.力道, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 1022 },
      { 属性: 属性类型.外功会心等级, 值: 2015 },
      { 属性: 属性类型.外功破防等级, 值: 2015 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42431,
    图标ID: 18063,
    uid: 42431,
    装备名称: '共苍茫·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15500,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5909 },
      { 属性: 属性类型.身法, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 1022 },
      { 属性: 属性类型.外功会心等级, 值: 1477 },
      { 属性: 属性类型.外功破防等级, 值: 1477 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42430,
    图标ID: 18057,
    uid: 42430,
    装备名称: '共苍茫·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15500,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5909 },
      { 属性: 属性类型.力道, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 1022 },
      { 属性: 属性类型.外功会心等级, 值: 1477 },
      { 属性: 属性类型.外功破防等级, 值: 1477 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42588,
    图标ID: 1232,
    uid: 42588,
    装备名称: '陶然意·拥狸戒',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42587,
    图标ID: 1232,
    uid: 42587,
    装备名称: '陶然意·万仞戒',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42584,
    图标ID: 1232,
    uid: 42584,
    装备名称: '陶然意·徇节戒',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42583,
    图标ID: 1232,
    uid: 42583,
    装备名称: '陶然意·临溟戒',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42582,
    图标ID: 1232,
    uid: 42582,
    装备名称: '陶然意·上造戒',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42580,
    图标ID: 1232,
    uid: 42580,
    装备名称: '陶然意·征惯戒',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42578,
    图标ID: 1232,
    uid: 42578,
    装备名称: '陶然意·春瓮戒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42577,
    图标ID: 1232,
    uid: 42577,
    装备名称: '陶然意·吴陵戒',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42576,
    图标ID: 1232,
    uid: 42576,
    装备名称: '陶然意·穿心戒',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42572,
    图标ID: 1232,
    uid: 42572,
    装备名称: '陶然意·蜕羽戒',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.身法, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42570,
    图标ID: 1232,
    uid: 42570,
    装备名称: '陶然意·登锋戒',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.力道, 值: 547 },
      { 属性: 属性类型.外功基础攻击, 值: 892 },
      { 属性: 属性类型.外功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42729,
    图标ID: 2849,
    uid: 42729,
    装备名称: '舫梦戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2810 },
      { 属性: 属性类型.加速等级, 值: 8725 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41432,
    图标ID: 2845,
    uid: 41432,
    装备名称: '客行江湖·泉葩戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41431,
    图标ID: 2845,
    uid: 41431,
    装备名称: '客行江湖·冥绝戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41374,
    图标ID: 22891,
    uid: 41374,
    装备名称: '息机戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2108 },
      { 属性: 属性类型.外功破防等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41373,
    图标ID: 22890,
    uid: 41373,
    装备名称: '溪雾戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2108 },
      { 属性: 属性类型.外功会心等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41372,
    图标ID: 1236,
    uid: 41372,
    装备名称: '幽岩戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2547 },
      { 属性: 属性类型.外功破防等级, 值: 3232 },
      { 属性: 属性类型.破招值, 值: 3232 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41371,
    图标ID: 2856,
    uid: 41371,
    装备名称: '窗幽戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2986 },
      { 属性: 属性类型.无双等级, 值: 7110 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41358,
    图标ID: 1235,
    uid: 41358,
    装备名称: '临云戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41357,
    图标ID: 1235,
    uid: 41357,
    装备名称: '君信戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41346,
    图标ID: 1233,
    uid: 41346,
    装备名称: '羡雁戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41345,
    图标ID: 1233,
    uid: 41345,
    装备名称: '君深戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41328,
    图标ID: 1228,
    uid: 41328,
    装备名称: '留诗指环',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41327,
    图标ID: 1228,
    uid: 41327,
    装备名称: '清月指环',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41310,
    图标ID: 1234,
    uid: 41310,
    装备名称: '漠名戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41309,
    图标ID: 1234,
    uid: 41309,
    装备名称: '漠若戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42507,
    图标ID: 1232,
    uid: 42507,
    装备名称: '陶然意·拥狸戒',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42506,
    图标ID: 1232,
    uid: 42506,
    装备名称: '陶然意·万仞戒',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42503,
    图标ID: 1232,
    uid: 42503,
    装备名称: '陶然意·徇节戒',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42502,
    图标ID: 1232,
    uid: 42502,
    装备名称: '陶然意·临溟戒',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42501,
    图标ID: 1232,
    uid: 42501,
    装备名称: '陶然意·上造戒',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42499,
    图标ID: 1232,
    uid: 42499,
    装备名称: '陶然意·征惯戒',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42497,
    图标ID: 1232,
    uid: 42497,
    装备名称: '陶然意·春瓮戒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42496,
    图标ID: 1232,
    uid: 42496,
    装备名称: '陶然意·吴陵戒',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42495,
    图标ID: 1232,
    uid: 42495,
    装备名称: '陶然意·穿心戒',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42491,
    图标ID: 1232,
    uid: 42491,
    装备名称: '陶然意·蜕羽戒',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42489,
    图标ID: 1232,
    uid: 42489,
    装备名称: '陶然意·登锋戒',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 766 },
      { 属性: 属性类型.外功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41426,
    图标ID: 2851,
    uid: 41426,
    装备名称: '客行江湖·烟景戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41425,
    图标ID: 2851,
    uid: 41425,
    装备名称: '客行江湖·秋序戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41292,
    图标ID: 1234,
    uid: 41292,
    装备名称: '御水戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41291,
    图标ID: 1234,
    uid: 41291,
    装备名称: '振藻戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41280,
    图标ID: 1227,
    uid: 41280,
    装备名称: '扬明戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41279,
    图标ID: 1227,
    uid: 41279,
    装备名称: '重悬戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41262,
    图标ID: 1229,
    uid: 41262,
    装备名称: '静言戒指',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41261,
    图标ID: 1229,
    uid: 41261,
    装备名称: '棣仪戒指',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42913,
    图标ID: 1231,
    uid: 42913,
    装备名称: '梧风御厨戒指·刀功',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42912,
    图标ID: 1231,
    uid: 42912,
    装备名称: '岚峰御厨戒指·刀功',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.力道, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42908,
    图标ID: 1231,
    uid: 42908,
    装备名称: '凌晓御厨戒指·刀功 ',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42907,
    图标ID: 1231,
    uid: 42907,
    装备名称: '沧波御厨戒指·刀功 ',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42906,
    图标ID: 1231,
    uid: 42906,
    装备名称: '傲寒御厨戒指·刀功 ',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.力道, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42902,
    图标ID: 1231,
    uid: 42902,
    装备名称: '塞雪御厨戒指·刀工',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42899,
    图标ID: 1231,
    uid: 42899,
    装备名称: '醉梦逍遥戒指·刀功',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.力道, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42898,
    图标ID: 1231,
    uid: 42898,
    装备名称: '至尊御厨戒指·刀功',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42897,
    图标ID: 1231,
    uid: 42897,
    装备名称: '蜀月御厨戒指·刀功',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.力道, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42891,
    图标ID: 1231,
    uid: 42891,
    装备名称: '灵虚御厨戒指·刀功',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.身法, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42888,
    图标ID: 1231,
    uid: 42888,
    装备名称: '御赐御厨戒指·刀功',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6094 },
      { 属性: 属性类型.力道, 值: 497 },
      { 属性: 属性类型.外功基础攻击, 值: 1129 },
      { 属性: 属性类型.外功会心等级, 值: 4155 },
      { 属性: 属性类型.无双等级, 值: 3324 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41244,
    图标ID: 2845,
    uid: 41244,
    装备名称: '壁明戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41243,
    图标ID: 2845,
    uid: 41243,
    装备名称: '壁邺戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41178,
    图标ID: 1229,
    uid: 41178,
    装备名称: '语铎戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41177,
    图标ID: 1229,
    uid: 41177,
    装备名称: '语方戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42879,
    图标ID: 1233,
    uid: 42879,
    装备名称: '润源戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42878,
    图标ID: 1233,
    uid: 42878,
    装备名称: '辞羁戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42860,
    图标ID: 1233,
    uid: 42860,
    装备名称: '羽缀戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42859,
    图标ID: 1233,
    uid: 42859,
    装备名称: '夏凌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42635,
    图标ID: 1233,
    uid: 42635,
    装备名称: '平皋戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42634,
    图标ID: 1233,
    uid: 42634,
    装备名称: '淮沛戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41232,
    图标ID: 1233,
    uid: 41232,
    装备名称: '润源戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41231,
    图标ID: 1233,
    uid: 41231,
    装备名称: '辞羁戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41214,
    图标ID: 1233,
    uid: 41214,
    装备名称: '平皋戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41213,
    图标ID: 1233,
    uid: 41213,
    装备名称: '淮沛戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41196,
    图标ID: 1233,
    uid: 41196,
    装备名称: '羽缀戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41195,
    图标ID: 1233,
    uid: 41195,
    装备名称: '夏凌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41106,
    图标ID: 2847,
    uid: 41106,
    装备名称: '镂云戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41105,
    图标ID: 2847,
    uid: 41105,
    装备名称: '炎翳戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41088,
    图标ID: 2847,
    uid: 41088,
    装备名称: '智渊戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41087,
    图标ID: 2847,
    uid: 41087,
    装备名称: '韶曦戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 695 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42842,
    图标ID: 2853,
    uid: 42842,
    装备名称: '风沙行·远风',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.身法, 值: 452 },
      { 属性: 属性类型.外功基础攻击, 值: 1027 },
      { 属性: 属性类型.破招值, 值: 3781 },
      { 属性: 属性类型.无双等级, 值: 3024 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42841,
    图标ID: 2853,
    uid: 42841,
    装备名称: '风沙行·孤旅',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.力道, 值: 452 },
      { 属性: 属性类型.外功基础攻击, 值: 1027 },
      { 属性: 属性类型.破招值, 值: 3781 },
      { 属性: 属性类型.无双等级, 值: 3024 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41160,
    图标ID: 1229,
    uid: 41160,
    装备名称: '语铎戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.身法, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41159,
    图标ID: 1229,
    uid: 41159,
    装备名称: '语方戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.力道, 值: 434 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41070,
    图标ID: 2850,
    uid: 41070,
    装备名称: '修篁戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.身法, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41069,
    图标ID: 2850,
    uid: 41069,
    装备名称: '绮陌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.力道, 值: 421 },
      { 属性: 属性类型.外功基础攻击, 值: 956 },
      { 属性: 属性类型.外功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41142,
    图标ID: 1229,
    uid: 41142,
    装备名称: '语铎戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.身法, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.外功会心等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41141,
    图标ID: 1229,
    uid: 41141,
    装备名称: '语方戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.力道, 值: 405 },
      { 属性: 属性类型.外功基础攻击, 值: 921 },
      { 属性: 属性类型.外功会心等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41052,
    图标ID: 2850,
    uid: 41052,
    装备名称: '涵煦戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.身法, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.加速等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41051,
    图标ID: 2850,
    uid: 41051,
    装备名称: '惜霜戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.力道, 值: 394 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.加速等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41124,
    图标ID: 1229,
    uid: 41124,
    装备名称: '语铎戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.身法, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.外功会心等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41123,
    图标ID: 1229,
    uid: 41123,
    装备名称: '语方戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.力道, 值: 376 },
      { 属性: 属性类型.外功基础攻击, 值: 854 },
      { 属性: 属性类型.外功会心等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41534,
    图标ID: 2850,
    uid: 41534,
    装备名称: '春齐戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41533,
    图标ID: 2850,
    uid: 41533,
    装备名称: '曲汜戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 370 },
      { 属性: 属性类型.外功基础攻击, 值: 839 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41034,
    图标ID: 4257,
    uid: 41034,
    装备名称: '嵘嶂戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.身法, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41033,
    图标ID: 4257,
    uid: 41033,
    装备名称: '湛影戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.力道, 值: 343 },
      { 属性: 属性类型.外功基础攻击, 值: 559 },
      { 属性: 属性类型.外功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [],
  },
]

export default 戒指装备数据
