import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

// 9722
const 涓流函数 = () => {
  const 数组 = 按数字生成数组(10)
  return 数组.map((item) => {
    return {
      增益名称: `涓流·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '涓流',
      增益类型: '部分启用',
      增益集合: [
        { 属性: 属性类型.内功会心百分比, 值: 0.016 * item },
        { 属性: 属性类型.郭氏内功会心效果等级, 值: 16 * item },
      ],
    } as 技能增益列表类型
  })
}

// 24599
const 溅玉函数 = () => {
  const 数组 = 按数字生成数组(3)
  return 数组.map((item) => {
    return {
      增益名称: `溅玉·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '雪中行',
      增益类型: '部分启用',
      增益集合: [
        { 属性: 属性类型.内功会心百分比, 值: 0.06 * item },
        { 属性: 属性类型.郭氏内功基础攻击, 值: 102 * item },
      ],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('内功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 481 / 1024 }],
  },
  {
    增益名称: '青冠',
    增益所在位置: '奇穴',
    依赖奇穴: '青冠',
    增益类型: '全局启用',
    是否常驻面板: true,
    增益集合: [
      { 属性: 属性类型.郭氏内功基础攻击, 值: 102 },
      { 属性: 属性类型.郭氏无双, 值: 102 },
    ],
  },
  // 套装双会特效
  {
    增益名称: '怒叱', // 1440
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 41 },
    ],
  },
  {
    增益名称: '布散畅和', // 1487
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 307 }],
  },
  {
    增益名称: '倚天', // 11809
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 256 }],
  },
  {
    增益名称: '钟灵', // 28116
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 154 }],
  },
  ...涓流函数(),
  {
    增益名称: '清流', // 12588
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  ...溅玉函数(),
]

export default 通用增益
