/**
 * 访问量图表
 */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as G2 from '@antv/g2'
import { 心法 } from '@/心法模块/index'
import styles from './index.module.less'

function 技能统计图表(props, ref) {
  const { data } = props
  const [chartData, setChartData] = useState<any>()

  const limitRef: any = useRef<any>()

  const getDataSource = () => {
    const res = Object.keys(data)?.map((key) => {
      const trueName = key?.replace('·', '_')
      const color = 心法?.[trueName]?.系统配置?.主题色
      return {
        心法: key,
        浏览量: data[key],
        颜色: color,
      }
    })
    return res
  }

  useImperativeHandle(ref, () => ({
    initChart: initChart,
  }))

  useEffect(() => {
    limitRef.current = false
    return () => {
      limitRef.current = false
    }
  }, [])

  const initChart = () => {
    if (limitRef.current) {
      return
    }
    limitRef.current = true

    const chart = chartData
      ? chartData
      : new G2.Chart({
          container: 'view-chart',
          autoFit: true,
          renderer: 'canvas',
          padding: [0, 0, 0, 0],
        })

    if (!chartData) {
      setChartData(chart)
    }

    const dataSource = getDataSource()

    const colorList = dataSource?.map((item) => item?.颜色)
    chart.clear()
    chart.coordinate('theta', {
      radius: 0.75,
    })
    chart.tooltip({
      showMarkers: false,
    })
    chart.legend(false)
    chart
      .interval()
      .adjust('stack')
      .position('浏览量')
      .color('心法', colorList)
      .label('心法', function () {
        return {
          offset: -10,
          content: (obj) => {
            return `${obj.浏览量}`
          },
        }
      })

    chart.data(dataSource)
    chart.render()

    setTimeout(() => {
      limitRef.current = false
    }, 10)
  }

  return <div className={styles.viewChart} id='view-chart' />
}

export default forwardRef(技能统计图表)
