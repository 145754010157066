import { 属性类型 } from '@/@types/属性'
import { 装备增益数据类型, 装备特效枚举 } from '@/@types/装备'

const 装备增益数据: 装备增益数据类型 = {
  套装会心会效: [
    { 属性: 属性类型.全会心百分比, 值: 0.04 },
    { 属性: 属性类型.郭氏全会心效果等级, 值: 41 },
  ],
  切糕会心: [{ 属性: 属性类型.全会心等级, 值: 1363 }],
  切糕无双: [{ 属性: 属性类型.无双等级, 值: 1363 }],
  切糕会心_英雄: [{ 属性: 属性类型.全会心等级, 值: 2600 }],
  切糕无双_英雄: [{ 属性: 属性类型.无双等级, 值: 2600 }],
  // 大附魔_伤帽: [{ 属性: 属性类型.全破防等级, 值: 1098 }],
  大附魔_伤腰: [{ 属性: 属性类型.通用增伤, 值: (0.038 * 8) / 30 }], // 按30% 1% 和 70% 5% 的中间值算 30秒触发一次，时常8秒
  大附魔_伤腕: [],
  大附魔_伤鞋: [],
  龙门飞剑武器: [
    { 属性: 属性类型.全会心等级, 值: (9070 * 6) / 30 },
    { 属性: 属性类型.全会心效果等级, 值: (4320 * 6) / 30 },
  ],
  冬至套装: [{ 属性: 属性类型.全属性, 值: 543 }],
  风特效腰坠: [{ 属性: 属性类型.全破防等级, 值: 23988 }],
  风特效腰坠_英雄: [{ 属性: 属性类型.全破防等级, 值: 23988 }], // 29268
  大附魔_伤帽: [{ 属性: 属性类型.全破防等级, 值: 2376 }], // 普通2376
  大附魔_伤帽_英雄: [{ 属性: 属性类型.全破防等级, 值: 2611 }], // 普通2376
  精简鞋子破防: [{ 属性: 属性类型.全破防等级, 值: 4218 * 0.5 }],
  精简鞋子破防_英雄: [{ 属性: 属性类型.全破防等级, 值: 4391 * 0.5 }],
  精简鞋子会心: [{ 属性: 属性类型.全会心等级, 值: 4218 * 0.5 }],
  精简鞋子会心_英雄: [{ 属性: 属性类型.全会心等级, 值: 4391 * 0.5 }],
  精简腰坠会效: [{ 属性: 属性类型.全会心效果等级, 值: 270 * 5 }],
  精简腰坠会效_英雄: [{ 属性: 属性类型.全会心效果等级, 值: 282 * 5 }],
  精简腰坠破防: [{ 属性: 属性类型.全破防等级, 值: 270 * 5 }],
  精简腰坠破防_英雄: [{ 属性: 属性类型.全破防等级, 值: 282 * 5 }],
  神兵无双_1: [{ 属性: 属性类型.无双等级, 值: 671 * 5 }], // 17100
  神兵无双_2: [{ 属性: 属性类型.无双等级, 值: 718 * 5 }], // 18300
  神兵无双_3: [{ 属性: 属性类型.无双等级, 值: 765 * 5 }], // 19500
  神兵无双_4: [{ 属性: 属性类型.无双等级, 值: 814 * 5 }], // 20750
  神兵无双_5: [{ 属性: 属性类型.无双等级, 值: 864 * 5 }], // 22000
} as any

const 功法特殊增益数据 = {
  内功: {
    水特效武器: [{ 属性: 属性类型.全基础攻击, 值: 1340 }], // 1层134 10层1340 按覆盖率100%算
    水特效武器_英雄: [{ 属性: 属性类型.全基础攻击, 值: 1710 }], // 1层171 10层 按覆盖率100%算
    大附魔_伤衣: [{ 属性: 属性类型.全基础攻击, 值: 719 }], // 普通 719
    大附魔_伤衣_英雄: [{ 属性: 属性类型.全基础攻击, 值: 791 }], // 普通 719
  },
  外功: {
    水特效武器: [{ 属性: 属性类型.全基础攻击, 值: 1110 }], // 1层111 10层1110 按覆盖率100%算
    水特效武器_英雄: [{ 属性: 属性类型.全基础攻击, 值: 1530 }], // 1层153 10层 按覆盖率100%算
    大附魔_伤衣: [{ 属性: 属性类型.全基础攻击, 值: 646 }], // 普通 646
    大附魔_伤衣_英雄: [{ 属性: 属性类型.全基础攻击, 值: 710 }], // 普通 646
  },
}

const 装备特效存在等级: 装备特效枚举[] = [装备特效枚举.水特效武器, 装备特效枚举.风特效腰坠]

export { 功法特殊增益数据, 装备特效存在等级 }

export default 装备增益数据
