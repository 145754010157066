import React from 'react'
import { 心法 } from '@/心法模块'
import ComponentReveal from '../多组件渐显'
import styles from './index.module.less'

const 分组 = {
  周天功: {
    // 图片: 'https://icon.jx3box.com/icon/3086.png',
    成员: ['风雪入我怀', '顾车夫', '买糖', '平泽唯', '十九停', '朱雀', '偷懒'],
  },
  孤锋诀: {
    成员: [
      '七海',
      '柒七柒',
      '刀剑予尘',
      '刀可特',
      '锋止水',
      '秋空',
      '邀影',
      '纵月惊鸿',
      'kito',
      '绘清影',
    ],
  },
  山海心诀: {
    成员: ['叶青笺', '清城', '流萤', '可乐_2', '苹果', '夙禾'],
  },
  凌海诀: {
    成员: [
      '方小皮',
      '不老梦',
      '沉白鹿',
      '枯骨执笔',
      '倾慕成仙',
      '晴明',
      '小坏蛋',
      '小鹿辞',
      '云灯',
      '初酱',
      '会灰',
    ],
  },
  太一神宫第一实验室: {
    图片: require('../../静态资源/致谢名单/太一神宫第一实验室.jpg'),
    成员: ['猜猜', '初雪', '画忆', '一慕尔如星一'],
  },
  太玄经: {
    成员: [
      '小满胜万全',
      '江岸停',
      '猫灯灯',
      '心如刀',
      '奶茶鉴定专家',
      '神算子',
      '月影无痕',
      '星鹭',
      '一木见风',
    ],
  },
  无方: {
    成员: ['长倦', '白夜'],
  },
  花间游: {
    成员: ['蔓海赋尘', '糯米团子', '樱内梨子'],
  },
  毒经: {
    成员: ['莴苣', '淡淡稻花香'],
  },
  易筋经: {
    成员: ['秃不得', 'Modest'],
  },
  北傲诀: {
    成员: ['世界成冰', '霸刀攻略组'],
  },
  分山劲: {
    成员: ['神光毓逍遥'],
  },
  魔盒: {
    图片: require('../../静态资源/致谢名单/魔盒.jpg'),
    成员: ['倾茶茶', '吴钩月下', '叶殇离'],
  },
  技术专家组: {
    图片: 'https://icon.jx3box.com/icon/2589.png',
    成员: ['Zeratulag', 'yunmoer', '难为水', '青墨白宣', '雨轩'],
  },
}

// const 特别鸣谢 = ['考拉', '蓝团', '可乐']
const splitNum = 6

function 致谢名单({ step }) {
  const DataComponent = (data) => {
    return data.map((group) => {
      const 数据 = 分组[group]
      const groupImg = 数据?.图片 || 心法?.[group]?.系统配置?.心法图标
      const bigItem = 数据?.成员?.length > 7
      const smallItem = 数据?.成员?.length <= 3
      return (
        <div
          key={group}
          className={`${styles.item} ${bigItem ? styles.bigItem : ''} ${
            smallItem ? styles.smallItem : ''
          }`}
        >
          <div className={styles.header}>
            <img src={groupImg} className={styles.img} />
            <span className={styles.groupName}>{group}</span>
          </div>
          <div className={styles.members}>
            {数据?.成员?.map((成员) => {
              const groupImg = require(`../../静态资源/致谢名单/${成员}.jpg`)
              return (
                <div className={styles.member} key={成员}>
                  <img src={groupImg} className={styles.img} />
                  <span className={styles.name}>{成员?.replace('_', '')}</span>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <div
      className={`${styles.thanks} ${step === 0 ? styles.step0 : styles.step1}`}
      onClick={() => 1}
    >
      {step === 0 ? (
        <ComponentReveal list={DataComponent(Object.keys(分组)?.filter((_, i) => i <= splitNum))} />
      ) : (
        <ComponentReveal list={DataComponent(Object.keys(分组)?.filter((_, i) => i > splitNum))} />
      )}
    </div>
  )
}

export default 致谢名单
