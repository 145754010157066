import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
// import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用外功增益: 技能增益列表类型[] = [
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 430 / 1024 }],
  },
]

export default 通用外功增益
