import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 兵主逆增益 from './技能增益/兵主逆'
import 三星临增益 from './技能增益/三星临'
import 列宿游增益 from './技能增益/列宿游'
import 天斗旋增益 from './技能增益/天斗旋'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '102247',
    技能名称: '飞宫·悟·撞击',
    统计名称: '飞宫·悟',
    技能伤害系数: 373,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102249',
    技能名称: '飞宫·悟·连撞',
    统计名称: '飞宫·悟',
    技能伤害系数: 392,
    技能破招系数: 1249 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102238',
    技能名称: '奇门飞宫·悟',
    技能伤害系数: 24,
    技能破招系数: 72 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102248',
    技能名称: '列宿游·悟',
    技能伤害系数: 1542,
    基础伤害_基础值: 1,
    技能增益列表: 列宿游增益,
  },
  {
    技能ID: '102205',
    技能名称: '辰星·悟',
    技能伤害系数: 127,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '101481',
    技能名称: '兵主逆·悟',
    技能伤害系数: 444,
    技能破招系数: 1423 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 兵主逆增益,
  },
  {
    技能ID: '101466',
    技能名称: '三星临·悟',
    技能伤害系数: 275,
    技能破招系数: 1434 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 三星临增益,
  },
  {
    技能ID: '101467',
    技能名称: '天斗旋·悟',
    统计名称: '天斗旋·悟',
    技能伤害系数: 296,
    技能破招系数: 1546 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 天斗旋增益,
  },
  {
    技能ID: '102265',
    技能名称: '天斗旋·悟·秘籍',
    统计名称: '天斗旋·悟',
    技能伤害系数: 96,
    基础伤害_基础值: 1,
    技能增益列表: 天斗旋增益,
  },
  {
    技能ID: '101497',
    技能名称: '杀星在尾·悟',
    技能伤害系数: 242,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
