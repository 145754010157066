// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RbIs_zZeV3iykLsa0ZQK {
  position: absolute;
  right: 12px;
  bottom: 8px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: right;
  justify-content: flex-end;
}
.YAouz86ZDbAt6cfLYhUI {
  background-color: #fff;
  padding: 3px;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
}
.rUF7mhviH3uIpGY0dzco {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rUF7mhviH3uIpGY0dzco + .rUF7mhviH3uIpGY0dzco {
  margin-left: 24px;
}
.WfdK8Pleey3tJleIsM1g {
  max-width: 240px;
  text-align: left;
  margin-left: 8px;
}
.iJq3yWvkFOmqwu1CLA9t {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-bottom: 6px;
}
.yj_XXqo4PtHg5Xn2Hsfm {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  max-width: 230px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/二维码区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,yBAAA;AACF;AAEA;EACE,sBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAKA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAJF;AAOA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AALF","sourcesContent":[".codeWrap {\n  position: absolute;\n  right: 12px;\n  bottom: 8px;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  line-height: 24px;\n  font-size: 12px;\n  font-weight: 400;\n  color: #fff;\n  text-align: right;\n  justify-content: flex-end;\n}\n\n.codeContent {\n  background-color: #fff;\n  padding: 3px;\n  width: 40px;\n  height: 40px;\n  display: inline-block;\n  border-radius: 4px;\n}\n\n.codeItem {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n\n  &+.codeItem {\n    margin-left: 24px;\n  }\n}\n\n.codeInfo {\n  max-width: 240px;\n  text-align: left;\n  margin-left: 8px;\n}\n\n.codeTitle{\n  font-size: 12px;\n  line-height: 12px;\n  font-weight: 400;\n  margin-bottom: 6px;\n}\n\n.codeUrl {\n  font-size: 12px;\n  line-height: 12px;\n  font-weight: 400;\n  margin-bottom: 0px;\n  max-width: 230px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeWrap": `RbIs_zZeV3iykLsa0ZQK`,
	"codeContent": `YAouz86ZDbAt6cfLYhUI`,
	"codeItem": `rUF7mhviH3uIpGY0dzco`,
	"codeInfo": `WfdK8Pleey3tJleIsM1g`,
	"codeTitle": `iJq3yWvkFOmqwu1CLA9t`,
	"codeUrl": `yj_XXqo4PtHg5Xn2Hsfm`
};
export default ___CSS_LOADER_EXPORT___;
