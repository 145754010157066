import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 门派套装增益 from './通用增益/门派套装增益'

const 万世不竭增益: 技能增益列表类型[] = [
  ...通用增益,
  ...门派套装增益,
  {
    // 5756 橙武增伤
    增益名称: '苍冥游',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 512 / 1024 }],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '3%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 31 / 1024 }],
  },
  {
    // 17918
    增益名称: '破势',
    增益所在位置: '奇穴',
    依赖奇穴: "破势",
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 1331 / 1024 }],
  },
]

export default 万世不竭增益
