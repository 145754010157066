import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '驭风·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22973.png',
        id: '102293',
        奇穴描述: '每次施展【对阵招式】，降低【风流云散·悟】3秒调息时间。',
      },
      {
        奇穴名称: '逆脉·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22985.png',
        id: '102294',
        奇穴描述: '自身会心率提高15%，施展{指法招式}可降低【风流云散·悟】1秒调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '佑风·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22818.png',
        id: '102295',
        奇穴描述: '每次消耗{悬剑}使自身{扇招式}伤害提高12%，持续10秒，可叠加3层。',
      },
      {
        奇穴名称: '风扬·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22993.png',
        id: '102296',
        奇穴描述: '不再消耗{悬剑}，处于{游风}时，触发招式的{悬剑}效果时，额外造成1次内功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '入静·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22981.png',
        id: '102297',
        奇穴描述:
          '会心效果提高10%，添加{悬剑}时，降低{扇招式}调息时间1秒，触发{悬剑}效果时，降低{扇招式}调息时间2秒。',
      },
      {
        奇穴名称: '奇脉·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22982.png',
        id: '102298',
        奇穴描述:
          '自身开始运功时，受到的伤害降低20%，持续3秒，完成运功时，造成的伤害提高20%，持续3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '一阳化生·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/102290.png',
        id: '102290',
        奇穴描述:
          '持续在目标区域造成伤害。招式伤害和招式会心率会随着命中次数递进，若全部命中目标，则对其添加{悬剑}。',
      },
      {
        奇穴名称: '抟风令·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/22814.png',
        id: '102291',
        奇穴描述:
          '每秒将敌方拉拽至中心并使其{轻功沉默}1.5秒。运功完成后击飞区域中心半径5尺内敌方并对其造成伤害。',
      },
    ],
  },
]

export default 奇穴数据
